export const translations = {
  it: {
    sideMenu: {
      home: "Home",
      write: "Nuova Comunicazione",
      archive: "Comunicazioni",
      templates: "Layouts",
      user: "Nome utente",
      reduce: "Riduci",
      logout: "Esci",
    },
    communicationTypeSelector: {
      header: "Scegli il tipo di comunicazione",
      mostUsed: "Più usati",
      others: "Altri",
    },
    shared: {
      communicationTypes: {
        DEM: {
          title: "DEM",
          info: "Comunicazione mirata via email, ideale per promozioni e conversioni dirette.",
        },
        PAPER_MAIL: {
          title: "Mail cartacea",
          info: "Ideale per creare un impatto tangibile e memorabile, rafforzando la percezione del brand.",
        },
        LANDING_PAGE: {
          title: "Landing page",
          info: "Fondamentale per conversioni digitali, permette di concentrare l'attenzione su un'offerta specifica.",
        },
        PUSH_NOTIFICATION: {
          title: "Notifica push",
          info: "Perfetta per messaggi tempestivi su mobile, cattura immediatamente l'attenzione.",
        },
        SCRIPT: {
          title: "Script",
          info: "Efficace per un'interazione diretta e personalizzata, utile per vendere prodotti in tempo reale.",
        },
        RCS: {
          title: "RCS",
          info: "SMS che combina testo e contenuti multimediali, aumentando il tasso di interazione.",
        },
      },
      aimTypes: {
        COMMERCIAL: "Comunicazione commerciale",
        INFOTAINMENT: "Comunicazione di infotainment",
        SERVICE: "Comunicazione di servizio",
      },
      copy: "Copia",
      copied: "Copiato",
      save: "Salva",
      retrying: "È in corso un tentativo di salvataggio...",
      cancel: "Annulla",
      ok: "Ok",
      delete: "Elimina",
      proceed: "Procedi",
      close: "Chiudi",
    },
    contentCreation: {
      exit: "Esci",
    },
    commEditing: {
      status: {
        loading: "Caricamento in corso...",
        loaded: "Caricamento completato",
        error: "Errore durante il caricamento",
      },
      editLayout: "Modifica il layout",
      targetPersona: "Target Persona",
      editBrief: {
        title: "Modifica il brief",
        paragraph:
          "Ogni bozza di lavoro verrà rimossa e verrà creata una nuova bozza partendo dal nuovo brief.",
      },
      newDraftButton: "Genera una nuova bozza",
      newDraftModal: {
        title: "Genera una nuova bozza",
        paragraph:
          "Riscrivi totalmente la proposta di contenuto, partendo dal brief. Le altre bozze rimarranno disponibili e immutate. La nuova proposta terrà conto delle customer persona che indichi e di qualunque suggerimento aggiuntivo",
        form: {
          personaSelector: {
            label: "A quale persona deve parlare?",
            placeholder: "Target persona",
          },
          promptInput: {
            label: "Ci sono istruzioni aggiuntive da rispettare?",
            placeholder:
              "Puoi dare a Reaidy indicazioni specifiche per questa nuova bozza",
          },
          submitButton: {
            label: "Genera",
          },
          cancelButton: {
            label: "Indietro",
          },
        },
      },
      variantModal: {
        title: "Duplica con variazione",
        subtitle: "Crea una variante del contenuto",
        paragraph:
          "Puoi dare commenti o chiedere modifiche alla proposta di contenuto attuale, con la possibilità di indicare la persona per cui scrivere la comunicazione. La nuova proposta sarà comunque vicina all'originale.",
        form: {
          personaSelector: {
            label: "Persona",
          },
          promptInput: {
            label: "Indicazioni su cosa variare",
          },
          submitButton: {
            label: "Genera",
          },
        },
      },
      duplicateModal: {
        title: "Duplica",
        subtitle: "Crea una copia esatta del contenuto",
        paragraph:
          "Puoi usare la nuova copia per sperimentare apportando modifiche al testo, senza influire sul contenuto della bozza originale.",
        form: {
          personaSelector: {
            label: "Persona",
          },
          submitButton: {
            label: "Genera",
          },
        },
      },
    },
    login: {
      welcome:
        "Reaidy ti aiuta a creare comunicazioni efficaci e personalizzate per qualsiasi destinatario.",
      emailRequired: "Il campo email è obbligatorio",
      emailInvalid: "Inserisci un indirizzo email valido",
      passwordRequired: "Il campo password è obbligatorio",
      errorHeader: "Login fallito",
      errorContent: "Email o password non validi, riprova.",
    },
    dashboard: {
      title: "Buongiorno {{name}} 👋",
      quickActions: {
        title: "Inizia da qui...",
        newComm: "Nuova comunicazione",
      },
      communications: {
        title: "...o riprendi da dove avevi lasciato",
        updatedAt: "Modificato il {{date}}",
        actions: {
          edit: "Modifica",
          duplicate: "Duplica",
          delete: "Elimina",
          duplicateFromBrief: "Duplica dal brief",
        },
      },
    },
    brief: {
      generation: {
        loading: {
          title: "Attendi qualche secondo 🤗",
          description: "Reaidy sta lavorando al miglior copy possibile",
        },
      },
      header: "Scrivi il brief",
      indications: {
        1: "Un buon brief aiuta Reaidy a capire come ottimizzare la comunicazione e come scriverne il contenuto.",
        2: "Se citi dei prodotti, programmi o servizi che Reaidy conosce le loro informazioni saranno automaticamente usate. Contatta un referente se ti serve aiuto nella scrittura del brief.",
      },
      generate: "Genera il testo",
      name: "Dai un nome alla comunicazione",
      persona: "A quale persona deve parlare?",
      questions: [
        {
          label:
            "Obiettivi, contenuti e motivazioni per l’invio della comunicazione",
          placeholder: "L'obiettivo della comunicazione è quello di...",
        },
        {
          label: "Messaggi chiave o aspetti da evidenziare",
          placeholder: "E’ importante evidenziare che...",
        },
      ],
      goBack: "Indietro",
      goNext: "Avanti",
      error: {
        name: "È necessario dare un nome alla comunicazione",
        personaId: "È necessario selezionare una persona",
        tooLongName:
          "Il nome della comunicazione è troppo lungo, il limite è di 40 caratteri",
        description: "È necessario rispondere a questa domanda",
        plusOrIncentives: "È necessario rispondere a questa domanda",
      },
      regenerateHeader: "Attenzione",
      regenerateContent:
        "Sei sicuro di voler rigenerare il testo? Le precedenti proposte verranno sovrascritte.",
      creationFailureHeader: "Errore nella creazione",
      creationFailureContent:
        "Si è verificato un errore durante la creazione della comunicazione",
      generationFailureContent:
        "Si è verificato un errore durante la generazione del testo, riprova più tardi.",
      creationSuccessHeader: "Comunicazione creata",
      creationSuccessContent:
        "La comunicazione è stata creata con successo, potrai trovarla nell’archivio.",
    },
    aimTypes: {
      header: "Di che comunicazione si tratta?",
      indications:
        "Scegli la categoria che meglio rappresenta la tua comunicazione. Questo aiuterà a generare un testo più adatto alle tue esigenze.",
    },
    layout: {
      header: "Decidi il Layout",
      creation: {
        title: "Costruisci il Layout",
      },
      createFromTemplate: "Parti da un Template",
      createNew: [
        "Crea un nuovo Layout",
        "Struttura il testo della tua comunicazione partendo da zero.",
      ],
      indications: [
        "Scegli il layout che meglio si adatta alle tue esigenze e otterrai un testo adattato al tuo design.",
      ],
    },
    template: {
      selectTemplate: "Usa un template esistente",
      choose: {
        header: "Conferma la scelta: {{name}}",
        content:
          "Il template che hai selezionato è il seguente. Sei sicuro di voler procedere con la scelta?",
        confirm: "Conferma",
        cancel: "Annulla",
      },
      create: "Crea un nuovo template",
      creationDescription:
        "Configura i campi per generare testi conformi al tuo template.",
      createNow: "Crea ora",
      preview: {
        close: "Chiudi",
      },
      creation: {
        DEM: {
          subject: "Oggetto",
          mainTitle: "Titolo principale",
          subjectField: "Oggetto dell’email",
          mainTitleField: "Titolo principale",
        },
        PAPER_MAIL: {
          subject: "Titolo busta",
          mainTitle: "Titolo principale",
          subjectField: "Titolo busta",
          mainTitleField: "Titolo principale",
        },
        header: "Creazione nuovo template",
        subject: "Oggetto",
        section: "Sezione {{index}}",
        addTitle: "Aggiungi titolo principale",
        addSection: "Aggiungi sezione",
        chars: "caratteri",
        charsAbbreviation: "car.",
        communicationType: "Tipo di comunicazione",
        SECTION_TITLE: "Titolo",
        TEXT: "Testo",
        BULLET_POINT: "Punto elenco",
        TITLED_BULLET_POINT: "Punto elenco con titolo",
        CTA: "Call to action",
        bulletMaxTitleLength: "Titolo",
        bulletMaxContentLength: "Testo",
        description: "Cosa deve esser scritto in questa sezione?",
        missingDescription:
          "Indica qui la funzione a cui questa sezione deve assolvere",
        descriptionOptional:
          "Ci sono indicazioni su come scrivere questo testo?",
        submit: "Salva",
        cancel: "Annulla",
        preview:
          "In questa sezione sarà mostrata l'anteprima del template che stai creando. Puoi visualizzare il formato del testo generato e controllare che sia perfetto per te.",
        failure: {
          header: "Creazione fallita",
          409: "Impossibile creare il template poiché esiste già un template con lo stesso nome. Scegli un altro nome e riprova.",
        },
        //the following value is empty because we do not want the error message to be shown.
        // At the same time this i18n entry is necessary because the validation schema uses it,
        // and it is not possible to remove it (removing it would cause the insertion of a default error message)
        invalidMaxLength: "",
        newSection: "Nuova sezione",
      },
      update: {
        submit: "Salva",
      },
    },
    select: "Seleziona",
    loading: "Caricamento in corso...",
    editing: {
      floatingPrompt: {
        placeholder:
          "Cosa va cambiato o non ti piace di questa proposta di contenuto?",
      },
      yourDraft: "La tua bozza",
      proposal1: "Proposta 1",
      proposal2: "Proposta 2",
      proposal3: "Proposta 3",
      undo: "Annulla",
      redo: "Ripristina",
      createDraft: "Seleziona questa proposta",
      modify: "Modifica",
      enterDirections: "Inserisci le tue indicazioni",
      improve: "Migliora",
      shorten: "Accorcia",
      expand: "Espandi",
      rephrase: "Riformula",
      insert: "Inserisci",
      accept: "Accetta",
      discard: "Scarta",
      versionExpanded: "Versione espansa",
      versionReduced: "Versione ridotta",
      versionRephrased: "Versione riformulata",
      versionCustom: "Versione personalizzata",
      regenerate: "Rigenera",
      error: "Si è verificato un errore, riprova più tardi",
      goBack: "Indietro",
      suggestEdits: "Suggerisci una modifica",
      shareDraft: "Copia link della bozza",
      saved: "Tutte le modifiche sono state salvate",
      unsaved: "Ci sono modifiche non salvate, attendi il salvataggio...",
      communicationNotFound:
        "Dev'esserci stato un errore, impossibile trovare la comunicazione",
      proposalSubtitle: "Proposte contenuto",
      proposalsDescription:
        "Abbiamo creato tre proposte di testo personalizzate per te. Puoi apportare le modifiche che preferisci e creare il testo perfetto che meglio risponde alle tue specifiche esigenze e obiettivi. \n\n" +
        "Dopo aver elaborato la tua proposta, selezionala per procedere con l'invio o la declinazione in base ai profili delle Personas.",
      viewSubtitle: "Preview contenuto",
      viewDescription:
        "Qui puoi visualizzare il testo della comunicazione e controllare che sia perfetto per te. In questa modalità non è possibile apportare modifiche.",
      changeNameFailed: "Impossibile cambiare il nome del progetto",
      changeNameFailedMessage:
        "Il nome della comunicazione deve essere unico e non può essere vuoto",
      discardBriefEdits: {
        header: "Chiusura del brief",
        content:
          "Sei sicuro di voler scartare tutte le modifiche apportate al brief?",
      },
      overwriteDraft: {
        button: "Riparti da questa proposta",
        header: "Overwriting della bozza",
        content:
          "Sei sicuro di voler sovrascrivere la bozza attuale? Tutte le modifiche effettuate andranno perse.",
      },
      selectPersonas: "Selezione personas",
      personalisedCommunications: {
        share: "Condividi le versioni",
        subtitle: "Versioni personalizzate per le Personas",
        description:
          "Abbiamo creato una versione personalizzata per ciascuna delle Personas selezionate.\nPuoi visualizzare e condividere le versioni per ottenere feedback e approvazioni.",
        versionItem: "Versione per {{name}}",
      },
      errors: {
        saving: {
          header: "Errore durante il salvataggio",
          content:
            "Si è verificato un errore durante il salvataggio, qui di seguito ci sono le versioni che non siamo riusciti a salvare. Puoi copiare il contenuto per non perdere le modifiche.",
          retry: "Riprova il salvataggio",
          discard: "Chiudi e scarta le modifiche",
        },
      },
    },
    personasSelection: {
      proceed: "Procedi",
      header: "Declinazione contenuto su personas",
      indications:
        'Seleziona una o più delle "Personas",  adattaremo il messaggio per rispondere in modo preciso alle diverse esigenze e aspettative.',
      overwrite: {
        header: "Sovrascrittura comunicazioni",
        content:
          "Sei sicuro di voler sovrascrivere le comunicazioni personalizzate già generate?",
      },
      error: {
        header: "Impossibile procedere",
        content:
          "Selezionare almeno una persona per cui personalizzare la comunicazione",
      },
    },
    stepper: {
      contentType: "Tipo di Comunicazione",
      layout: "Costruisci il Layout",
      brief: "Genera un Nuovo Brief",
      editing: "Itera sul Contenuto",
    },
    archive: {
      title: "Comunicazioni",
      controls: {
        newCommunication: "Nuova comunicazione",
      },
      filters: "Filtri",
      filterBy: {
        createdByMe: "Creato da me",
      },
      write: "Scrivi",
      date: "Data",
      project: "Progetto",
      type: "Formato",
      status: "Stato",
      view: "Visualizza",
      edit: "Modifica",
      duplicateFromBrief: "Duplica dal brief",
      noCommunications: "Non ci sono ancora comunicazioni da mostrare",
      createNow: "Creane una ora",
      communicationDeletion: {
        header: "Eliminazione comunicazione",
        checkMessage:
          'Sei sicuro di voler cancellare definitivamente la comunicazione "{{communicationName}}"?',
        failureHeader: "Cancellazione fallita",
        failure: {
          generic: "Si è verificato un errore, riprova più tardi",
        },
      },
      duplicate: {
        buttonText: "Duplica",
        header: "Duplica Comunicazione",
        content:
          "Scegli il nome per la nuova comunicazione, ricorda che deve essere unico",
        failureHeader: "Duplicazione fallita",
        failure: {
          404: "Impossibile duplicare poiché la comunicazione non esiste.",
          409: "Impossibile duplicare poiché esiste già una comunicazione con lo stesso nome con cui verrà generata la nuova comunicazione. Scegli un altro nome e riprova.",
        },
      },
      duplicateFromDraft: {
        buttonText: "Duplica dalla bozza",
        header: "Duplicazione dalla bozza",
        content:
          "Scegli il nome per la nuova comunicazione, ricorda che deve essere unico",
        failureHeader: "Duplicazione dalla bozza fallita",
        failure: {
          404: "Impossibile duplicare dalla bozza poiché la comunicazione non esiste.",
          409: "Impossibile duplicare dalla bozza poiché esiste già una comunicazione con lo stesso nome con cui verrà generata la nuova comunicazione. Scegli un altro nome e riprova.",
        },
      },
      duplicateFromPersonas: {
        buttonText: "Duplica dalle personas",
        header: "Duplicazione dalla selezione delle personas",
        content:
          "Scegli il nome per la nuova comunicazione, ricorda che deve essere unico",
        failureHeader: "Duplicazione da personas fallita",
        failure: {
          404: "Impossibile duplicare da personas poiché la comunicazione non esiste.",
          409: "Impossibile duplicare da personas poiché esiste già una comunicazione con lo stesso nome con cui verrà generata la nuova comunicazione. Scegli un altro nome e riprova.",
        },
      },
    },
    templates: {
      title: "Layout",
      controls: {
        create: "Crea nuovo layout",
      },
      menu: {
        edit: "Modifica",
        delete: "Elimina",
      },
    },
    genericError: "Si è verificato un errore, riprova più tardi",
    time: {
      formats: {
        fullDateTime: "%d/%m/%Y %H:%M",
      },
    },
    date: {
      formats: {
        default: "%Y-%m",
        full: "%d/%m/%Y",
        humanAbbreviated: "%-d %b, %Y",
      },
    },
  },
};

export enum DateTimeFormat {
  ONLY_YEAR_MONTH_STANDARD = "date.formats.default",
  FULL_DATE = "date.formats.full",
  FULL_DATE_TIME = "time.formats.fullDateTime",
}

type ObjectPaths<T, Prefix extends string = ""> = {
  [K in keyof T]: T[K] extends object
    ? ObjectPaths<T[K], `${Prefix}${Extract<K, string>}.`>
    : `${Prefix}${Extract<K, string>}`;
}[keyof T];

export type TranslationKeys = ObjectPaths<(typeof translations)["it"]>;
