// pageViewMap.ts
import { pages } from "@reaidy/pages";
import { matchPath } from "react-router-dom";

export enum Page {
  DashboardPage = "DashboardPage",
  ContentCreationPage = "ContentCreationPage",
  ArchivePage = "ArchivePage",
  TemplateArchivePage = "TemplateArchivePage",
  EditPage = "EditPage",
  ViewPage = "ViewPage",
}
export const pageViewMap = {
  /*
   * Here you can map your app paths (defined in paths.d.ts)
   * to the according page view keys (defined in the chosen analytics platform)
   */
  [pages.home]: Page.DashboardPage,
  [pages.contentCreation]: Page.ContentCreationPage,
  [pages.archive]: Page.ArchivePage,
  [pages.templates]: Page.TemplateArchivePage,
  [pages.edit]: Page.EditPage,
  [pages.view]: Page.ViewPage,
};

export const propsMapper = {
  [Page.DashboardPage]: (_params) => undefined,
  [Page.ContentCreationPage]: (_params) => undefined,
  [Page.ArchivePage]: (_params) => undefined,
  [Page.TemplateArchivePage]: (_params) => undefined,
  [Page.EditPage]: (params) => ({
    communicationId: params.communicationId,
  }),
  [Page.ViewPage]: (params) => ({
    communicationId: params.communicationId,
  }),
} satisfies {
  [K in Page]: (
    params: Record<string, string | undefined>,
  ) => object | undefined;
};

export const matchPathToEvent = (pathname: string) => {
  // if the path is static the mapping is direct
  if (pageViewMap[pathname]) {
    return { page: pageViewMap[pathname] };
  }

  // if the path is dynamic, we need to match it to the page checking all the patterns
  for (const pattern in pageViewMap) {
    const match = matchPath(pattern, pathname);
    if (match) {
      const page = pageViewMap[pattern];
      return {
        page,
        props: propsMapper[page](match.params),
      };
    }
  }
  return null;
};
