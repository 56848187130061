import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { ReactNode } from "react";

const BUTTON_HEIGHT = "2.75rem";

const StyledButton = styled(Button)<{
  isActive: boolean;
}>(({ theme, isActive }) => ({
  "--_bg-color": isActive ? theme.palette.brand[9] : "transparent",
  "--_text-color": isActive ? theme.palette.grey[1] : theme.palette.grey[7],
  "--_hover-bg-color": isActive ? "var(--_bg-color)" : theme.palette.grey[11],
  "--_hover-text-color": "var(--_text-color)",
  "--_active-bg-color": theme.palette.grey[9],
  "--_active-text-color": theme.palette.grey[1],
  "--_focus-bg-color": "var(--_hover-bg-color)",
  "--_focus-text-color": "var(--_hover-text-color)",

  backgroundColor: "var(--_bg-color)",
  color: "var(--_text-color)",
  transition: "color 0.3s",
  padding: "0.5rem",
  borderRadius: "0.5rem",
  position: "relative",
  height: BUTTON_HEIGHT,

  "&:hover": {
    backgroundColor: "var(--_hover-bg-color)",
    color: "var(--_hover-text-color)",
  },
  "&:active": {
    backgroundColor: "var(--_active-bg-color)",
    color: "var(--_active-text-color)",
  },
  "&:focus": {
    backgroundColor: "var(--_focus-bg-color)",
    color: "var(--_focus-text-color)",
    boxShadow: "none !important",
  },
  svg: {
    color: "currentColor !important",
  },
}));

const StyledIcon = styled(Icon)({
  fontSize: "1.3rem !important",
  position: "absolute",
  left: "0",
  width: BUTTON_HEIGHT,
  display: "flex",
  justifyContent: "center",
  top: "50%",
  transform: "translateY(-50%)",
});

const StyledLabel = styled("span")<{
  isExpanded?: boolean;
}>(({ isExpanded }) => ({
  fontSize: "1rem",
  display: "block",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  left: BUTTON_HEIGHT,
  whiteSpace: "nowrap",
  opacity: isExpanded ? 1 : 0,
  transitionProperty: "opacity",
  transitionDuration: "0.2s",
  transitionDelay: isExpanded ? "0" : "0.1s",
  transitionTimingFunction: isExpanded ? "ease-out" : "ease-in",
}));

StyledIcon.displayName = "Icon";

export const SideMenuButton = ({
  testId,
  icon,
  active,
  label,
  onClick,
  isExpanded,
}: {
  testId?: string;
  icon: ReactNode;
  label: string;
  active: boolean;
  onClick: () => void;
  isExpanded: boolean;
}) => {
  return (
    <StyledButton
      testId={testId}
      variant={"filled"}
      isActive={active}
      size="md"
      leftIcon={<StyledIcon fontSize="2rem">{icon}</StyledIcon>}
      onClick={onClick}
      isExpanded={isExpanded}
    >
      {/** @ts-expect-error label should be a string */}
      <StyledLabel isExpanded={isExpanded}>{label}</StyledLabel>
    </StyledButton>
  );
};
