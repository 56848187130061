import { useFormContext } from "@xtreamsrl/react-forms";
import { generateLoremIpsumMarkdown } from "../utils";
import { MarkdownViewer } from "../../contentCreation/components/MarkdownViewer";
import { useState, useEffect } from "react";
import { GenericTemplateComponent } from "../types";

export function TemplatePreview() {
  const { watch } = useFormContext();
  const [previewContent, setPreviewContent] = useState("");

  useEffect(() => {
    const subscription = watch((value) => {
      const content = generateLoremIpsumMarkdown(
        value as GenericTemplateComponent,
      );
      setPreviewContent(content);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  return <MarkdownViewer contentToDisplay={previewContent} />;
}
