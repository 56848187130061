export enum ArchiveEvents {
  GoToContentCreation = "GoToContentCreation",
  ViewCommunication = "ViewCommunication",
  EditCommunication = "EditCommunication",
  DuplicateFromBrief = "DuplicateFromBrief",
  OpenDuplicateFromDraftDialog = "OpenDuplicateFromDraftDialog",
  DuplicateFromDraft = "DuplicateFromDraft",
  OpenDuplicateFromPersonasDialog = "OpenDuplicateFromPersonasDialog",
  DuplicateFromPersonas = "DuplicateFromPersonas",
  OpenDeleteDialog = "OpenDeleteDialog",
}
