import root from "react-shadow";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

export type HTMLRendererAPI = {
  readonly shadowRoot?: ShadowRoot | null;
};

export type HTMLRendererProps = {
  children: string;
  extraStyles?: string;
};

export const HTMLRenderer = forwardRef<HTMLRendererAPI, HTMLRendererProps>(
  function HTMLRenderer(props, ref) {
    const { children, extraStyles } = props;
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const rootElement = containerRef.current?.shadowRoot;
      if (!rootElement) throw new Error("Shadow root not found");

      // Copy the body of the parsed HTML into the container
      const parsed = new DOMParser().parseFromString(children, "text/html");
      const container = document.createElement("div");
      container.appendChild(parsed.body);

      // Add custom CSS
      const style = document.createElement("style");
      const css = document.createTextNode(extraStyles ?? "");
      style.appendChild(css);

      // Replace the children of the shadow root with the parsed HTML
      rootElement.replaceChildren(container.children[0]);
      style && rootElement.appendChild(style);
    }, [children, containerRef, extraStyles]);

    useImperativeHandle(ref, () => ({
      get shadowRoot() {
        return containerRef.current?.shadowRoot;
      },
    }));

    return <root.section ref={containerRef} />;
  },
);
