import { queryOptions, useQuery } from "@tanstack/react-query";
import { getCommunicationAPI } from "../apis/getCommunication";

export const commDetailsQueryKeys = {
  all: ["comm", "details"] as const,
  byId: (commId: string) => [...commDetailsQueryKeys.all, commId] as const,
};

export function getCommDetailsQuery(commId: string) {
  return queryOptions({
    queryKey: commDetailsQueryKeys.byId(commId),
    queryFn: async ({ queryKey }) => {
      const communication = await getCommunicationAPI(queryKey[2]);

      // This will be removed once the API is ready
      // const mockHTML = await (
      //   await fetch(window.location.origin + "/email-templates/demo-nexi.html")
      // ).text();
      //
      // communication.details.layout = {
      //   html: mockHTML,
      // };

      return communication;
    },
  });
}

export function useCommDetailsQuery(commId: string) {
  return useQuery(getCommDetailsQuery(commId));
}
