import { queryOptions, useQuery } from "@tanstack/react-query";
import { getUserAPI } from "../apis/getUser";

export const userQueryKeys = {
  all: ["user"],
};

export function getUserQuery() {
  return queryOptions({
    queryKey: userQueryKeys.all,
    queryFn: getUserAPI,
  });
}

export function useUserQuery() {
  return useQuery(getUserQuery());
}
