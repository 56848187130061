import { moveSectionTitleOutsideComponents } from "src/templates/utils";
import { default as fetch } from "../../_shared/fetch";
import { BriefDemForm, Communication } from "../types";

export type CreateCommunicationInput = {
  name?: string;
  personaId?: string;
  details: BriefDemForm;
};

export async function createCommunicationAPI(
  input: CreateCommunicationInput,
): Promise<Communication>;

export async function createCommunicationAPI(
  input: CreateCommunicationInput,
): Promise<Communication> {
  const layoutStructure =
    input.details.source === "MANUAL"
      ? moveSectionTitleOutsideComponents(input.details.layoutStructure)
      : input.details.layoutStructure;

  const communication: Communication = await fetch.post("/communications", {
    personaId: input.personaId,
    details: {
      ...input.details,
      layoutStructure,
    },
  });

  return communication;
}
