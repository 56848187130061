import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { styled } from "@xtreamsrl/react-ui-kit/styles";

const Wrapper = styled(Box)({
  height: "100%",
  flex: 1,
  position: "relative",
  overflow: "hidden",
});

const Container = styled(Box)({
  position: "absolute",
  inset: 0,
  overflow: "auto",
});

export const HTMLPreviewBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>
  );
};
