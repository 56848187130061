import { useForm } from "@xtreamsrl/react-forms";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { FormProvider } from "@xtreamsrl/react-forms";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Divider } from "@xtreamsrl/react-ui-kit/Divider";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Select } from "@xtreamsrl/react-ui-kit/Select";
import { SelectBasic } from "@xtreamsrl/react-ui-kit/SelectBasic";
import {
  createContext,
  useState,
  useCallback,
  FormEventHandler,
  useMemo,
} from "react";
import { CommunicationType } from "../../contentCreation/types";
import { TemplateFixedItem } from "../components/TemplateFixedItem.tsx";
import { TemplatePreview } from "../components/TemplatePreview.tsx";
import { TemplateSections } from "../components/TemplateSections.tsx";
import {
  ComponentType,
  GenericTemplateComponent,
  isDemTemplate,
} from "../types";
import { TextInput } from "@xtreamsrl/react-ui-kit/TextInput";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import {
  validationSchemas,
  basicInitialValues,
  removeDescriptionsIfEmpty,
  getValuesWithMaxLengthParsed,
  moveSectionTitleInsideComponents,
} from "../utils";
import { TemplatesEvents, useTracker } from "../analytics";

export const CommunicationTypeContext = createContext<CommunicationType>(
  CommunicationType.DEM,
);

export const LayoutCreator = ({
  closeCreator,
  onSubmit,
  initialValues: initialFormValues,
  communicationType: externallySetCommunicationType,
  isLoading,
}: {
  closeCreator: () => void;
  onSubmit: (values: GenericTemplateComponent) => void;
  initialValues?: GenericTemplateComponent;
  communicationType?: CommunicationType;
  isLoading?: boolean;
}) => {
  const t = useTranslate();
  const { track } = useTracker();

  // if the communicationType is received as prop, it means that the user is duplicating or updating a template or the user is in the contentCreation path.
  // in this two cases the communicationType is already set and the user can't change it -> see the disabled prop in the SelectBasic component below
  // if the communicationType is not received as prop, the default value is DEM (it means that the user is creating a new template from the archive and will be able to choose the communicationType freely)
  const [communicationType, setCommunicationType] = useState<CommunicationType>(
    externallySetCommunicationType || CommunicationType.DEM,
  );

  const validationSchema = validationSchemas[communicationType];

  const initialValues = useMemo(() => {
    const initialValues =
      initialFormValues || basicInitialValues[communicationType];
    if (isDemTemplate(initialValues)) {
      return moveSectionTitleInsideComponents(initialValues);
    }
    return initialValues;
  }, [initialFormValues, communicationType]);

  const form = useForm({ initialValues, validationSchema, mode: "onBlur" });

  const [hasTitle, setHasTitle] = useState(
    "mainTitle" in initialValues && initialValues.mainTitle !== undefined,
  );
  function addTitle() {
    form.formProps.setValue("mainTitle", {
      type: ComponentType.MAIN_TITLE,
      description: undefined,
      maxLength: 20,
      editable: true,
    });
    setHasTitle(true);
  }

  function removeTitle() {
    setHasTitle(false);
    form.formProps.setValue("mainTitle", undefined);
  }

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const handler = form.formProps.handleSubmit((values) => {
        const updatedValues = removeDescriptionsIfEmpty(
          getValuesWithMaxLengthParsed(values),
        );
        console.log("updatedValues", updatedValues);
        onSubmit(updatedValues);
      });
      handler(e).catch((error) => {
        console.error("Error on submit", error);
      });
    },
    [form.formProps, onSubmit],
  );

  return (
    <CommunicationTypeContext.Provider value={communicationType}>
      <FormProvider {...form.formProps}>
        <form style={{ height: "100%" }} onSubmit={handleSubmit}>
          <Flex flex={3} direction="column" gap="sm-8" height="100%">
            {/* View's header */}
            <Box>
              <Typography variant="header/lg/bold">
                {t("layout.creation.title")}
              </Typography>
            </Box>
            <Divider />
            <Flex gap="1rem" flex={1} flexBasis="12rem" overflow="auto">
              <Box px="1rem" flex="1 1 auto" overflow="auto">
                <TemplatePreview />
              </Box>
              <Divider direction="vertical" />
              <Flex
                flex="0 0 35rem"
                direction="column"
                gap="sm-6"
                height="100%"
                width="100%"
                overflow="auto"
              >
                <SelectBasic
                  size="md"
                  name="template-type"
                  startInputAddOn={
                    <TextInput.AddOn>
                      {t("template.creation.communicationType")}
                    </TextInput.AddOn>
                  }
                  // if the communicationType is received as prop, the user can't change it. It means that the user is duplicating or updating a template or the user is in the contentCreation path
                  disabled={!!externallySetCommunicationType}
                  value={communicationType}
                  onChange={(e) => {
                    setCommunicationType(e.target.value as CommunicationType);
                    // resetForm(e.target.value as CommunicationType);
                  }}
                >
                  {Object.values(CommunicationType).map((type) => (
                    <Select.Option key={type} value={type}>
                      {t(`shared.communicationTypes.${type}.title`)}
                    </Select.Option>
                  ))}
                </SelectBasic>
                {/* TODO: abstract on some info line hasSubject or similar */}
                {(communicationType === CommunicationType.DEM ||
                  communicationType === CommunicationType.PAPER_MAIL) && (
                  <>
                    <TemplateFixedItem
                      showDescription
                      communicationType={communicationType}
                      fieldName="subject"
                      value={ComponentType.SUBJECT}
                    />
                    {!hasTitle ? (
                      <Box alignSelf="center">
                        <Button
                          size="sm"
                          variant="tinted"
                          leftIcon={<Icon name="Plus" />}
                          onClick={() => {
                            track(TemplatesEvents.AddTemplateTitle);
                            addTitle();
                          }}
                        >
                          {t("template.creation.addTitle")}
                        </Button>
                      </Box>
                    ) : (
                      <TemplateFixedItem
                        showDescription
                        communicationType={communicationType}
                        fieldName="mainTitle"
                        value={ComponentType.MAIN_TITLE}
                        remove={() => {
                          track(TemplatesEvents.RemoveTemplateTitle);
                          removeTitle();
                        }}
                      />
                    )}
                  </>
                )}
                <TemplateSections showDescription />
              </Flex>
            </Flex>
            <Divider />
            <Flex justifyContent="flex-end" gap="sm-4">
              <Button
                type="button"
                variant="outlined"
                onClick={closeCreator}
                color="grey"
                disabled={isLoading}
              >
                {t("template.creation.cancel")}
              </Button>
              <Button
                testId="create-template"
                type="submit"
                variant="filled"
                loading={isLoading}
              >
                {t("template.creation.submit")}
              </Button>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    </CommunicationTypeContext.Provider>
  );
};
