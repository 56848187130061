import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTemplateAPI } from "../apis/deleteTemplate.ts";
import { templatesListQueryKeys } from "../queries/useTemplateListQuery.ts";
import { templateDetailsQueryKeys } from "../queries/useTemplateDetailsQuery.ts";

export function useDeleteTemplateMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteTemplateAPI,
    async onMutate({ templateId }) {
      // Stop ongoing details queries
      await Promise.allSettled([
        queryClient.cancelQueries({
          queryKey: templateDetailsQueryKeys.byId(templateId),
          exact: true,
        }),
        // Stop ongoing listing queries
        queryClient.cancelQueries({
          queryKey: templatesListQueryKeys.all,
        }),
      ]);
    },
    async onSuccess(_, { templateId }) {
      // Remove deleted template from cache
      queryClient.removeQueries({
        queryKey: templateDetailsQueryKeys.byId(templateId),
        exact: true,
      });
      // Trigger a refetch of the listing query
      const listQueryKey = templatesListQueryKeys.all;
      await queryClient.invalidateQueries({
        queryKey: listQueryKey,
      });
    },
  });
}
