import { useTranslate } from "@xtreamsrl/react-i18n";
import { TranslationKeys } from "src/_shared/assets/i18n";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { TextInputBasic } from "@xtreamsrl/react-ui-kit/TextInputBasic";
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useState,
} from "react";
import { TextInput } from "@xtreamsrl/react-ui-kit/TextInput";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import {
  AiMagicIcon,
  ArrowExpand02Icon,
  ArrowShrink02Icon,
  BlendIcon,
} from "hugeicons-react";

type PrimaryActionsProps = {
  onShorten: () => void;
  onExpand: () => void;
  onRephrase: () => void;
  onModify: (prompt: string) => void;
};

const StyledMenuList = styled(MenuList)({
  padding: 0,
});
const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.grey[9],
  "& > svg": {
    color: "inherit",
    height: "1.25rem",
    width: "1.25rem",
  },
}));

export function ChooseAction(props: PrimaryActionsProps) {
  const t = useTranslate();
  const { onModify, onShorten, onExpand, onRephrase } = props;

  return (
    <>
      <ModifyForm onModify={onModify} />
      <StyledMenuList>
        <MenuItem onClick={onShorten}>
          <StyledListItemIcon>
            <ArrowShrink02Icon />
          </StyledListItemIcon>
          <Typography>
            {t("editing.shorten" satisfies TranslationKeys)}
          </Typography>
        </MenuItem>
        <MenuItem onClick={onExpand}>
          <StyledListItemIcon>
            <ArrowExpand02Icon />
          </StyledListItemIcon>
          <Typography>
            {t("editing.expand" satisfies TranslationKeys)}
          </Typography>
        </MenuItem>
        <MenuItem onClick={onRephrase}>
          <StyledListItemIcon>
            <BlendIcon />
          </StyledListItemIcon>
          <Typography>
            {t("editing.rephrase" satisfies TranslationKeys)}
          </Typography>
        </MenuItem>
      </StyledMenuList>
    </>
  );
}

const StyledTextInput = styled(TextInputBasic)(({ theme }) => ({
  borderColor: theme.palette.grey[300],
  ".MuiInputBase-root": {
    borderRadius: 0,
    borderWidth: 0,
    borderBottomWidth: "1px",
    paddingInline: theme.spacing("sm-8"),
    paddingBlock: theme.spacing("sm-8"),
  },
  ".MuiInputBase-root.Mui-focused": {
    boxShadow: "none",
    borderColor: "inherit",
  },
  ".MuiInputBase-root:hover": {
    borderColor: "inherit",
  },
  ".MuiInputBase-input::placeholder": {
    color: theme.palette.text.secondary,
    opacity: 1,
  },
  ".MuiInputAdornment-root": {
    color: theme.palette.grey[9],
    "& > svg": {
      color: "inherit",
      height: "1.25rem",
      width: "1.25rem",
      marginRight: "0.1rem",
    },
  },
}));

function ModifyForm({ onModify }: { onModify: (value: string) => void }) {
  const t = useTranslate();
  const [value, setValue] = useState("");

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      onModify(value);
    },
    [onModify, value],
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => setValue(e.target.value),
    [],
  );

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <StyledTextInput
        name="prompt"
        placeholder={t("editing.suggestEdits" satisfies TranslationKeys)}
        value={value}
        onChange={handleChange}
        startInputAddOn={
          <TextInput.AddOn>
            <AiMagicIcon />
          </TextInput.AddOn>
        }
      />
    </form>
  );
}
