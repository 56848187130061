import { createTheme } from "@xtreamsrl/react-ui-kit/theme";

export const theme = createTheme({
  typography: {
    fontFamily: "Inter",
  },
  palette: {
    background: {
      default: "#F4F5FC",
    },
    secondary: {
      "1": "#ebfde8",
      "2": "#d3f9ce",
      "3": "#aaf4a2",
      "4": "#77ea6c",
      "5": "#43db37",
      "6": "#29c220",
      "7": "#1b9b15",
      "8": "#177615",
      "9": "#43db37",
      "10": "#29c220",
      "11": "#143e13",
      "12": "#072c08",
    },
    brand: {
      "1": "#f3f4ff",
      "2": "#e0e2ff",
      "3": "#eff2fe",
      "4": "#e2e6fd",
      "5": "#cad1fb",
      "6": "#aab2f7",
      "7": "#888bf1",
      "8": "#716be9",
      "9": "#5f4ddb",
      "10": "#5341c1",
      "11": "#44379c",
      "12": "#3b337c",
    },
    grey: {
      1: "#FBFCFF",
      2: "#F6F9FE",
      3: "#ECF1F8",
      4: "#E3E9F2",
      5: "#DAE2EE",
      6: "#D1DBE9",
      7: "#C4D0E1",
      8: "#ACBDD4",
      9: "#808FA4",
      10: "#758499",
      11: "#586577",
      12: "#1A202A",
    },
    info: {
      1: "#FCFDFF",
      2: "#F5FAFF",
      3: "#E9F3FF",
      4: "#D8ECFF",
      5: "#C5E2FF",
      6: "#AED4FF",
      7: "#93C2FF",
      8: "#6AA9FF",
      9: "#64A2FE",
      10: "#5996F1",
      11: "#2E6AC1",
      12: "#002E6D",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          paddingTop: "3rem",
          paddingInline: "1.5rem",
          paddingBottom: "1.5rem",
          margin: 0,
          ".ui-kit-close": {
            display: "none !important",
          },
          "& > .MuiStack-root": {
            padding: 0,
          },
        },
      },
    },
    MuiInputBase: {
      // These styles need to be re-applied because there is a bug. Have fun :)
      variants: [
        {
          props: { size: "lg" },
          style: ({ theme }) => ({
            padding: theme.spacing("sm-6", "sm-8") + " !important",
          }),
        },
        {
          props: { size: "md" },
          style: ({ theme }) => ({
            padding: theme.spacing("sm-5", "sm-6") + " !important",
          }),
        },
        {
          props: { size: "sm" },
          style: ({ theme }) => ({
            padding: theme.spacing("sm-3", "sm-6") + " !important",
          }),
        },
      ],
    },
  },
});

export type Theme = typeof theme;
