import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { HTMLRenderer, HTMLRendererAPI } from "./HTMLRenderer";
import DomPurify from "dompurify";
import { Theme } from "@reaidy/components";
import { useTheme } from "@mui/material/styles";

export type HTMLEditorProps = {
  children: string;
  onChange?: (nodeId: string, event: Event) => void;
  onFocus?: (nodeId: string, event: Event) => void;
  onBlur?: (nodeId: string, event: Event) => void;
};

type HTMLEditorEvent<T extends keyof HTMLEditorProps> = NonNullable<
  HTMLEditorProps[T]
>;

export type HTMLEditorChangeEventHandler = HTMLEditorEvent<"onChange">;
export type HTMLEditorFocusEventHandler = HTMLEditorEvent<"onFocus">;
export type HTMLEditorBlurEventHandler = HTMLEditorEvent<"onBlur">;

const selectors = {
  node: "[data-content-id]",
  editable: "[data-content-editable=true]",
  suspended: "[data-content-suspended=true]",
  focused: "[data-content-focused=true]",
  contentTypes: {
    subject: "[data-content-type=subject]",
    mainTitle: "[data-content-type=main-title]",
    sectionTitle: "[data-content-type=section-title]",
    text: "[data-content-type=text]",
    bulletPoint: "[data-content-type=bullet-point]",
    cta: "[data-content-type=cta]",
    titledBulletPoint: "[data-content-type=titled-bullet-point]",
  },
};

const modifierClasses = {
  lowContrast: "low-contrast",
};

const randomId = Math.random().toString(36).slice(2, 8);
const cssVar = (a: string) => `--_${randomId}_` + a;
const referenceVar = (a: string) => `var(${cssVar(a)})`;

const createStylesheet = (theme: Theme) => ` 
  :host {
   ${cssVar("content-color")}: ${theme.palette.brand[9]};
   ${cssVar("page-background")}: ${theme.palette.common.white};
  }

  body {
    position: relative;
  }

  body::before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${referenceVar("page-background")};
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
    z-index: 1;    
    display: block;
    position: absolute;
  }

  body.${modifierClasses.lowContrast}::before {
    opacity: 0.7; 
  }
  
  ${selectors.editable} {
    position: relative; 
  }

  ${selectors.editable}::selection,
  ${selectors.editable} *::selection {
      background-color: color-mix(in srgb, ${referenceVar("content-color")} 30%, transparent); 
  }
 
  ${selectors.focused},
  ${selectors.editable}:focus,
  ${selectors.editable}:hover {
    position: relative;
    outline: color-mix(in srgb, ${referenceVar("content-color")} 50%, transparent) solid 2px;
    border-radius: 2px;
    transition-duration: 0;
    z-index: 2;
    cursor: text;
  }

  ${selectors.editable}:hover {
    background-color: color-mix(in srgb, ${referenceVar("content-color")} 20%, transparent);
    transition: background-color 0.2s;
  }

  ${selectors.focused},
  ${selectors.editable}:focus {
    cursor: text;
    background-color: transparent;
  }
  
  ${selectors.suspended} {
    pointer-events: none;
    user-select: none;
    opacity: 0.5;
    transition-property: opacity;
    transition-delay: var(--content-suspend-delay, 0s);
    transition-duration: var(--content-suspend-duration, 0.2s);
  }

  ${selectors.contentTypes.subject} {
    font-size: 1.5rem;
    font-weight: bold;
  }

  ${selectors.contentTypes.cta} {
    display: block;
    font-weight: bold;
    font-size: 1.25rem;
  }

  ${selectors.contentTypes.mainTitle} {
    font-size: 2rem;
    font-weight: bold;
  }

  ${selectors.contentTypes.bulletPoint},
  ${selectors.contentTypes.titledBulletPoint} {
    margin-bottom: 0.7em;
  }
  
`;

function sanitizeHTML(html: string) {
  // Be aware, that the html structure might change
  // Example: a <div> inside a <p> will be converted to a <div> outside a <p>
  return DomPurify.sanitize(html, {
    ADD_ATTR: ["data-content-id", "data-content-editable"],
    ADD_TAGS: ["style"],
  });
}

const nodeHelpers = {
  getNodeId: (node: HTMLElement) => node.dataset.contentId,
  setNodeId: (node: HTMLElement, id: string | null) => {
    if (id === null) delete node.dataset.contentId;
    else node.dataset.contentId = id;
  },
  isEditable: (node: HTMLElement) => node.dataset.contentEditable === "true",
  setIsEditable: (node: HTMLElement, editable: boolean) => {
    node.dataset.contentEditable = editable ? "true" : "false";
    if (editable) node.contentEditable = "true";
  },
  isFocused: (node: HTMLElement) => {
    return node.dataset.contentFocused;
  },
  setFocused: (node: HTMLElement, focused: boolean = true) => {
    node.dataset.contentFocused = focused ? "true" : undefined;
    node
      .closest("body")
      ?.classList.toggle(modifierClasses.lowContrast, focused);
  },
  getContent: (node: HTMLElement) => node.innerHTML ?? "",
  setContent: (node: HTMLElement, content: string) => {
    node.innerHTML = sanitizeHTML(content);
  },
  getTextContent: (node: HTMLElement) => node.textContent ?? "",
  setTextContent: (node: HTMLElement, content: string) => {
    node.textContent = content;
  },
  getOriginalContent: (node: HTMLElement) => node.dataset.contentOriginal,
  setOriginalContent: (node: HTMLElement, content: string) => {
    node.dataset.contentOriginal = content;
  },
  isSuspended: (node: HTMLElement) => node.dataset.contentSuspended === "true",
  setIsSuspended: (node: HTMLElement, suspended: boolean) => {
    node.dataset.contentSuspended = suspended ? "true" : "false";
  },
};

export type HTMLEditorContentInfo = {
  id: string;
  editable: boolean;
  content: string;
  textContent: string;
  original?: string;
  suspended: boolean;
};

const parseContentNode = (node: HTMLElement): HTMLEditorContentInfo => {
  const nodeId = nodeHelpers.getNodeId(node);
  if (!nodeId) throw new Error("Node does not have a content id");
  return {
    id: nodeId,
    content: nodeHelpers.getContent(node),
    textContent: nodeHelpers.getTextContent(node),
    original: nodeHelpers.getOriginalContent(node),
    editable: nodeHelpers.isEditable(node),
    suspended: nodeHelpers.isSuspended(node),
  };
};

export type HTMLEditorAPI = {
  renderer: HTMLRendererAPI | null;
  sanitizeHTML: (html: string) => string;
  updateContent: (nodeId: string, content: string) => void;
  getContentNode: (nodeId: string) => HTMLElement | null;
  getContentInfo: (nodeId: string) => HTMLEditorContentInfo | null;
  suspendContent: (nodeId: string) => void;
  unsuspendContent: (nodeId: string) => void;
  focusContent: (nodeId: string) => void;
  blurContent: (nodeId: string) => void;
  suspendAll: () => void;
  unsuspendAll: () => void;
};

/**
 * This component renders HTML content and makes it editable.
 *
 */
export const HTMLEditor = forwardRef<HTMLEditorAPI, HTMLEditorProps>(
  function HTMLEditor(props, ref) {
    const { children, onChange, onFocus, onBlur } = props;
    const theme = useTheme();
    const stylesheet = createStylesheet(theme);

    const rendererAPI = useRef<HTMLRendererAPI>(null);
    const nodeMap = useRef(new Map<string, HTMLElement>());
    const editableNodes = useRef(new Set<HTMLElement>());

    const safeHtml = useMemo(() => {
      return sanitizeHTML(children);
    }, [children]);

    useImperativeHandle(ref, () => {
      const getContentNode = (nodeId: string) => {
        return nodeMap.current.get(nodeId) ?? null;
      };

      const getContentInfo = (nodeId: string) => {
        const node = getContentNode(nodeId);
        if (!node) return null;
        return parseContentNode(node);
      };

      const updateContent = (nodeId: string, content: string) => {
        const node = getContentNode(nodeId);
        if (!node) {
          console.error(`Node with id ${nodeId} not found`);
          return;
        }
        nodeHelpers.setContent(node, content);
        nodeHelpers.setOriginalContent(node, content);
      };

      const suspendContent = (nodeId: string) => {
        // mark the node as suspended
        nodeHelpers.setIsSuspended(getContentNode(nodeId)!, true);
      };

      const unsuspendContent = (nodeId: string) => {
        // mark the node as unsuspended
        nodeHelpers.setIsSuspended(getContentNode(nodeId)!, false);
      };

      const suspendAll = () => {
        editableNodes.current.forEach((node) => {
          nodeHelpers.setIsSuspended(node, true);
        });
      };
      const unsuspendAll = () => {
        editableNodes.current.forEach((node) => {
          nodeHelpers.setIsSuspended(node, false);
        });
      };

      const focusContent = (nodeId: string) => {
        const node = getContentNode(nodeId)!;
        nodeHelpers.setFocused(node, true);
      };

      const blurContent = (nodeId: string) => {
        const node = getContentNode(nodeId)!;
        nodeHelpers.setFocused(node, false);
      };

      return {
        renderer: rendererAPI.current,
        sanitizeHTML,
        updateContent,
        getContentNode,
        getContentInfo,
        suspendContent,
        unsuspendContent,
        suspendAll,
        unsuspendAll,
        focusContent,
        blurContent,
      };
    });

    const handleNodeInput = useCallback(
      (event: Event) => {
        const target = event.target as HTMLElement;
        if (!target.dataset.contentId) return;
        onChange?.(target.dataset.contentId, event);
      },
      [onChange],
    );

    const handleNodeFocus = useCallback(
      (event: Event) => {
        const target = event.target as HTMLElement;
        if (!target.dataset.contentId) return;
        onFocus?.(target.dataset.contentId, event);
      },
      [onFocus],
    );

    const handleNodeBlur = useCallback(
      (event: Event) => {
        const target = event.target as HTMLElement;
        if (!target.dataset.contentId) return;
        onBlur?.(target.dataset.contentId, event);
      },
      [onBlur],
    );

    useEffect(() => {
      const rootElement = rendererAPI.current?.shadowRoot;
      if (!rootElement) throw new Error("Shadow root not found");

      // Make all anchors inactive and preserve the href attribute
      rootElement.querySelectorAll("a").forEach((a) => {
        const href = a.getAttribute("href");
        a.removeAttribute("href");
        a.dataset.href = href || "";
      }, []);

      // Change structure of content type subject
      rootElement
        .querySelectorAll(selectors.contentTypes.subject)
        .forEach((node) => {
          if (!(node instanceof HTMLElement)) return;
          const nodeId = nodeHelpers.getNodeId(node);
          if (!nodeId) return;

          nodeHelpers.setIsEditable(node, false);
          nodeHelpers.setNodeId(node, null);

          const subjectNode = document.createElement("span");
          subjectNode.innerText = "Oggetto: ";

          const contentNode = document.createElement("span");
          contentNode.innerHTML = node.innerHTML;
          nodeHelpers.setNodeId(contentNode, nodeId);
          nodeHelpers.setIsEditable(contentNode, true);

          node.innerHTML = "";
          node.appendChild(subjectNode);
          node.appendChild(contentNode);
        });

      // Grab all content nodes
      rootElement.querySelectorAll(selectors.node).forEach((node) => {
        if (node instanceof HTMLElement && node.dataset.contentId) {
          nodeMap.current.set(node.dataset.contentId, node);
        }
      });

      // Grab all elements that are editable
      rootElement.querySelectorAll(selectors.editable).forEach((node) => {
        if (!(node instanceof HTMLElement)) return;
        const nodeId = nodeHelpers.getNodeId(node);
        if (
          node instanceof HTMLElement &&
          nodeId &&
          nodeMap.current.has(nodeId)
        ) {
          editableNodes.current.add(node);
        }
      });

      // Set attributes to make elements editable
      editableNodes.current.forEach((el) => {
        nodeHelpers.setIsEditable(el, true);
        nodeHelpers.setOriginalContent(el, nodeHelpers.getContent(el));
      });

      // Add event listeners to editable
      const cleanupFunctions = new Set<() => void>();
      editableNodes.current.forEach((el) => {
        el.addEventListener("input", handleNodeInput);
        el.addEventListener("focus", handleNodeFocus);
        el.addEventListener("blur", handleNodeBlur);

        cleanupFunctions.add(() => {
          el.removeEventListener("input", handleNodeInput);
          el.removeEventListener("focus", handleNodeFocus);
          el.removeEventListener("blur", handleNodeBlur);
        });
      });

      return () => {
        cleanupFunctions.forEach((cleanup) => cleanup());
      };
    }, [children, handleNodeInput, handleNodeFocus, handleNodeBlur]);

    return (
      <HTMLRenderer ref={rendererAPI} extraStyles={stylesheet}>
        {safeHtml}
      </HTMLRenderer>
    );
  },
);
