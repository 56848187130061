import { useDateTimeFormat, useTranslate } from "@xtreamsrl/react-i18n";
import { useBrowserNavigation } from "@xtreamsrl/react-routing";
import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Card } from "@xtreamsrl/react-ui-kit/Card";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { AddSquareIcon, MoreVerticalCircle01Icon } from "hugeicons-react";
import {
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
  useId,
} from "react";
import { TopBar } from "src/_shared/components/TopBar";
import { SearchCommFilters } from "src/archive/apis/searchCommunications";
import { useCommunicationItemActions } from "src/archive/hooks/useCommunicationItemActions";
import { useCommunicationsList } from "src/archive/hooks/useCommunicationsList";
import { CommunicationElement } from "src/archive/types";
import { resetState } from "src/contentCreation/slice/contentCreationSlice";
import {
  communicationsTypes,
  CommunicationType,
} from "src/contentCreation/types";
import { pages } from "@reaidy/pages";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@xtreamsrl/react-ui-kit/Divider";
import { ConfirmationWithFeedbackModal } from "src/_shared/components/ConfirmationWithFeedbackModal";
import { TextInput } from "@xtreamsrl/react-ui-kit/TextInput";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing("md-5"),
  marginInline: theme.spacing("md-4"),
  marginBlock: theme.spacing("md-5"),
  [theme.breakpoints.up("md")]: {
    marginInline: theme.spacing("lg-1"),
  },
}));

export function Archive() {
  return (
    <Box width="100%" overflow="auto" height="100vh">
      <TopBar />
      <Container>
        <Title />
        <MainControls />
        <CommunicationList />
      </Container>
    </Box>
  );
}

function Title() {
  const t = useTranslate();
  return (
    <Typography variant="header/sm/semibold">{t("archive.title")}</Typography>
  );
}

const StyledButton = styled(Button)({
  width: "max-content",
});

function MainControls() {
  const t = useTranslate();
  const { goTo } = useBrowserNavigation();
  // const { track } = useTracker();

  const handleNewCommunication = useCallback(() => {
    // track(DashboardEvents.GoToContentCreation);
    resetState();
    goTo(pages.contentCreation);
  }, [goTo]);

  return (
    <Flex direction="column" gap="sm-4">
      <StyledButton
        size="md"
        onClick={handleNewCommunication}
        leftIcon={
          <Icon>
            <AddSquareIcon />
          </Icon>
        }
      >
        {t("archive.controls.newCommunication")}
      </StyledButton>
    </Flex>
  );
}

const ListContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing("sm-6"),
}));

function CommunicationList() {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [filters] = useState<SearchCommFilters>({});

  const { communications = [] } = useCommunicationsList({
    filters,
    scrollerRef,
  });

  return (
    <Flex direction="column" gap="sm-5">
      <ListContainer ref={scrollerRef}>
        {communications.map((communication) => (
          <CommunicationItem
            key={communication.id}
            communication={communication}
          />
        ))}
      </ListContainer>
    </Flex>
  );
}

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  transition: "background-color 0.2s",
  "&:hover": {
    backgroundColor: theme.palette.grey[2],
  },
}));

const CardContent = styled("div")((props) => ({
  display: "flex",
  gap: props.theme.spacing("sm-8"),
  padding: props.theme.spacing("sm-5"),
  alignItems: "center",
}));

const DangerMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.error[11],
  "&:hover": {
    backgroundColor: theme.palette.error[3],
  },
}));

function CommunicationItem({
  communication,
}: {
  communication: CommunicationElement;
}) {
  const t = useTranslate();
  const itemId = useId();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const menuId = "comm-menu-" + itemId;
  const menuButtonId = "comm-menu-button-" + itemId;
  const actions = useCommunicationItemActions(communication);

  const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      if (e.target instanceof HTMLElement && e.target.id !== menuButtonId) {
        actions.edit();
      }
    },
    [actions, menuButtonId],
  );

  const { edit: handleEdit, dupFromBrief: handleDuplicateFromBrief } = actions;

  const handleDelete = useCallback(() => {
    actions.openConfirmation("DELETE");
    setMenuOpen(false);
  }, [actions]);

  const handleDuplicate = useCallback(() => {
    actions.openConfirmation("DUP_FROM_DRAFT");
    setMenuOpen(false);
  }, [setMenuOpen, actions]);

  return (
    <>
      <StyledCard onClick={handleClick}>
        <CardContent>
          <CommTypeIcon type={communication.type} />
          <CommName communication={communication} />
          <CommDate communication={communication} />
          <CommMenuButton
            buttonId={menuButtonId}
            menuId={menuId}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            setMenuAnchor={setAnchorEl}
          />
        </CardContent>
      </StyledCard>
      <CommMenu
        menuId={menuId}
        menuOpen={menuOpen}
        menuAnchor={anchorEl}
        setMenuOpen={setMenuOpen}
      >
        <MenuItem onClick={handleEdit}>
          {t("dashboard.communications.actions.edit")}
        </MenuItem>
        <MenuItem onClick={handleDuplicate}>
          {t("dashboard.communications.actions.duplicate")}
        </MenuItem>
        <MenuItem onClick={handleDuplicateFromBrief}>
          {t("dashboard.communications.actions.duplicateFromBrief")}
        </MenuItem>
        <Divider />
        <DangerMenuItem onClick={handleDelete}>
          {t("dashboard.communications.actions.delete")}
        </DangerMenuItem>
      </CommMenu>
      <CommActionModal communication={communication} {...actions} />
    </>
  );
}

const StyledIcon = styled(Icon)(({ theme }) => ({
  backgroundColor: theme.palette.brand[1],
  color: theme.palette.brand[9],
  width: "2.5rem",
  height: "2.5rem",
  borderRadius: theme.spacing("sm-2"),
  padding: theme.spacing("sm-4"),
}));

function CommTypeIcon({ type }: { type: CommunicationType }) {
  const Icon = useMemo(() => {
    const commType = communicationsTypes.find((t) => t.code === type);
    return commType?.icon;
  }, [type]);

  if (!Icon) return null;
  return (
    <StyledIcon>
      <Icon />
    </StyledIcon>
  );
}

function CommName({ communication }: { communication: CommunicationElement }) {
  return (
    <Flex direction="column" overflow="hidden" flex="1">
      <Typography
        variant="body/sm/regular"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        {...{ overflow: "hidden" }}
      >
        {communication.name}
      </Typography>
      <Typography variant="body/xs/regular" color="grey">
        {communication.type}
      </Typography>
    </Flex>
  );
}

function CommDate({ communication }: { communication: CommunicationElement }) {
  const dtf = useDateTimeFormat();
  const t = useTranslate();

  const formattedDate = dtf(
    communication.updatedAt,
    "date.formats.humanAbbreviated",
  );

  if (communication.updatedAt == null) {
    return null;
  }
  return (
    <Typography
      variant="body/sm/regular"
      {...{
        paddingInline: "4rem",
      }}
    >
      {t("dashboard.communications.updatedAt", { date: formattedDate })}
    </Typography>
  );
}

function CommMenuButton({
  menuOpen,
  setMenuOpen,
  setMenuAnchor,
  menuId,
  buttonId,
}: {
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  setMenuAnchor: (anchor: HTMLElement | null) => void;
  menuId: string;
  buttonId: string;
}) {
  const a11yProps = {
    id: buttonId,
    "aria-controls": menuOpen ? menuId : undefined,
    "aria-haspopup": true,
    "aria-expanded": menuOpen ? true : undefined,
  };

  const handleClick = useCallback<MouseEventHandler>(
    (e) => {
      setMenuAnchor(e.currentTarget as HTMLElement);
      setMenuOpen(true);
    },
    [setMenuAnchor, setMenuOpen],
  );

  return (
    <Button
      {...a11yProps}
      onClick={handleClick}
      size="md"
      onlyIcon
      variant="plain"
      color="grey"
      icon={
        <Icon>
          <MoreVerticalCircle01Icon fill="currentColor" />
        </Icon>
      }
    />
  );
}

function CommMenu({
  children,
  menuId,
  menuOpen,
  setMenuOpen,
  menuAnchor,
}: PropsWithChildren<{
  setMenuOpen: (open: boolean) => void;
  menuId: string;
  menuOpen: boolean;
  menuAnchor: HTMLElement | null;
}>) {
  const handleClose = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  const a11yProps = {
    id: menuId,
    "aria-expanded": menuOpen ? true : undefined,
  };

  return (
    <Menu
      {...a11yProps}
      anchorEl={menuAnchor}
      open={menuOpen}
      onClose={handleClose}
    >
      {children}
    </Menu>
  );
}

function CommActionModal({
  op,
  modalState,
  communication,
  nameAfterDuplication,
  setNameAfterDuplication,
  onConfirm,
  onCancel,
  failureCode,
}: Pick<
  ReturnType<typeof useCommunicationItemActions>,
  | "op"
  | "modalState"
  | "nameAfterDuplication"
  | "setNameAfterDuplication"
  | "onConfirm"
  | "onCancel"
  | "failureCode"
> & {
  communication: CommunicationElement;
}) {
  const t = useTranslate();
  const operationsKey: {
    DUP_FROM_DRAFT: "duplicateFromDraft";
    DUP_FROM_PERSONAS: "duplicateFromPersonas";
    DELETE: "communicationDeletion";
  } = {
    DUP_FROM_DRAFT: "duplicateFromDraft",
    DUP_FROM_PERSONAS: "duplicateFromPersonas",
    DELETE: "communicationDeletion",
  };

  const feedbackText = {
    headerKey: `archive.${operationsKey[op]}.failureHeader`,
    contentKey: `archive.${operationsKey[op]}.failure.${failureCode}`,
  };
  return (
    <ConfirmationWithFeedbackModal
      onConfirm={onConfirm[op]}
      confirmationHeaderKey={`archive.${operationsKey[op]}.header`}
      feedbackText={feedbackText}
      state={modalState}
      onCancel={onCancel[op]}
    >
      {op !== "DELETE" ? (
        <>
          <Typography variant="body/sm/regular">
            {t(`archive.${operationsKey[op]}.content`)}
          </Typography>
          <TextInput
            label=""
            name="name"
            value={nameAfterDuplication}
            onChange={(event) => setNameAfterDuplication(event.target.value)}
          />
        </>
      ) : (
        <Typography variant="body/sm/regular">
          {t("archive.communicationDeletion.checkMessage", {
            communicationName: communication.name,
          })}
        </Typography>
      )}
    </ConfirmationWithFeedbackModal>
  );
}
