import { useMutation } from "@tanstack/react-query";
import { default as fetch } from "src/_shared/fetch";
import { EventSourceParserStream } from "eventsource-parser/stream";

export type TextModChunk = {
  delta: string;
};

type ErrorData = {
  message: string;
  code: string;
  id: string;
};

type ConversationData = {
  conversationId: string;
};

export type TextModType =
  | "REPHRASE"
  | "SUMMARIZE"
  | "EXPAND"
  | "CUSTOM_PROMPT_MODIFICATION";

export type GenerateTextModInput = {
  blockId?: string;
  draftId: string;
  communicationId: string;
  operationType: TextModType;
  directions?: string;
  conversationId?: string | null;
};

const noop = () => {};

export function useGenerateTextModMutation({
  onNewConversation = noop,
  onStreamChunk = noop,
  onStreamStart = noop,
  onStreamEnd = noop,
  onStreamError = noop,
  onStreamComplete = noop,
}: {
  onNewConversation?: (conversation: ConversationData) => void;
  onStreamChunk: ({ delta }: { delta: string }) => void;
  onStreamEnd?: () => void;
  onStreamError?: (error: unknown) => void;
  onStreamStart?: () => void;
  onStreamComplete?: () => void;
}) {
  return useMutation({
    mutationFn: generateTextMod,
    async onSuccess(stream) {
      onStreamStart();
      try {
        for await (const chunk of readStream(stream)) {
          const data: unknown = JSON.parse(chunk.data);
          if (chunk.event === "newConversation") {
            onNewConversation(data as ConversationData);
          } else if (chunk.event === "message") {
            onStreamChunk(data as TextModChunk);
          } else if (chunk.event === "error") {
            const error = data as ErrorData;
            throw new Error(`${error.code}: ${error.message}`);
          }
        }
        onStreamComplete();
      } catch (e) {
        console.error(e);
        onStreamError(e);
      } finally {
        onStreamEnd();
      }
    },
  });
}

async function generateTextMod(input: GenerateTextModInput) {
  const response = await fetch.post<Response>("/text-modification", input, {
    extractJson: false,
  });
  const stream = response.body;
  if (!stream) {
    throw new Error("No stream");
  }
  return stream;
}

async function* readStream(stream: ReadableStream<Uint8Array>) {
  const eventStream = stream
    .pipeThrough(new TextDecoderStream())
    .pipeThrough(new EventSourceParserStream())
    .getReader();

  while (true) {
    const { done, value } = await eventStream.read();
    if (done) break;
    yield value;
  }
}
