import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { getCommDetailsQuery } from "../queries/useCommDetailsQuery";
import { useCommPersonaDraftQuery } from "../queries/useCommPersonaDraftQuery";
import { Communication, PersonaDraft } from "../types";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { PencilEdit02Icon, PropertyEditIcon } from "hugeicons-react";
import { EditBriefModal } from "../components/EditBriefModal";
import { CommNameInput } from "../components/CommNameInput";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { EditLayoutModal } from "../components/EditLayoutPanel";
import { NewDraftEditor } from "../components/NewDraftEditor";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useParams } from "react-router-dom";
import { useContentCreationSlice } from "../slice/contentCreationSlice";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { TextInput } from "@xtreamsrl/react-ui-kit/TextInput";
import { usePersonaListQuery } from "../queries/usePersonaListQuery";
import { useChangeDraftPersonaMutation } from "../mutations/useChangeDraftPersonaMutation";
import { EditingLayout } from "../components/CommEditingLayout";
import { CommDraftList } from "../components/CommDraftList";
import { CommEditingNewDraft } from "../components/CommEditingNewDraft";
import { SelectBasic } from "@xtreamsrl/react-ui-kit/SelectBasic";

export function CommEditing() {
  const { communicationId } = useParams<{ communicationId: string }>();
  const { data } = useQuery({
    ...getCommDetailsQuery(communicationId!),
    enabled: !!communicationId,
  });
  const slice = useContentCreationSlice((state) => state.communication);
  const communication = data || slice;

  if (!communication) return null;
  return <CommEditingView communication={communication} />;
}

function CommEditingView({ communication }: { communication: Communication }) {
  const commId = communication.id;
  const { data: personaDrafts = [] } = useQuery({
    ...getCommDetailsQuery(commId),
    select: (data) => data?.personaDrafts,
    initialData: communication,
  });

  const [selectedDraft, setSelectedDraft] = useState<PersonaDraft | undefined>(
    personaDrafts[0],
  );

  return (
    <EditingLayout
      leftPanel={
        selectedDraft && (
          <LeftPanel
            communication={communication}
            selectedDraft={selectedDraft}
            setSelectedDraft={setSelectedDraft}
          />
        )
      }
      rightPanel={
        <RightPanel
          communication={communication}
          selectedDraft={selectedDraft}
          setSelectedDraft={setSelectedDraft}
          personaDrafts={personaDrafts}
        />
      }
    />
  );
}

function LeftPanel({
  setSelectedDraft,
  selectedDraft,
  communication,
}: {
  selectedDraft: PersonaDraft;
  setSelectedDraft: (draft: PersonaDraft) => void;
  communication: Communication;
}) {
  const draftId = selectedDraft?.id;
  const commId = communication.id;
  const { data: draft } = useCommPersonaDraftQuery({
    commId,
    draftId,
  });
  const isLoading = draft?.status === "DRAFT_LOADING";
  const isSaving = draft?.status === "DRAFT_SAVING";
  const isSaved = draft?.status === "DRAFT_SAVED";
  // const isEditable = !(isLoading || isSaving);
  const buttonsDisabled = isLoading || isSaving;

  return (
    <Flex flex={1} direction="column">
      <Flex gap="md-1" mb="md-1">
        <EditBriefButton
          commId={commId}
          disabled={buttonsDisabled}
          selectedDraft={selectedDraft}
          setSelectedDraft={setSelectedDraft}
        />
        {communication.details.layoutSource === "MANUAL" && (
          <EditLayoutButton
            disabled={buttonsDisabled}
            communication={communication}
            setSelectedDraft={setSelectedDraft}
          />
        )}
        <PersonaSelector
          disabled={buttonsDisabled}
          communication={communication}
          selectedDraft={selectedDraft}
        />
      </Flex>
      <ErrorBoundary fallbackRender={renderEditorError}>
        {draft && (
          <NewDraftEditor
            draft={draft}
            communication={communication}
            isLoading={isLoading}
            isSaved={isSaved}
            isSaving={isSaving}
          />
        )}
      </ErrorBoundary>
    </Flex>
  );
}

function renderEditorError({ error }: FallbackProps) {
  return (
    <div role="alert">
      <p>Error</p>
      <pre>
        {error instanceof Error
          ? error.message
          : JSON.stringify(error, null, 2)}
      </pre>
    </div>
  );
}

function RightPanel({
  communication,
  selectedDraft,
  setSelectedDraft,
  personaDrafts,
}: {
  communication: Communication;
  selectedDraft?: PersonaDraft;
  setSelectedDraft: (draft: PersonaDraft) => void;
  personaDrafts: PersonaDraft[];
}) {
  const commId = communication.id;
  return (
    <Flex direction="column" gap="sm-4" height="100%">
      <CommNameInput commId={commId} />
      <CommDraftList
        commId={commId}
        selectedDraft={selectedDraft}
        personaDrafts={personaDrafts}
        setSelectedDraft={setSelectedDraft}
      />
      <CommEditingNewDraft
        setSelectedDraft={setSelectedDraft}
        communication={communication}
      />
    </Flex>
  );
}

const buttonOverrides = {
  sx: {
    ["&[disabled]"]: {
      // Something is overriding the color of the button to white when disabled
      // Since the BG is white, the button is invisible
      color: "inherit",
    },
  },
};

function EditBriefButton({
  commId,
  setSelectedDraft,
  disabled,
}: {
  commId: string;
  selectedDraft: PersonaDraft;
  setSelectedDraft: (draft: PersonaDraft) => void;
  disabled?: boolean;
}) {
  const t = useTranslate();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = useCallback(() => setModalIsOpen(false), [setModalIsOpen]);
  const openModal = useCallback(() => setModalIsOpen(true), [setModalIsOpen]);

  return (
    <>
      <Button
        color="white"
        onClick={openModal}
        disabled={disabled}
        {...buttonOverrides}
        leftIcon={
          <Icon>
            <PencilEdit02Icon style={{ color: "black" }} />
          </Icon>
        }
      >
        {t("commEditing.editBrief.title")}
      </Button>
      {modalIsOpen && (
        <EditBriefModal
          commId={commId}
          isOpen={modalIsOpen}
          handleClose={closeModal}
          setSelectedDraft={setSelectedDraft}
        />
      )}
    </>
  );
}

function EditLayoutButton({
  disabled,
  communication,
  setSelectedDraft,
}: {
  disabled?: boolean;
  communication: Communication;
  setSelectedDraft: (draft: PersonaDraft) => void;
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = useCallback(() => setModalIsOpen(true), []);
  const closeModal = useCallback(() => setModalIsOpen(false), []);
  const t = useTranslate();

  return (
    <>
      <EditLayoutModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        communication={communication}
        setSelectedDraft={setSelectedDraft}
      />
      <Button
        color="white"
        onClick={openModal}
        disabled={disabled}
        {...buttonOverrides}
        leftIcon={
          <Icon>
            <PropertyEditIcon style={{ color: "black" }} />
          </Icon>
        }
      >
        {t("commEditing.editLayout")}
      </Button>
    </>
  );
}

const StyledSelect = styled(SelectBasic)({
  width: "max-content",
  marginLeft: "auto",
});

function PersonaSelector({
  disabled,
  communication,
  selectedDraft,
}: {
  disabled?: boolean;
  communication: Communication;
  selectedDraft: PersonaDraft;
}) {
  const t = useTranslate();
  const [personaId, setPersonaId] = useState(selectedDraft?.personaId);
  const [isGenerating, setIsGenerating] = useState(false);

  const { data: personas = [] } = usePersonaListQuery();
  const { mutate: changeDraftPersona } = useChangeDraftPersonaMutation({
    onStreamStart() {
      setIsGenerating(true);
    },
    onStreamEnd() {
      setIsGenerating(false);
    },
  });

  const draftId = selectedDraft?.id;
  const commId = communication.id;

  useEffect(() => {
    setPersonaId(selectedDraft?.personaId);
  }, [selectedDraft]);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const personaId = e.target.value;
      const currentPersonaId = selectedDraft?.personaId;
      setPersonaId(personaId);
      changeDraftPersona(
        { commId, draftId, personaId },
        {
          onError: (error) => {
            console.error("Error changing persona", error);
            setPersonaId(currentPersonaId);
          },
        },
      );
    },
    [setPersonaId, changeDraftPersona, commId, draftId, selectedDraft],
  );

  return (
    <StyledSelect
      disabled={disabled || isGenerating}
      name="persona"
      startInputAddOn={
        <TextInput.AddOn>{t("commEditing.targetPersona")}</TextInput.AddOn>
      }
      value={personaId}
      onChange={handleChange}
    >
      {personas.map((persona) => (
        <SelectBasic.Option key={persona.id} value={persona.id}>
          {persona.name}
        </SelectBasic.Option>
      ))}
    </StyledSelect>
  );
}
