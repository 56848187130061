import { styled } from "@xtreamsrl/react-ui-kit/styles";

export const TopBar = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.grey[4]}`,
  height: "4.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
