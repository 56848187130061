import fetch from "../../_shared/fetch";
import {
  CommunicationType,
  ImportedLayoutStructure,
} from "../../contentCreation/types";
import { GenericTemplateComponent, TemplateData } from "../types";
import { transformTemplateRequestData } from "../utils";

export type CreateTemplateData = {
  name: string;
  communicationType: CommunicationType;
} & (
  | { template: GenericTemplateComponent; source: "MANUAL" }
  | { template: ImportedLayoutStructure; source: "IMPORTED" }
);

export function createTemplateAPI(
  data: CreateTemplateData,
): Promise<TemplateData> {
  const requestData =
    data.source === "MANUAL" ? transformTemplateRequestData(data) : data;

  return fetch.post("/layout-templates", requestData, { extractJson: true });
}
