import { styled } from "@xtreamsrl/react-ui-kit/styles";
import Paper from "@mui/material/Paper";

export type MenuPosition = { x: number; y: number };

export const MENU_WIDTH = 420;

export const Menu = styled(Paper)<{ position?: MenuPosition }>(
  ({ theme, position }) => ({
    position: "absolute",
    zIndex: 10,
    top: position?.y ?? "-10000px",
    left: position?.x ?? "-10000px",
    opacity: position ? 1 : 0,
    transition: "opacity 0.75s",

    width: MENU_WIDTH,
    border: "solid 1px",
    borderColor: theme.palette.grey[5],
    borderRadius: "4px",
    overflow: "clip",
    marginTop: "1.5rem",
  }),
);
