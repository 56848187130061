import fetch from "../../_shared/fetch";
import { CommunicationType } from "../../contentCreation/types";
import { GenericTemplateComponent, TemplateData } from "../types";
import { transformTemplateRequestData } from "../utils";

export type UpdateTemplateData = {
  templateId: string;
  name: string;
  communicationType: CommunicationType;
  template: GenericTemplateComponent;
};

export function updateTemplate(
  data: UpdateTemplateData,
): Promise<TemplateData> {
  const { templateId, ...dataToTransform } = data;
  const requestData = transformTemplateRequestData(dataToTransform);

  return fetch.put(`/layout-templates/${templateId}`, requestData, {
    extractJson: true,
  });
}
