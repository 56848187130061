import { styled } from "@xtreamsrl/react-ui-kit/styles";

// const gradientColorStart = "rgba(251,252,255,1)";
// const gradientColorEnd = "rgba(255,255,255,0)";

export const EditorWrapper = styled("div")(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.grey["1"],
  overflow: "hidden",
  position: "relative",
  // "::before": {
  //   content: '""',
  //   position: "absolute",
  //   top: 0,
  //   left: 0,
  //   right: 0,
  //   height: "10px",
  //   background: `linear-gradient(to bottom, ${gradientColorStart}, ${gradientColorEnd})`,
  //   pointerEvents: "none",
  //   zIndex: 10,
  // },
}));
