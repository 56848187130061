import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { ReactNode } from "react";

const DIVIDER_COLOR = "rgba(0, 0, 0, 0.12)";

const Grid = styled("section")(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "grid",
  gridTemplateAreas: `
    "h h r"
    "l l r" 
  `,
  gridTemplateRows: "auto 1fr",
  gridTemplateColumns: "1fr 1fr 25rem",

  // Mobile
  [theme.breakpoints.down("lg")]: {
    gridTemplateAreas: `
    "h h h"
    "l l l"
  `,
  },
}));

const Header = styled("header")(({ theme }) => ({
  gridArea: "h",
  paddingInline: theme.spacing("md-5"),
  paddingBlock: theme.spacing("sm-6"),
  borderBottom: "solid 1px",
  borderColor: DIVIDER_COLOR,
  display: "flex",
  alignItems: "center",
  position: "relative",
}));

const LeftPanel = styled("div")(({ theme }) => ({
  gridArea: "l",
  background: "rgba(227, 233, 242, 1)",
  margin: theme.spacing("md-3"),
  paddingBlock: theme.spacing("md-2"),
  paddingInline: theme.spacing("md-4"),
  [`${theme.breakpoints.up("lg")}`]: {
    paddingInline: theme.spacing("lg-1"),
  },
  marginBottom: 0,
  paddingBottom: 0,
  "& > div": {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxWidth: "100ch",
    marginInline: "auto",
  },
}));

const RightPanel = styled("div")(({ theme }) => ({
  gridArea: "r",
  overflow: "auto",

  // Mobile
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export function EditingLayout({
  rightPanel,
  leftPanel,
  heading,
}: {
  rightPanel: ReactNode;
  leftPanel: ReactNode;
  heading?: ReactNode;
}) {
  return (
    <Grid>
      {heading && <Header>{heading}</Header>}
      <LeftPanel>
        <div>{leftPanel}</div>
      </LeftPanel>
      <RightPanel>{rightPanel}</RightPanel>
    </Grid>
  );
}
