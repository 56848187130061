import { useMutation, useQueryClient } from "@tanstack/react-query";
import { default as fetch } from "src/_shared/fetch";
import { commPersonaDraftQueryKeys } from "../queries/useCommPersonaDraftQuery";
import { DraftContent, PersonaDraft } from "../types";

async function generatePersonaDraft(
  {
    commId,
    draftId,
  }: {
    commId: string;
    draftId: string;
  },
  signal?: AbortSignal,
) {
  const response = await fetch.get<Response>(
    `/communications/${commId}/persona-drafts/${draftId}/content`,
    {}, // query params
    false, // extractJson
    signal,
  );
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  const stream = response.body;
  if (!stream) {
    throw new Error("No content stream");
  }
  return stream;
}

type ErrorChunk = {
  id: string;
  message: string;
  code: string;
};

function isErrorChunk(chunk: unknown): chunk is ErrorChunk {
  return (
    typeof chunk === "object" &&
    chunk != null &&
    "id" in chunk &&
    "message" in chunk &&
    "code" in chunk
  );
}

type ContentChunk = {
  contents: DraftContent;
};

function isContentChunk(chunk: unknown): chunk is ContentChunk {
  return typeof chunk === "object" && chunk != null && "contents" in chunk;
}

export function useGeneratePersonaDraftMutation({
  onStreamChunk,
  onStreamError,
  onStreamStart,
  onStreamEnd,
}: {
  onStreamError?: (error: unknown) => void;
  onStreamStart?: () => void;
  onStreamChunk?: (chunk: string) => void;
  onStreamEnd?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: generatePersonaDraft,
    async onSuccess(stream, { commId, draftId }) {
      onStreamStart?.();
      try {
        const reader = stream.pipeThrough(new TextDecoderStream()).getReader();
        const { value } = await reader.read();
        if (value == null) throw new Error("No content stream");
        onStreamChunk?.(value);

        // Parse the first chunk to get the content
        const chunk: unknown = JSON.parse(value);
        if (isErrorChunk(chunk)) {
          throw new Error(chunk.message);
        }
        if (!isContentChunk(chunk)) {
          throw new Error("Invalid content chunk");
        }
        const content = chunk.contents;

        const draft = queryClient.getQueryData<PersonaDraft>(
          commPersonaDraftQueryKeys.byCommIdAndDraftId(commId, draftId),
        );
        if (!draft) {
          throw new Error("Draft not found in cache");
        }

        // Update the draft in the cache on each chunk
        queryClient.setQueryData<PersonaDraft>(
          commPersonaDraftQueryKeys.byCommIdAndDraftId(commId, draftId),
          {
            ...draft,
            draftContent: content,
          },
        );

        // This is a hack to prevent a strange but where the
      } catch (error) {
        console.error(error);
        onStreamError?.(error);
      } finally {
        onStreamEnd?.();
      }
    },
  });
}
