import { PageInfo } from "../../_shared/types";
import {
  CommunicationType,
  ImportedLayoutStructure,
} from "../../contentCreation/types";

// Template selection
export type Template = {
  id: string;
  name: string;
  createdAt: string;
  communicationType: CommunicationType;
} & (
  | {
      source: "IMPORTED";
      structure: ImportedLayoutStructure;
      layoutHtml: string;
    }
  | {
      source: "MANUAL";
      structure: DemTemplateComponent;
    }
);

export function isImportedTemplate(
  template: Template,
): template is Template & { source: "IMPORTED" } {
  return template.source === "IMPORTED";
}

export function isManualTemplate(
  template: Template,
): template is Template & { source: "MANUAL" } {
  return template.source === "MANUAL";
}

export type TemplatesResponse = {
  content: Template[];
  pageInfo: PageInfo;
};

// Template Archive
export type SortDirection = "desc" | "asc" | undefined;

export enum ColumnName {
  createdAt = "createdAt",
  name = "name",
  communicationType = "communicationType",
}

// Template creation
export enum ComponentType {
  SUBJECT = "SUBJECT",
  BULLET_POINT = "BULLET_POINT",
  SECTION = "SECTION",
  MAIN_TITLE = "MAIN_TITLE",
  SECTION_TITLE = "SECTION_TITLE",
  TEXT = "TEXT",
  TITLED_BULLET_POINT = "TITLED_BULLET_POINT",
  CTA = "CTA",
}

export interface BulletPointWithTitleFieldComponent {
  type: ComponentType.TITLED_BULLET_POINT;
  maxTitleLength: number;
  maxContentLength: number;
}

export interface FieldComponent {
  type:
    | ComponentType.SECTION_TITLE
    | ComponentType.TEXT
    | ComponentType.BULLET_POINT
    | ComponentType.CTA;
  maxLength: number;
  editable: boolean;
}

export type SectionFieldComponent =
  | FieldComponent
  | BulletPointWithTitleFieldComponent;

export interface DemSectionContainerComponent {
  type: ComponentType.SECTION;
  components: SectionFieldComponent[];
  description?: string;
  title?: {
    type: ComponentType.SECTION_TITLE;
    maxLength: number;
    editable: boolean;
  };
}

export interface SubjectComponent {
  type: ComponentType.SUBJECT;
  description?: string;
  maxLength: number;
  editable: boolean;
}

export interface MainTitleComponent {
  type: ComponentType.MAIN_TITLE;
  description?: string;
  maxLength: number;
  editable: boolean;
}

export interface DemTemplateComponent {
  subject: SubjectComponent;
  mainTitle?: MainTitleComponent;
  sections: DemSectionContainerComponent[];
}

export interface RCSSectionFieldComponent {
  type: ComponentType.SECTION_TITLE | ComponentType.TEXT | ComponentType.CTA;
  maxLength: number;
  editable: boolean;
}

export interface RCSSectionContainerComponent {
  type: ComponentType.SECTION;
  components: RCSSectionFieldComponent[];
  description?: string;
}

export interface RCSTemplateComponent {
  sections: RCSSectionContainerComponent[];
}

export function isDemTemplate(
  template: GenericTemplateComponent,
): template is DemTemplateComponent {
  return "subject" in template;
}

export type GenericSectionContainerComponent = DemSectionContainerComponent;

// this will be used in the future when we have more than one type of template
export type GenericTemplateComponent =
  | DemTemplateComponent
  | RCSTemplateComponent;

// returned from backend
// TODO: check if communication type is returned inside the structure from the backend or if it's a separate field as below
export type BETemplateData = {
  id: string;
  name: string;
  createdAt: string;
  imageUrl: string;
  workspaceId: string;
  communicationType: CommunicationType;
  structure: BEDemTemplateComponent;
};

export interface BEDemTemplateComponent {
  subject: SubjectComponent;
  mainTitle?: MainTitleComponent;
  sections: BESectionContainerComponent[];
}

export interface BESectionContainerComponent {
  type: ComponentType.SECTION;
  components: SectionFieldComponent[];
  description?: string;
  title?: {
    type: ComponentType.SECTION_TITLE;
    maxLength: number;
    editable: boolean;
  };
}

export type TemplateData = {
  id: string;
  name: string;
  createdAt: string;
  imageUrl: string;
  workspaceId: string;
  communicationType: CommunicationType;
  structure: DemTemplateComponent;
};
