/// <reference types="vite-plugin-svgr/client" />
import "./_shared/config";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { enableMocking } from "./_shared/mock";

enableMocking()
  .then(() => {
    ReactDOM.createRoot(document.getElementById("root")!).render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  })
  .catch((err) => {
    console.error("Failed to enable mocking:", err);
  });
