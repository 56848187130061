import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { Card } from "@xtreamsrl/react-ui-kit/Card";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { StepLayout } from "../components/StepLayout";
import { useCallback, useMemo } from "react";
import { CommunicationType, communicationsTypes } from "../types";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { ContentCreationEvents, useTracker } from "../analytics";
import { useStepper } from "../hooks/useStepper";
import { useCommunicationTypeSetter } from "../hooks/useCommunicationTypeSetter";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing("md-4"),
}));

export function SelectCommType() {
  const t = useTranslate();

  return (
    <StepLayout
      title={t("communicationTypeSelector.header")}
      rightPane={
        <Container>
          <CommTypeList
            title={t("communicationTypeSelector.mostUsed")}
            commTypes={[CommunicationType.DEM]}
          />
          <CommTypeList
            title={t("communicationTypeSelector.others")}
            commTypes={[CommunicationType.PAPER_MAIL, CommunicationType.RCS]}
          />
        </Container>
      }
    />
  );
}

const StyledCard = styled(Card)(({ theme }) => ({
  width: "35ch",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.grey[1],
  },
}));

const IconWrapper = styled("div")(({ theme }) => ({
  height: "3rem",
  width: "3rem",
  display: "grid",
  placeContent: "center",
  color: theme.palette.brand[9],
  backgroundColor: theme.palette.brand[1],
  borderRadius: theme.spacing("sm-2"),
  marginBottom: theme.spacing("sm-8"),
}));

function CommTypeCard({ commType }: { commType: CommunicationType }) {
  const t = useTranslate();
  const { next } = useStepper();
  const { track } = useTracker();
  const setCommType = useCommunicationTypeSetter();

  const handleClick = useCallback(() => {
    track(ContentCreationEvents.SelectCommunicationType);
    setCommType(commType);
    next();
  }, [next, commType, track, setCommType]);

  const details = useMemo(() => {
    return communicationsTypes.find((type) => type.code === commType);
  }, [commType]);

  if (!details || !details.isImplemented) {
    return null;
  }

  const Icon = details?.icon;
  return (
    <StyledCard onClick={handleClick}>
      <Card.Content>
        <IconWrapper>
          <Icon />
        </IconWrapper>
        <Typography variant="body/xl/semibold" color="grey.12">
          {t(details.text)}
        </Typography>
        <Typography variant="body/base/regular" color="grey.10">
          {t(details.info)}
        </Typography>
      </Card.Content>
    </StyledCard>
  );
}

function CommTypeList({
  title,
  commTypes,
}: {
  title?: string;
  commTypes: CommunicationType[];
}) {
  return (
    <Flex direction="column" gap="sm-8">
      {title && (
        <Typography variant="body/xl/semibold" color="grey.11">
          {title + ":"}
        </Typography>
      )}
      <Flex gap="md-2" flexWrap="wrap">
        {commTypes.map((commType) => (
          <CommTypeCard key={commType} commType={commType} />
        ))}
      </Flex>
    </Flex>
  );
}
