import { theme } from "@reaidy/components";
import { FormTextInputBasic } from "@xtreamsrl/react-form-input-kit/FormTextInputBasic";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { TemplatesEvents, useTracker } from "../analytics";
import { TemplateSectionFields } from "./TemplateSectionFields";

export function TemplateSection({
  index,
  remove,
  showDescription,
}: {
  index: number;
  remove: (index: number) => void;
  showDescription?: boolean;
}) {
  const t = useTranslate();
  const { track } = useTracker();
  return (
    <>
      <Box
        padding="sm-6"
        pt="sm-6"
        border={`1px solid ${theme.palette.grey[5]}`}
        style={{ borderRadius: "10px" }}
      >
        {/* Section header */}
        <Flex
          justifyContent="space-between"
          marginBottom="sm-6"
          alignItems="center"
        >
          <Flex gap="sm-4" alignItems="center">
            <Icon fontSize="large" name="TextSelect" />
            <Typography>
              {t("template.creation.section", { index: index + 1 })}
            </Typography>
          </Flex>
          <Button
            size="sm"
            color="grey"
            variant="outlined"
            onlyIcon={true}
            onClick={() => {
              track(TemplatesEvents.RemoveTemplateSection);
              remove(index);
            }}
            icon={<Icon name="Trash2" />}
          />
        </Flex>
        <Flex direction="column" gap="sm-8">
          <Box pl="sm-2" pr="sm-2">
            {showDescription && (
              <FormTextInputBasic
                size="md"
                testId={`section.${index}.description`}
                hint={t("template.creation.missingDescription")}
                name={`sections.${index}.description`}
                multiline={true}
                placeholder={t("template.creation.description")}
              />
            )}
          </Box>
          <TemplateSectionFields sectionIndex={index} />
        </Flex>
      </Box>
    </>
  );
}
