import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EventSourceParserStream } from "eventsource-parser/stream";
import { useCallback } from "react";
import { default as fetch } from "src/_shared/fetch";
import { commPersonaDraftQueryKeys } from "../queries/useCommPersonaDraftQuery";
import { PersonaDraft } from "../types";
import { commDetailsQueryKeys } from "../queries/useCommDetailsQuery";

type ChunkData = {
  contents: {
    id: string;
    content: string;
  }[];
};

type ErrorData = {
  message: string;
  code: string;
  id: string;
};

export function useChatPersonaDraftMutation() {
  const queryClient = useQueryClient();

  const handleDraftUpdated = useCallback(
    (updatedDraft: PersonaDraft, commId: string) => {
      // Update the draft in the cache
      const draftQueryKey = commPersonaDraftQueryKeys.byCommIdAndDraftId(
        commId,
        updatedDraft.id,
      );
      console.log("Updated draft", updatedDraft);
      queryClient.setQueryData<PersonaDraft>(draftQueryKey, (oldDraft) => ({
        ...updatedDraft,
        draftContent: oldDraft?.draftContent || [],
        status: "DRAFT_LOADING",
      }));
    },
    [queryClient],
  );

  const handleDraftChunk = useCallback(
    (data: ChunkData, commId: string, draftId: string) => {
      // Update the draft in the cache
      queryClient.setQueryData<PersonaDraft>(
        commPersonaDraftQueryKeys.byCommIdAndDraftId(commId, draftId),
        (draft) => {
          if (!draft) return;
          return { ...draft, draft: data.contents, status: "DRAFT_SAVED" };
        },
      );
    },
    [queryClient],
  );

  const handleError = useCallback(
    async (draftId: string, commId: string) => {
      // Invalidate all related queries
      await queryClient.invalidateQueries({
        queryKey: commPersonaDraftQueryKeys.byCommIdAndDraftId(commId, draftId),
      });
    },
    [queryClient],
  );

  const handleComplete = useCallback(
    async (draftId: string, commId: string) => {
      console.log("Chat draft complete");
      // Invalidate all related queries
      await queryClient.invalidateQueries({
        queryKey: commPersonaDraftQueryKeys.byCommIdAndDraftId(commId, draftId),
      });
    },
    [queryClient],
  );

  return useMutation({
    mutationFn: chatPersonaDraft,
    async onSuccess(stream, { commId, draftId }) {
      // Cancel related queries while we spoil the cache
      const cancelPromises = [
        queryClient.cancelQueries({
          queryKey: commPersonaDraftQueryKeys.byCommIdAndDraftId(
            commId,
            draftId,
          ),
        }),
        queryClient.cancelQueries({
          queryKey: commDetailsQueryKeys.byId(commId),
        }),
      ];
      await Promise.all(cancelPromises);

      let updatedDraft: PersonaDraft | undefined;
      try {
        for await (const chunk of readStream(stream)) {
          console.log("Chunk", chunk);
          const data: unknown = JSON.parse(chunk.data);
          if (chunk.event === "updatedDraft") {
            updatedDraft = data as PersonaDraft;
            console.log("Updated draft", updatedDraft);
            handleDraftUpdated(updatedDraft, commId);
          } else if (chunk.event === "message") {
            if (!updatedDraft) {
              throw new Error("Received message before updatedDraft");
            }
            handleDraftChunk(data as ChunkData, commId, updatedDraft.id);
          } else if (chunk.event === "error") {
            const error = data as ErrorData;
            throw new Error(`${error.code}: ${error.message}`);
          }
        }
        if (!updatedDraft) throw new Error("Stream ended without updatedDraft");
        await handleComplete(draftId, commId);
      } catch (e) {
        console.error(e);
        await handleError(draftId, commId);
      }
    },
  });
}

async function chatPersonaDraft({
  commId,
  draftId,
  newPersonaId,
  directions,
}: {
  commId: string;
  draftId: string;
  newPersonaId?: string;
  directions?: string;
}) {
  const response = await fetch.post<Response>(
    `/communications/${commId}/persona-drafts/${draftId}/chat`,
    {
      personaId: newPersonaId,
      directions: directions || undefined,
    },
    {
      headers: {
        "Accept-Encoding": "chunked",
      },
      extractJson: false,
    },
  );
  if (!response.body) throw new Error("Failed to get chat draft stream");
  return response.body;
}

async function* readStream(stream: ReadableStream<Uint8Array>) {
  const eventStream = stream
    .pipeThrough(new TextDecoderStream())
    .pipeThrough(new EventSourceParserStream())
    .getReader();

  while (true) {
    const { done, value } = await eventStream.read();
    if (done) break;
    yield value;
  }
}
