import { infiniteQueryOptions, useInfiniteQuery } from "@tanstack/react-query";
import {
  SearchCommFilters,
  searchCommunicationsAPI,
} from "../apis/searchCommunications";

export const searchCommsListQueryKeys = {
  all: ["comms", "search"] as const,
  filtered: (filters: SearchCommFilters) =>
    ["comms", "search", filters] as const,
};

export function getSearchCommsQueryOptions(filters: SearchCommFilters = {}) {
  const queryKey = searchCommsListQueryKeys.filtered(filters);
  return infiniteQueryOptions({
    queryKey,
    queryFn({ pageParam, queryKey }) {
      return searchCommunicationsAPI(pageParam, queryKey[2]);
    },
    initialPageParam: 0,
    getNextPageParam: ({ pageInfo }) => {
      if (pageInfo.nextPage) return pageInfo.nextPage;
      const { pageNumber, pageSize, totalElements } = pageInfo;
      const totalPages = Math.ceil(totalElements / pageSize);
      return pageNumber < totalPages - 1 ? pageNumber + 1 : undefined;
    },
  });
}

export function useSearchCommsQuery({
  filters,
}: {
  filters?: SearchCommFilters;
}) {
  return useInfiniteQuery(getSearchCommsQueryOptions(filters));
}
