import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { EditingLayout } from "../components/CommEditingLayout";
import { getCommDetailsQuery } from "../queries/useCommDetailsQuery";
import { Communication, PersonaDraft } from "../types";
import { CommDraftList } from "../components/CommDraftList";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { useParams } from "react-router-dom";
import { CommNameInput } from "../components/CommNameInput";
import { ErrorBoundary } from "react-error-boundary";
import { NewDraftViewer } from "../components/NewDraftViewer";
import { useCommPersonaDraftQuery } from "../queries/useCommPersonaDraftQuery";

export function CommViewing() {
  const { communicationId } = useParams<{ communicationId: string }>();
  const { data } = useQuery({
    ...getCommDetailsQuery(communicationId!),
    enabled: !!communicationId,
  });

  if (!data) return null;
  return <CommViewingComponent communication={data} />;
}

function CommViewingComponent({
  communication,
}: {
  communication: Communication;
}) {
  const commId = communication.id;
  const { data: personaDrafts = [] } = useQuery({
    ...getCommDetailsQuery(commId),
    select: (data) => data?.personaDrafts,
    initialData: communication,
  });

  const [selectedDraft, setSelectedDraft] = useState<PersonaDraft | undefined>(
    personaDrafts[0],
  );

  if (!selectedDraft) return null;
  return (
    <EditingLayout
      leftPanel={
        selectedDraft && (
          <LeftPanel
            communication={communication}
            selectedDraft={selectedDraft}
          />
        )
      }
      rightPanel={
        <RightPanel
          communication={communication}
          selectedDraft={selectedDraft}
          setSelectedDraft={setSelectedDraft}
          personaDrafts={personaDrafts}
        />
      }
    />
  );
}

function LeftPanel({
  communication,
  selectedDraft,
}: {
  communication: Communication;
  selectedDraft: PersonaDraft;
}) {
  const commId = communication.id;
  const draftId = selectedDraft.id;

  const { data: draft } = useCommPersonaDraftQuery({
    commId,
    draftId,
  });

  if (!draft) return null;
  return (
    <ErrorBoundary fallback={<p>Error</p>}>
      <NewDraftViewer communication={communication} draft={draft} />
    </ErrorBoundary>
  );
}

function RightPanel({
  communication,
  selectedDraft,
  setSelectedDraft,
  personaDrafts,
}: {
  communication: Communication;
  selectedDraft?: PersonaDraft;
  setSelectedDraft: (draft: PersonaDraft) => void;
  personaDrafts: PersonaDraft[];
}) {
  const commId = communication.id;
  return (
    <Flex direction="column" gap="sm-4" height="100%">
      <CommNameInput commId={commId} disabled />
      <CommDraftList
        commId={commId}
        selectedDraft={selectedDraft}
        personaDrafts={personaDrafts}
        setSelectedDraft={setSelectedDraft}
      />
      {/* <CommEditingNewDraft setSelectedDraft={setSelectedDraft} /> */}
    </Flex>
  );
}
