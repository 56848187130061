import { SVGProps } from "react";

export const ReaidyLogoInlineSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 167 36"
      {...props}
    >
      <path
        id="reaiy-logo-text"
        d="M62.285 15.809c0-1.523 0-3.054-.033-4.574h4.5c.077.563.142 2.398.142 3.498.981-2.33 3.143-4.004 6.547-3.934v4.271c-3.801-.056-6.547 1.722-6.547 6.573v6.426h-4.609V15.81ZM79.884 20.766c.15 2.412 1.836 4.247 4.62 4.247 2.19 0 3.29-.76 3.99-1.983h4.611c-.974 2.831-3.44 5.492-8.693 5.492-6.576 0-9.126-4.517-9.126-8.845 0-4.848 3.228-8.905 9.075-8.905 6.456 0 8.959 4.565 8.959 8.86 0 .38 0 .66-.025 1.132H79.882l.002.002Zm8.73-2.939c-.115-1.511-.92-3.725-4.264-3.725-2.983 0-4.203 1.724-4.402 3.725h8.667ZM112.966 23.522c0 2.015.096 4.173.139 4.543h-4.361c-.13-.47-.172-1.579-.197-2.077-.892 1.547-2.806 2.537-6.078 2.537-4.622 0-6.434-2.829-6.434-5.465 0-4.386 3.857-5.6 8.738-5.6h3.685v-.647c0-1.514-.651-2.812-3.455-2.812-2.574 0-3.157 1.038-3.411 2.185h-4.609c.159-2.124 1.624-5.412 7.964-5.412 6.889 0 8.022 3.301 8.022 6.104v6.644h-.003Zm-4.51-3.054c-.426-.022-1.57-.022-3.596-.022-3.052 0-4.341.757-4.341 2.376 0 1.277.988 2.328 3.177 2.328 3.384 0 4.76-1.738 4.76-4.273v-.409ZM117.755 3.575h4.611v4.11h-4.611v-4.11Zm0 7.657h4.611v16.833h-4.611V11.233ZM144.524 3.575v19.707c0 1.583 0 3.2.034 4.785h-4.535c-.076-.568-.107-1.428-.13-2.14-.897 1.339-2.152 2.598-5.749 2.598-4.706 0-8.111-3.525-8.111-8.733 0-5.634 4.37-9.018 8.573-9.018 3.473 0 4.808 1.328 5.287 2.006V3.575h4.634-.003Zm-13.75 16.116c0 2.796 1.631 5.152 4.641 5.152 3.008 0 4.606-2.055 4.606-5.21s-1.187-5.198-4.558-5.198c-3.163 0-4.689 2.553-4.689 5.256ZM152.644 11.232c3.091 7.604 4.548 11.875 4.93 13.152h.077c.491-1.675 1.923-6.249 4.475-13.152h4.718l-5.889 14.922c-2.909 7.415-3.852 9.555-9.087 9.555-.704 0-1.862-.065-2.461-.187V31.71c.735.077 1.233.095 1.729.095 2.275 0 2.904-.797 3.986-3.056l-7.538-17.516H152.644Z"
        fill="currentColor"
      />
      <g id="reaidy-logo-symbol">
        <path
          d="m35.903 35.709-5.943-.25c.212-9.364 5.813-14.674 12.233-17.605C35.773 14.924 30.172 9.611 29.96.25L35.903 0c.332 14.695 19.365 14.863 20.177 14.863v5.985c-.81 0-19.843.168-20.177 14.863v-.002Z"
          fill="#5F4DDB"
        />
        <path
          d="m20.177 35.709 5.943-.25c-.212-9.364-5.814-14.674-12.234-17.605C20.306 14.924 25.908 9.611 26.12.25L20.177 0C19.842 14.695.81 14.863 0 14.863v5.985c.81 0 19.842.168 20.177 14.863v-.002Z"
          fill="#43DB37"
        />
      </g>
    </svg>
  );
};
