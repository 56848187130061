import { RefObject, useEffect, useState } from "react";
import { MENU_WIDTH, Menu, MenuPosition } from "./SelectionMenu/Menu";
import { useMenuActions } from "./SelectionMenu/useMenuActions";
import { PreviewAction } from "./SelectionMenu/PreviewAction";
import { ChooseAction } from "./SelectionMenu/ChooseAction";

export function SelectionMenu({
  blockId,
  draftId,
  commId,
  menuRef,
  anchorEl,
  containerRef,
  onAccept,
  onReject,
}: {
  blockId: string;
  commId: string;
  draftId: string;
  menuRef: RefObject<HTMLDivElement>;
  anchorEl: HTMLElement | null;
  containerRef: RefObject<HTMLElement>;
  onAccept: (content: string) => void;
  onReject: (content: string) => void;
}) {
  const [menuPosition, setMenuPosition] = useState<MenuPosition | null>(null);

  useEffect(() => {
    const updatePosition = () => {
      if (!anchorEl || !containerRef.current) return;
      const position = calculatePosition({
        container: containerRef.current,
        anchor: anchorEl,
      });
      setMenuPosition(position);
    };
    updatePosition();
    const resizeObserver = new ResizeObserver(updatePosition);
    if (anchorEl) resizeObserver.observe(anchorEl);
    return () => {
      resizeObserver.disconnect();
    };
  }, [anchorEl, containerRef]);

  const {
    generationEnded,
    generationType,
    generationContent,
    generationError,
    handleRegenerate,
    handleModify,
    handleShorten,
    handleExpand,
    handleRephrase,
    handleReset,
    handleAcceptMod,
  } = useMenuActions({
    draftId,
    commId,
    blockId,
    onAccept,
    onReject,
  });

  if (!menuPosition) return null;
  return (
    <Menu ref={menuRef} position={menuPosition}>
      {generationType ? (
        <PreviewAction
          isGenerating={generationEnded === false}
          generationType={generationType}
          onReset={handleReset}
          onAccept={handleAcceptMod}
          onRegenerate={handleRegenerate}
          content={generationContent}
          error={generationError}
        />
      ) : (
        <ChooseAction
          onModify={handleModify}
          onShorten={handleShorten}
          onExpand={handleExpand}
          onRephrase={handleRephrase}
        />
      )}
    </Menu>
  );
}

function calculatePosition({
  container,
  anchor,
}: {
  container: HTMLElement;
  anchor: HTMLElement;
}): MenuPosition {
  const space = 0;
  const anchorRect = anchor.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  // Check if the selection was made near the end of the viewport
  // If so, the container should scroll down to make room for the menu
  if (window.innerHeight - anchorRect.bottom < 300) {
    const top = container.scrollTop + 400;
    container.scrollTo({
      top,
      behavior: "smooth",
    });
  }

  const x =
    anchorRect.left -
    containerRect.left +
    container.scrollLeft +
    anchorRect.width / 2 -
    MENU_WIDTH / 2;

  const y =
    anchorRect.top -
    containerRect.top +
    container.scrollTop +
    anchorRect.height +
    space;

  // check that the menu fits horizontally
  const rightEdge = x + MENU_WIDTH / 2;
  if (rightEdge >= containerRect.width + containerRect.left) {
    return { x: containerRect.right - MENU_WIDTH, y };
  }
  const leftEdge = x - MENU_WIDTH / 2;
  if (leftEdge <= containerRect.left) {
    return {
      x: anchorRect.left - containerRect.left + container.scrollLeft,
      y,
    };
  }

  return { x, y };
}
