import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createCommunicationAPI } from "../apis/createCommunication";
import { searchCommsListQueryKeys } from "src/archive/queries/useSearchCommsQuery";
import { useGeneratePersonaDraftMutation } from "./useGeneratePersonaDraftMutation";

export function useCreateCommunicationMutation({
  onError,
  onStreamError,
  onStreamChunk,
  onStreamEnd,
}: {
  onError?: (error: unknown) => void;
  onStreamError?: (error: unknown) => void;
  onStreamChunk?: (chunk: string) => void;
  onStreamEnd?: () => void;
}) {
  const queryClient = useQueryClient();
  const { mutate: generatePersonaDraft } = useGeneratePersonaDraftMutation({
    onStreamError,
    onStreamChunk,
    onStreamEnd,
  });

  return useMutation({
    mutationFn: createCommunicationAPI,
    async onMutate() {
      await queryClient.cancelQueries({
        queryKey: searchCommsListQueryKeys.all,
      });
    },
    async onSuccess(data) {
      await queryClient.invalidateQueries({
        queryKey: searchCommsListQueryKeys.all,
      });
      generatePersonaDraft({
        commId: data.id,
        draftId: data.personaDrafts[0].id,
      });
    },
    onError,
  });
}
