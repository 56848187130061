import { useCallback, useState } from "react";
import { Communication, PersonaDraft } from "../types";
import { useRecreateCommMutation } from "../mutations/useRecreateCommMutation";
import {
  DemTemplateComponent,
  GenericTemplateComponent,
} from "src/templates/types";
import { SidePanel } from "@xtreamsrl/react-ui-kit/SidePanel";
import { LayoutCreator } from "src/templates/views/LayoutCreator";

export function EditLayoutModal({
  modalIsOpen,
  closeModal,
  communication,
  setSelectedDraft,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
  communication: Communication;
  setSelectedDraft: (draft: PersonaDraft) => void;
}) {
  const initialValues = communication.details.layout.layoutStructure;
  const [newCommunication, setNewCommunication] = useState<Communication>();
  const [isGenerating, setIsGenerating] = useState(false);

  const handleStreamEnd = useCallback(() => {
    const newDraft = newCommunication?.personaDrafts[0];
    if (newDraft) setSelectedDraft(newDraft);
    setIsGenerating(false);
    closeModal();
  }, [newCommunication, closeModal, setSelectedDraft]);

  const handleNewCommunication = useCallback(
    (comm: Communication) => {
      setNewCommunication(comm);
      setIsGenerating(true);
    },
    [setNewCommunication, setIsGenerating],
  );

  const handleStreamError = useCallback(() => {
    setIsGenerating(false);
    closeModal();
  }, [closeModal]);

  const { mutate, isPending } = useRecreateCommMutation({
    onNewCommunication: handleNewCommunication,
    onStreamError: handleStreamError,
    onStreamEnd: handleStreamEnd,
  });

  const handleSubmit = useCallback(
    (template: GenericTemplateComponent) => {
      mutate({
        commId: communication.id,
        input: {
          ...communication,
          details: {
            ...communication.details,
            source: "MANUAL",
            layoutStructure: template as DemTemplateComponent,
          },
        },
      });
    },
    [communication, mutate],
  );

  const isLoading = isPending || isGenerating;
  const handleClose = useCallback(() => {
    if (!isLoading) closeModal();
  }, [closeModal, isLoading]);

  return (
    <SidePanel open={modalIsOpen} onClose={handleClose} anchor="right">
      <SidePanel.Content height="100%" width="80vw">
        <LayoutCreator
          onSubmit={handleSubmit}
          closeCreator={closeModal}
          initialValues={initialValues as DemTemplateComponent}
          isLoading={isLoading}
        />
      </SidePanel.Content>
    </SidePanel>
  );
}
