import { useTranslate } from "@xtreamsrl/react-i18n";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Divider } from "@xtreamsrl/react-ui-kit/Divider";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { SidePanel } from "@xtreamsrl/react-ui-kit/SidePanel";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { FormEventHandler, useCallback } from "react";
import { Template, isManualTemplate } from "src/templates/types";
import { HTMLRenderer } from "../../contentCreation/components/HTMLRenderer";
import { HTMLPreviewBox } from "src/_shared/components/HTMLPreviewBox";

export function ImportedLayoutModal({
  template,
  closeModal,
  modalIsOpen,
}: {
  template: Template;
  closeModal: () => void;
  modalIsOpen: boolean;
}) {
  const t = useTranslate();
  if (isManualTemplate(template)) throw new Error("Template is manual");

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal /* setBriefData, briefData, communicationType */]);

  const handleSubmit = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault();
      closeModal();
    },
    [closeModal],
  );

  return (
    <SidePanel open={modalIsOpen} onClose={handleClose} anchor="right">
      <SidePanel.Content height="100%" width="80vw">
        <form
          onSubmit={handleSubmit}
          style={{
            height: "100%",
          }}
        >
          <Flex height="100%" direction="column" gap="md-1">
            <Typography variant="header/lg/bold">{template.name}</Typography>
            <Divider />
            <HTMLPreviewBox>
              <HTMLRenderer>{template.layoutHtml}</HTMLRenderer>
            </HTMLPreviewBox>
            <Divider />
            <Flex justifyContent="flex-end" gap="sm-4">
              <Button
                testId="create-template"
                type="submit"
                variant="filled"
                loading={false}
              >
                {t("template.preview.close")}
              </Button>
            </Flex>
          </Flex>
        </form>
      </SidePanel.Content>
    </SidePanel>
  );
}
