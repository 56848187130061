import { FeaturesMap } from "@xtreamsrl/react-feature-flags";

export enum FeatureFlag {
  ShowCommunicationAim = "communication-aim",
  ShowMockBriefButton = "mock-brief-button",
  ShowCommunicationTypeDem = "communication-type-dem",
  ShowCommunicationTypePaperMail = "communication-type-paper-mail",
  ShowCommunicationTypeRcs = "communication-type-rcs",
  ShowCommunicationTypeLandingPage = "communication-type-landing-page",
  ShowCommunicationTypePushNotification = "communication-type-push-notification",
  ShowCommunicationTypeScript = "communication-type-script",
}

export const featuresMap: FeaturesMap = {
  ShowCommunicationAim: { flag: FeatureFlag.ShowCommunicationAim },
  ShowMockBriefButton: { flag: FeatureFlag.ShowMockBriefButton },
  ShowCommunicationTypeDem: { flag: FeatureFlag.ShowCommunicationTypeDem },
  ShowCommunicationTypePaperMail: {
    flag: FeatureFlag.ShowCommunicationTypePaperMail,
  },
  ShowCommunicationTypeRcs: { flag: FeatureFlag.ShowCommunicationTypeRcs },
  ShowCommunicationTypeLandingPage: {
    flag: FeatureFlag.ShowCommunicationTypeLandingPage,
  },
  ShowCommunicationTypePushNotification: {
    flag: FeatureFlag.ShowCommunicationTypePushNotification,
  },
  ShowCommunicationTypeScript: {
    flag: FeatureFlag.ShowCommunicationTypeScript,
  },
};
