import { useStepper } from "../hooks/useStepper";
import {
  DemTemplateComponent,
  GenericTemplateComponent,
  Template,
} from "src/templates/types";
import { useCallback, useMemo } from "react";
import { LayoutCreator } from "src/templates/views/LayoutCreator";
import { useCommunicationType } from "../hooks/useCommunicationType";
import { SidePanel } from "@xtreamsrl/react-ui-kit/SidePanel";
import { useBriefData } from "../hooks/useBriefData";
import { CommunicationType } from "../types";
import { basicInitialValues } from "src/templates/utils";
import { Intent } from "../views/SelectLayout";

export function ManualLayoutModal({
  templates,
  templateId,
  closeModal,
  modalIsOpen,
  intent,
}: {
  templates: Template[];
  templateId: string | null;
  closeModal: () => void;
  modalIsOpen: boolean;
  intent: Intent | null;
}) {
  const { next } = useStepper();
  const communicationType = useCommunicationType();
  if (!communicationType) throw new Error("Communication type not set");

  const [briefData, setBriefData] = useBriefData();

  const initialValues = useMemo(() => {
    if (intent === "START_FROM_TEMPLATE" && templateId) {
      const template = templates.find((template) => template.id === templateId);
      return template?.structure;
    }
    if (intent === "START_FROM_SCRATCH") {
      return basicInitialValues[communicationType];
    }
    // When coming back from the next step
    return briefData?.layoutStructure;
  }, [templateId, templates, briefData, intent, communicationType]);

  const handleSubmit = useCallback(
    (template: GenericTemplateComponent) => {
      setBriefData({
        source: "MANUAL",
        type: briefData?.type ?? (communicationType as CommunicationType.DEM),
        layoutStructure: template as DemTemplateComponent,
        brief: briefData?.brief ?? [],
      });
      next();
      // do not call closeModal because that resets the brief data
    },
    [next, setBriefData, briefData, communicationType],
  );

  const handleClose = useCallback(() => {
    closeModal();
    setBriefData({
      type: briefData?.type ?? (communicationType as CommunicationType.DEM),
      source: "MANUAL",
      layoutStructure: basicInitialValues[
        communicationType
      ] as DemTemplateComponent,
      brief: briefData?.brief ?? [],
    });
  }, [closeModal, setBriefData, briefData, communicationType]);

  return (
    <SidePanel open={modalIsOpen} onClose={handleClose} anchor="right">
      <SidePanel.Content height="100%" width="80vw">
        <LayoutCreator
          closeCreator={handleClose}
          communicationType={communicationType}
          onSubmit={handleSubmit}
          initialValues={initialValues as GenericTemplateComponent}
        />
      </SidePanel.Content>
    </SidePanel>
  );
}
