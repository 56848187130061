import { useContentCreationSlice } from "../slice/contentCreationSlice.ts";
import { BriefData } from "../types";

export function useBriefData(): [
  briefData: BriefData | undefined,
  setBriefData: (briefData: BriefData) => void,
] {
  const [briefData, setBriefData] = useContentCreationSlice((state) => [
    state.briefData,
    state.setBriefData,
  ]);

  return [briefData, setBriefData] as const;
}
