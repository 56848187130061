export enum TemplatesEvents {
  AddTemplateTitle = "AddTitle",
  RemoveTemplateTitle = "RemoveTitle",
  AddTemplateSection = "AddSection",
  RemoveTemplateSection = "RemoveSection",
  StartTemplateCreate = "StartCreateTemplate",
  StartTemplateEdit = "StartEditTemplate",
  StartTemplateDuplicate = "StartDuplicateTemplate",
  OpenTemplatePreview = "OpenTemplatePreview",
  FilterTemplates = "FilterTemplates",
  StartTemplateDelete = "StartTemplateDelete",
}
