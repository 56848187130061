import fetch from "../../_shared/fetch";
import { Communication } from "../types";

export async function getCommunicationAPI(
  communicationId: string,
): Promise<Communication> {
  const communications: Promise<Communication> = fetch.get(
    `/communications/${communicationId}`,
  );
  return communications;
}
