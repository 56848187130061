import { useMutation, useQueryClient } from "@tanstack/react-query";
import { recreateCommunication } from "../apis/recreateCommunication";
import { commDetailsQueryKeys } from "../queries/useCommDetailsQuery";
import { useGeneratePersonaDraftMutation } from "./useGeneratePersonaDraftMutation";
import { PersonaDraft, type Communication } from "../types";
import { commPersonaDraftQueryKeys } from "../queries/useCommPersonaDraftQuery";

export function useRecreateCommMutation({
  onNewCommunication,
  onError,
  onStreamError,
  onStreamStart,
  onStreamChunk,
  onStreamEnd,
}: {
  onNewCommunication?: (communication: Communication) => void;
  onSuccess?: (communication: Communication) => void;
  onError?: (error: unknown) => void;
  onStreamError?: (error: unknown) => void;
  onStreamStart?: () => void;
  onStreamChunk?: (chunk: string) => void;
  onStreamEnd?: () => void;
}) {
  const queryClient = useQueryClient();

  const { mutate: generatePersonaDraft } = useGeneratePersonaDraftMutation({
    onStreamError,
    onStreamStart,
    onStreamChunk,
    onStreamEnd,
  });

  return useMutation({
    mutationFn: recreateCommunication,
    async onMutate({ commId }) {
      // Cancel related queries
      await queryClient.cancelQueries({
        queryKey: commDetailsQueryKeys.byId(commId),
      });
      await queryClient.cancelQueries({
        queryKey: commPersonaDraftQueryKeys.byCommId(commId),
      });
    },
    onSuccess(communication) {
      onNewCommunication?.(communication);
      const { id, personaDrafts } = communication;

      // Update communication right away
      queryClient.setQueryData<Communication>(
        commDetailsQueryKeys.byId(id),
        communication,
      );
      // Create draft, otherwise, generate draft has nowhere to put the data
      queryClient.setQueryData<PersonaDraft>(
        commPersonaDraftQueryKeys.byCommIdAndDraftId(id, personaDrafts[0].id),
        personaDrafts[0],
      );

      // Generate persona draft
      generatePersonaDraft({
        commId: id,
        draftId: personaDrafts[0].id,
      });
    },
    onError,
  });
}
