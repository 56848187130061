import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createPersonaDraft as createPersonaDraft } from "../apis/createPersonaDraft";
import { commDetailsQueryKeys } from "../queries/useCommDetailsQuery";
import { useGeneratePersonaDraftMutation } from "./useGeneratePersonaDraftMutation";

export function useCreatePersonaDraftMutation({
  onStreamError,
  onStreamChunk,
}: {
  onStreamError?: (error: unknown) => void;
  onStreamChunk?: (chunk: string) => void;
}) {
  const queryClient = useQueryClient();
  const { mutate: generatePersonaDraft } = useGeneratePersonaDraftMutation({
    onStreamError,
    onStreamChunk,
  });

  return useMutation({
    mutationFn: createPersonaDraft,
    async onMutate({ commId }) {
      await queryClient.cancelQueries({
        queryKey: commDetailsQueryKeys.byId(commId),
      });
    },
    async onSuccess({ id: draftId }, { commId }) {
      await queryClient.invalidateQueries({
        queryKey: commDetailsQueryKeys.byId(commId),
      });
      // Initiate content generation
      generatePersonaDraft({
        commId,
        draftId,
      });
    },
  });
}
