// This will be used to generate the cards to allow the user to select the type of communication they want to create
import { Features } from "@xtreamsrl/react-feature-flags";
import { ElementType, ReactNode } from "react";
import { Descendant } from "slate";
import {
  MailAtSign01Icon,
  MailOpenIcon,
  MessageNotification01Icon,
  // BrowserIcon,
  // NotificationSquareIcon,
} from "hugeicons-react";
import { DemTemplateComponent } from "src/templates/types";

type FlowDependencies = {
  communicationType?: CommunicationType;
};

export type AvailableStep = {
  id: StepKey;
  label: string;
  element: ReactNode;
  isInFlow: boolean | ((data?: FlowDependencies) => boolean);
  findNextStep?: (data: FlowDependencies) => StepKey;
  findPreviousStep?: (data: FlowDependencies) => StepKey;
};

export enum CommunicationType {
  DEM = "DEM",
  PAPER_MAIL = "PAPER_MAIL",
  RCS = "RCS",
  // LANDING_PAGE = "LANDING_PAGE",
  // PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
  // SCRIPT = "SCRIPT",
}

export const communicationsTypes: readonly {
  code: CommunicationType;
  icon: ElementType;
  text: string;
  info: string;
  isImplemented: boolean;
  feature: keyof Features;
}[] = [
  {
    code: CommunicationType.DEM,
    icon: MailAtSign01Icon,
    isImplemented: true,
    text: "shared.communicationTypes.DEM.title",
    info: "shared.communicationTypes.DEM.info",
    feature: "ShowCommunicationTypeDem",
  },
  {
    code: CommunicationType.PAPER_MAIL,
    icon: MailOpenIcon,
    text: "shared.communicationTypes.PAPER_MAIL.title",
    info: "shared.communicationTypes.PAPER_MAIL.info",
    isImplemented: true,
    feature: "ShowCommunicationTypePaperMail",
  },
  {
    code: CommunicationType.RCS,
    icon: MessageNotification01Icon,
    text: "shared.communicationTypes.RCS.title",
    info: "shared.communicationTypes.RCS.info",
    isImplemented: true,
    feature: "ShowCommunicationTypeRcs",
  },
  // {
  //   code: CommunicationType.LANDING_PAGE,
  //   icon: BrowserIcon,
  //   text: "shared.communicationTypes.LANDING_PAGE.title",
  //   info: "shared.communicationTypes.LANDING_PAGE.info",
  //   isImplemented: false,
  //   feature: "ShowCommunicationTypeLandingPage",
  // },
  // {
  //   code: CommunicationType.PUSH_NOTIFICATION,
  //   icon: NotificationSquareIcon,
  //   text: "shared.communicationTypes.PUSH_NOTIFICATION.title",
  //   info: "shared.communicationTypes.PUSH_NOTIFICATION.info",
  //   isImplemented: false,
  //   feature: "ShowCommunicationTypePushNotification",
  // },
] as const;

export type ImportedLayoutStructure = {
  id: string;
  example: string;
  style: string;
}[];

export type CommunicationLayout = {
  layoutHtml: string;
  layoutStructure: ImportedLayoutStructure | DemTemplateComponent;
};

export type CommunicationDetails = {
  type: CommunicationType.DEM;
  brief: { question: string; answer: string }[];
  layout: CommunicationLayout;
  layoutSource: "IMPORTED" | "MANUAL";
};

export type BriefDemForm = {
  type: CommunicationType.DEM;
  brief: { question: string; answer: string }[];
} & (
  | {
      source: "IMPORTED";
      layoutStructure: ImportedLayoutStructure;
      layoutHtml: string;
    }
  | {
      source: "MANUAL";
      layoutStructure: DemTemplateComponent;
    }
);

export type StepKey =
  | "communicationTypeSelection"
  | "aimSelection"
  | "templateSelection"
  | "briefForm"
  | "proposalSelection"
  | "personasSelection"
  | "PersonalisedCommunicationsEditing"
  | "communicationEditing";

export type EditorSlice = {
  value?: Descendant[];
  setValue: (value: Descendant[]) => void;
};

export type PersonalisedCommunication = {
  id: string;
  personaId: string;
  name: string;
  draft: string;
};

export type DraftContent = {
  id: string;
  content: string;
}[];

export type PersonaDraft = {
  id: string;
  status: string;
  name: string;
  personaId: string;
  personaName: string;
  _optimistic?: boolean;
  draftContent?: DraftContent;
};

export type Communication = {
  id: string;
  name: string;
  details: CommunicationDetails;
  proposal1: string;
  proposal2: string;
  proposal3: string;
  workspaceId: string;
  status: string;
  selectedProposal: ProposalSelection | null;
  createdAt: string;
  personalisedCommunications: PersonalisedCommunication[];
  personaDrafts: PersonaDraft[];
  _optimistic?: boolean;
};

export type BECommunication = {
  id: string;
  name: string;
  details: CommunicationDetails;
  proposal1: string;
  proposal2: string;
  proposal3: string;
  workspaceId: string;
  status: string;
  selectedProposal: ProposalSelection | null;
  draft: string | null;
  createdAt: string;
  personaDrafts: PersonalisedCommunication[];
};

export type BriefData = BriefDemForm;

export enum ProposalSelection {
  PROPOSAL_1 = "proposal1",
  PROPOSAL_2 = "proposal2",
  PROPOSAL_3 = "proposal3",
}

// Personas -> TODO: update if necessary
export type Persona = {
  id: string;
  name: string;
  description: string;
  default?: boolean;
};

export type CommunicationBeingSaved = {
  id: string;
  name: string;
  content: Descendant[];
};
