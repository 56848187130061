import { AvailableStep, StepKey } from "../types";
// import { AimSelector } from "../views/AimSelector";
import { CommEditing } from "../views/CommEditing.tsx";
import { WriteBrief } from "../views/WriteBrief.tsx";
import { SelectLayout } from "../views/SelectLayout.tsx";
import { SelectCommType } from "../views/SelectCommType.tsx";

export const allSteps: AvailableStep[] = [
  // {
  //   id: "aimSelection",
  //   label: "stepper.aimSelection",
  //   element: <AimSelector />,
  //   isInFlow: false,
  // },
  {
    id: "communicationTypeSelection",
    label: "stepper.contentType",
    element: <SelectCommType />,
    isInFlow: true,
  },
  {
    id: "templateSelection",
    label: "stepper.layout",
    element: <SelectLayout />,
    isInFlow: true,
  },
  {
    id: "briefForm",
    label: "stepper.brief",
    element: <WriteBrief />,
    isInFlow: true,
  },
  {
    id: "communicationEditing",
    label: "stepper.editing",
    element: <CommEditing />,
    isInFlow: true,
  },
];

export const stepByKey = allSteps.reduce(
  (acc, step) => {
    acc[step.id] = step;
    return acc;
  },
  {} as Record<StepKey, AvailableStep>,
);

export const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  return (event.returnValue = "");
};

export function addListenerForUnsavedChanges() {
  window.addEventListener("beforeunload", handleBeforeUnload, {
    capture: true,
  });
}

export function removeListenerForUnsavedChanges() {
  window.removeEventListener("beforeunload", handleBeforeUnload, {
    capture: true,
  });
}

export const mockedBrief = {
  name: "C-ReaDY-TO",
  description: `Obiettivo della DEM è convincere il Cliente ad attivare il prodotto C-REaDY-TO cliccando sul bottone a fondo pagina premendo il quale si avvia il processo di attivazione del servizio che può essere sottoscritto dall’Area Clienti o direttamente dall’APP BNL
  Si tratta di un prodotto che consente ai Clienti di rateizzare le spese sostenute sul conto corrente, dalle bollette di luce e gas all’abbonamento della palestra.
  E’ sufficiente selezionare le spese che si intende rateizzare e l’importo delle spese già sostenute verrà riaccreditato in conto generando nuova disponibilità sul conto corrente stesso.
  Si possono rateizzare le spese sostenute per importi da un minimo di 75 euro a un massimo di 5.000 euro nei limiti previsti dal plafond del Cliente
  Si può usare comodamente quando il Cliente lo desidera direttamente dallo smartphone tramite l’app BNL o dal pc attraverso l’Area Clienti.
  Per attivare il piano bastano pochi secondi basta scegliere la durata del piano che si preferisci da 3 a 60 mesi.
  E’ vantaggioso perché non ha costo di attivazione e nessun canone di gestione si paga solo una commissione mensile sul piano che si scegli di attivare e limitatamente alla sua durata.`,
  plusOrIncentives: `Grazie a questo servizio il Cliente può ricevere il riaccredito, sul proprio conto corrente, di spese già sostenute ed ha tempo fino a due mesi dopo aver sostenuto la spesa per decidere la rateizzazione
  Per attivare il servizio bastano pochi secondi e si può subito selezionare le spese che si intende rateizzare scegliendo il piano che si preferisce da 3 a 60 mesi
  Non ci sono costi di attivazione né canone di gestione e si paga solo una commissione mensile sul piano che si scegli di attivare, limitatamente alla sua durata.`,
};

export class NdjsonDecoderStream<TData = unknown> extends TransformStream<
  string,
  TData
> {
  constructor() {
    super({
      transform(chunk, controller) {
        const lines = chunk.split("\n").filter(Boolean);
        for (const line of lines) {
          if (line) {
            const data: unknown = JSON.parse(line);
            controller.enqueue(data as TData);
          }
        }
      },
    });
  }
}
