import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { ReactNode } from "react";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing("md-4"),
  height: "100%",
  paddingBlock: theme.spacing("md-6"),
  paddingInline: theme.spacing("lg-1"),
  maxWidth: "100rem",
  width: "100%",
}));

const SplitLayout = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing("md-4"),
}));

const SplitPane = styled("div")({
  display: "flex",
  flexDirection: "column",
  "&:first-child": {
    flex: "0 0 50ch",
  },
  "&:last-child": {
    flex: 1,
    minWidth: "50ch",
  },
});

const StyledButtonGroup = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing("sm-5"),
  justifyContent: "flex-end",
  paddingBottom: theme.spacing("md-4"),
  "& > button:first-child": {
    border: "solid 1px",
    borderColor: theme.palette.grey[7],
    color: theme.palette.grey[11],
  },
}));

export function StepLayout({
  title,
  buttons,
  leftPane,
  rightPane,
}: {
  title: string;
  buttons?: ReactNode;
  leftPane?: ReactNode;
  rightPane?: ReactNode;
}) {
  return (
    <Container>
      <Typography variant="header/sm/bold">{title}</Typography>
      <SplitLayout>
        {leftPane && <SplitPane>{leftPane}</SplitPane>}
        {rightPane && <SplitPane>{rightPane}</SplitPane>}
      </SplitLayout>
      {buttons && <StyledButtonGroup>{buttons}</StyledButtonGroup>}
    </Container>
  );
}
