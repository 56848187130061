import { Communication, DraftContent, PersonaDraft } from "../types";
import {
  HTMLEditor,
  HTMLEditorAPI,
  HTMLEditorBlurEventHandler,
  HTMLEditorChangeEventHandler,
  HTMLEditorContentInfo,
  HTMLEditorFocusEventHandler,
} from "./HTMLEditor";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { EditorContainer } from "./EditorContainer";
import { EditorWrapper } from "./EditorWrapper";
import { FloatingPrompt } from "./FloatingPrompt";
import { SelectionMenu } from "./SelectionMenu";
import { useUpdatePersonaDraftMutation } from "../mutations/useUpdatePersonaCommMutation";
import { useChatPersonaDraftMutation } from "../mutations/useChatPersonaDraftMutation";

export function NewDraftEditor({
  draft,
  communication,
  isLoading,
  isSaving,
}: {
  draft: PersonaDraft;
  communication: Communication;
  isLoading?: boolean;
  isSaving?: boolean;
  isSaved?: boolean;
}) {
  const commId = communication.id;
  const draftId = draft.id;

  const html = communication.details?.layout?.layoutHtml;
  const content = draft?.draftContent as DraftContent;

  // Safety checks that we will remove later
  if (!html) throw new Error("HTML layout is missing");
  if (!content) throw new Error("Draft content is missing");

  const editorRef = useRef<HTMLEditorAPI>(null);
  const initialHTML = useMemo(() => {
    return replaceContent(html, content);
  }, [html, content]);

  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [menuBlockId, setMenuBlockId] = useState<string | null>(null);

  const closeMenu = useCallback(() => setMenuAnchor(null), []);

  const { mutate: updateDraft } = useUpdatePersonaDraftMutation();

  const onEdit = useCallback(
    (info: HTMLEditorContentInfo) => {
      const api = editorRef.current!;
      api.suspendContent(info.id);
      const onSettled = () => {
        api.unsuspendContent(info.id);
      };
      updateDraft(
        {
          commId,
          draftId,
          payload: {
            block: {
              id: info.id,
              content: info.content,
            },
          },
        },
        {
          onSettled,
        },
      );
    },
    [commId, draftId, updateDraft],
  );

  const handleChange = useCallback<HTMLEditorChangeEventHandler>((nodeId) => {
    const api = editorRef.current!;
    const info = api.getContentInfo(nodeId);
    console.log("Node change", info);
  }, []);

  const handleFocus = useCallback<HTMLEditorFocusEventHandler>((nodeId) => {
    const api = editorRef.current!;
    const node = api.getContentNode(nodeId);
    const info = api.getContentInfo(nodeId);
    if (!info) return;
    setMenuAnchor(node);
    setMenuBlockId(info.id);
  }, []);

  const handleBlur = useCallback<HTMLEditorBlurEventHandler>(
    (nodeId) => {
      const api = editorRef.current!;
      const info = api.getContentInfo(nodeId);
      if (!info) return;
      console.log("Node blur", info);

      // Don't do anything if the content hasn't changed
      if (info.content.trim() === info.original?.trim()) return;

      // Reset the content if it contains empty text
      if (info.textContent.trim() === "" && info.original) {
        api.updateContent(nodeId, info.original);
        return;
      }

      console.log("Content changed", info);
      onEdit?.(info);
    },
    [onEdit],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleClick = (event: MouseEvent) => {
      if (!menuAnchor) return;
      const menu = menuRef.current;
      const path = event.composedPath();
      if (path.includes(menuAnchor)) return;
      if (menu && path.includes(menu)) return;
      setMenuAnchor(null);
      setMenuBlockId(null);
    };

    container.addEventListener("click", handleClick);
    return () => {
      container.removeEventListener("click", handleClick);
    };
  }, [menuAnchor]);

  const { mutate: chat, isPending: chatPending } =
    useChatPersonaDraftMutation();

  const handleChatPrompt = useCallback(
    (prompt: string) => {
      chat({ commId, draftId, directions: prompt });
    },
    [commId, draftId, chat],
  );

  useEffect(() => {
    const api = editorRef.current!;
    if (isLoading || isSaving) {
      api.suspendAll();
    } else {
      api.unsuspendAll();
    }
  }, [isLoading, isSaving]);

  useEffect(() => {
    const api = editorRef.current!;
    const nodeId = menuAnchor?.dataset?.contentId;
    if (nodeId) {
      api.focusContent(nodeId);
      return () => {
        api.blurContent(nodeId);
      };
    }
  }, [menuAnchor]);

  const handleAcceptMod = useCallback(
    (content: string) => {
      const api = editorRef.current!;
      const nodeId = menuBlockId;
      if (!nodeId) return;
      api.updateContent(nodeId, content);
      closeMenu();
      const info = api.getContentInfo(nodeId);
      if (!info) return;
      onEdit(info);
    },
    [menuBlockId, closeMenu, onEdit],
  );

  return (
    <EditorWrapper>
      <FloatingPrompt onChat={handleChatPrompt} isLoading={chatPending} />
      <EditorContainer ref={containerRef}>
        <HTMLEditor
          ref={editorRef}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          {initialHTML}
        </HTMLEditor>
        {menuAnchor && menuBlockId && (
          <SelectionMenu
            blockId={menuBlockId}
            commId={commId}
            draftId={draftId}
            menuRef={menuRef}
            containerRef={containerRef}
            anchorEl={menuAnchor}
            onReject={closeMenu}
            onAccept={handleAcceptMod}
          />
        )}
      </EditorContainer>
    </EditorWrapper>
  );
}

function replaceContent(html: string, contents: DraftContent) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  for (const item of contents) {
    const node = doc.querySelector(`[data-content-id="${item.id}"]`);
    if (!node) {
      console.error(`Node with ID ${item.id} not found`);
      continue;
    }
    node.innerHTML = item.content;
  }

  return doc.body.innerHTML;
}
