import { AnalyticsEvent } from "@xtreamsrl/react-analytics";
import { ContentCreationEvents } from "./events";

interface CommunicationRelatedEventProps {
  communicationId: string;
}

interface SelectionRelatedEventProps extends CommunicationRelatedEventProps {
  selection: string;
}

interface EditingRelatedEventProps extends CommunicationRelatedEventProps {
  activeStep: string;
}

type GoBackEventProps = {
  activeStep: string;
};

export const contentCreationEventsMapping = {
  [ContentCreationEvents.GoBack]: (props: GoBackEventProps) => ({
    type: "goBack",
    props,
  }),
  [ContentCreationEvents.SelectCommunicationType]: () => ({
    type: "selectCommunicationType",
  }),
  [ContentCreationEvents.SelectTemplate]: () => ({
    type: "selectTemplate",
  }),
  [ContentCreationEvents.OpenTemplateCreatorPanelInFlow]: () => ({
    type: "openTemplateCreatorPanelInFlow",
  }),
  [ContentCreationEvents.OpenBriefEditorPanel]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: "openBriefEditorPanel",
    props,
  }),
  [ContentCreationEvents.CreateDraft]: (props: SelectionRelatedEventProps) => ({
    type: "createDraft",
    props,
  }),
  [ContentCreationEvents.OpenOverwriteDraftDialog]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: "openOverwriteDraftDialog",
    props,
  }),
  [ContentCreationEvents.CancelOverwriteDraftDialog]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: "cancelOverwriteDraftDialog",
    props,
  }),
  [ContentCreationEvents.OverwriteDraft]: (
    props: SelectionRelatedEventProps,
  ) => ({
    type: "overwriteDraft",
    props,
  }),
  [ContentCreationEvents.SelectCommunicationEditorContent]: (
    props: SelectionRelatedEventProps,
  ) => ({
    type: "selectCommunicationEditorContent",
    props,
  }),
  [ContentCreationEvents.ShareDraft]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: "shareDraft",
    props,
  }),
  [ContentCreationEvents.StartChangeCommunicationName]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: "startChangeCommunicationName",
    props,
  }),
  [ContentCreationEvents.CancelChangeCommunicationName]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: "cancelChangeCommunicationName",
    props,
  }),
  [ContentCreationEvents.UndoEdit]: (props: EditingRelatedEventProps) => ({
    type: "undoEdit",
    props,
  }),
  [ContentCreationEvents.RedoEdit]: (props: EditingRelatedEventProps) => ({
    type: "redoEdit",
    props,
  }),
  [ContentCreationEvents.CopySelection]: (props: EditingRelatedEventProps) => ({
    type: "copySelection",
    props,
  }),
  [ContentCreationEvents.CutSelection]: (props: EditingRelatedEventProps) => ({
    type: "cutSelection",
    props,
  }),
  [ContentCreationEvents.PasteSelection]: (
    props: EditingRelatedEventProps,
  ) => ({
    type: "pasteSelection",
    props,
  }),
  [ContentCreationEvents.DeleteSelection]: (
    props: EditingRelatedEventProps,
  ) => ({
    type: "deleteSelection",
    props,
  }),
  [ContentCreationEvents.AcceptAIChanges]: (
    props: EditingRelatedEventProps,
  ) => ({
    type: "acceptAIChanges",
    props,
  }),
  [ContentCreationEvents.RejectAIChanges]: (
    props: EditingRelatedEventProps,
  ) => ({
    type: "rejectAIChanges",
    props,
  }),
  [ContentCreationEvents.GoToPersonasSelection]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: "goToPersonasSelection",
    props,
  }),
  [ContentCreationEvents.SelectPersonalisedCommunicationEditorContent]: (
    props: SelectionRelatedEventProps,
  ) => ({
    type: "selectPersonalisedCommunicationEditorContent",
    props,
  }),
  [ContentCreationEvents.SharePersonalisedCommunications]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: "sharePersonalisedCommunications",
    props,
  }),
  [ContentCreationEvents.CloseCreationView]: () => ({
    type: "closeCreationView",
  }),
  // eslint-disable-next-line
} satisfies Record<ContentCreationEvents, (...props: any[]) => AnalyticsEvent>;
