import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EventSourceParserStream } from "eventsource-parser/stream";
import fetch from "src/_shared/fetch";
import { commPersonaDraftQueryKeys } from "../queries/useCommPersonaDraftQuery";
import { DraftContent, PersonaDraft } from "../types";

async function changeDraftPersona({
  commId,
  draftId,
  personaId,
}: {
  commId: string;
  draftId: string;
  personaId: string;
}) {
  const response = await fetch.post<Response>(
    `/communications/${commId}/persona-drafts/${draftId}/change-persona`,
    { personaId },
    { extractJson: false },
  );
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  const stream = response.body;
  if (!stream) {
    throw new Error("No content stream");
  }
  return stream;
}

export type ChunkData = {
  contents: DraftContent;
};

export function useChangeDraftPersonaMutation({
  onUpdatedDraft,
  onStreamChunk,
  onStreamEnd,
  onStreamError,
  onStreamStart,
  onStreamComplete,
}: {
  onUpdatedDraft?: (draft: PersonaDraft) => void;
  onStreamChunk?: (data: ChunkData) => void;
  onStreamEnd?: () => void;
  onStreamError?: (error: unknown) => void;
  onStreamStart?: () => void;
  onStreamComplete?: () => void;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: changeDraftPersona,
    async onSuccess(stream, variables) {
      const { commId, draftId } = variables;

      onStreamStart?.();
      try {
        for await (const chunk of readStream(stream)) {
          if (chunk.event === "updatedDraft") {
            const draft = JSON.parse(chunk.data) as PersonaDraft;
            onUpdatedDraft?.(draft);
            // Update the draft in the cache on each chunk
            queryClient.setQueryData<PersonaDraft>(
              commPersonaDraftQueryKeys.byCommIdAndDraftId(commId, draftId),
              (oldData) => {
                return {
                  ...oldData,
                  ...draft,
                  status: "DRAFT_LOADING",
                };
              },
            );
          }
          if (chunk.event === "message") {
            const data = JSON.parse(chunk.data) as {
              contents: DraftContent;
            };
            onStreamChunk?.(data);
            // Update the draft in the cache on each chunk
            queryClient.setQueryData<PersonaDraft>(
              commPersonaDraftQueryKeys.byCommIdAndDraftId(commId, draftId),
              (oldData) => {
                if (!oldData) return oldData;
                return {
                  ...oldData,
                  status: "DRAFT_SAVED",
                  draftContent: data.contents,
                };
              },
            );
          }
          if (chunk.event === "error") {
            const data = JSON.parse(chunk.data) as {
              message: string;
              code: string;
              id: string;
            };
            throw new Error(`${data.code}: ${data.message}`);
          }
        }
        onStreamComplete?.();
      } catch (e) {
        console.error(e);
        onStreamError?.(e);
      } finally {
        onStreamEnd?.();
      }
    },
  });
}

async function* readStream(stream: ReadableStream<Uint8Array>) {
  const eventStream = stream
    .pipeThrough(new TextDecoderStream())
    .pipeThrough(new EventSourceParserStream())
    .getReader();

  while (true) {
    const { done, value } = await eventStream.read();
    if (done) break;
    yield value;
  }
}
