import { queryOptions, useQuery } from "@tanstack/react-query";
import { getTemplateDetails } from "../apis/getTemplateDetails";

export const templateDetailsQueryKeys = {
  all: ["templates", "details"] as const,
  byId: (id: string) => [...templateDetailsQueryKeys.all, id] as const,
};

export function getTemplateDetailsQuery(templateId: string) {
  return queryOptions({
    queryKey: templateDetailsQueryKeys.byId(templateId),
    queryFn: ({ queryKey }) => getTemplateDetails(queryKey[2]),
  });
}

export function useTemplateDetailsQuery(templateId: string) {
  return useQuery(getTemplateDetailsQuery(templateId));
}
