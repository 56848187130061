import { Communication, DraftContent, PersonaDraft } from "../types";
import { useMemo } from "react";
import { EditorContainer } from "./EditorContainer";
import { EditorWrapper } from "./EditorWrapper";
import { HTMLRenderer } from "./HTMLRenderer";

export function NewDraftViewer({
  draft,
  communication,
}: {
  draft: PersonaDraft;
  communication: Communication;
}) {
  const html = communication.details?.layout?.layoutHtml;
  const content = draft.draftContent;

  // Safety checks that we will remove later
  if (!html) throw new Error("HTML layout is missing");
  if (!content) throw new Error("Draft content is missing");

  const initialHTML = useMemo(() => {
    return replaceContent(html, content);
  }, [html, content]);

  return (
    <EditorWrapper>
      <EditorContainer>
        <HTMLRenderer>{initialHTML}</HTMLRenderer>
      </EditorContainer>
    </EditorWrapper>
  );
}

function replaceContent(html: string, content: DraftContent) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  for (const item of content) {
    const node = doc.querySelector(`[data-content-id="${item.id}"]`);
    if (!node) {
      console.error(`Node with ID ${item.id} not found`);
      continue;
    }
    node.innerHTML = item.content;
  }

  return doc.body.innerHTML;
}
