import fetch from "../../_shared/fetch";
import { CommunicationType } from "../../contentCreation/types";
import { ColumnName, SortDirection, TemplatesResponse } from "../types";

// TODO: handle communicationType in the API
export function getTemplatesAPI(
  pageParam: number,
  sortBy?: Record<ColumnName, SortDirection>,
  communicationType?: CommunicationType,
): Promise<TemplatesResponse> {
  const sortByString = sortBy
    ? Object.entries(sortBy)
        .map(([key, value]) => `${key}.${value}`)
        .join(",")
    : undefined;

  return fetch.get("/layout-templates", {
    pageNumber: pageParam,
    pageSize: 10,
    ...(communicationType && { communicationType }),
    ...(sortBy && { sortBy: sortByString }),
  });
}
