import fetch from "../../_shared/fetch";
import { PersonaDraft } from "../types";

export async function deletePersonaDraft({
  commId,
  draftId,
}: {
  commId: string;
  draftId: string;
}) {
  const result: PersonaDraft = await fetch.delete(
    `/communications/${commId}/persona-drafts/${draftId}`,
    {
      extractJson: false,
    },
  );
  return result;
}
