import { MouseEventHandler, useCallback } from "react";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { PersonaDraft } from "../types";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Copy01Icon, Delete01Icon } from "hugeicons-react";
import { useDuplicatePersonaDraftMutation } from "../mutations/useDuplicatePersonaDraftMutation";
import { useDeletePersonaDraftMutation } from "../mutations/useDeletePersonaDraftMutation";

const StyledButton = styled(Button)(({ theme }) => ({
  width: "2rem",
  height: "2rem",
  padding: 0,
  svg: {
    color: `${theme.palette.grey[11]} !important`,
    strokeWidth: 2,
  },
}));

export function CommDraftCardActions({
  commId,
  draft,
  onBeforeDelete,
  onAfterCopy,
  canDelete,
}: {
  commId: string;
  draft: PersonaDraft;
  onBeforeDelete: (draft: PersonaDraft) => void;
  onAfterCopy: (draft: PersonaDraft) => void;
  canDelete: boolean;
}) {
  return (
    <Flex gap="sm-5">
      <CopyButton commId={commId} draft={draft} onAfterCopy={onAfterCopy} />
      <DeleteButton
        commId={commId}
        draft={draft}
        onBeforeDelete={onBeforeDelete}
        canDelete={canDelete}
      />
    </Flex>
  );
}
function DeleteButton({
  commId,
  draft,
  canDelete,
  onBeforeDelete,
}: {
  commId: string;
  draft: PersonaDraft;
  canDelete: boolean;
  onBeforeDelete: (personaDraft: PersonaDraft) => void;
}) {
  const { mutate, isPending } = useDeletePersonaDraftMutation();

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    onBeforeDelete(draft);
    mutate({ commId, draftId: draft.id });
  }, [commId, draft, mutate, onBeforeDelete]);

  return (
    <StyledButton
      onlyIcon
      icon={
        <Icon>
          <Delete01Icon />
        </Icon>
      }
      size="sm"
      color="grey"
      variant="outlined"
      disabled={isPending || canDelete}
      onClick={handleClick}
    />
  );
}
function CopyButton({
  commId,
  draft,
  onAfterCopy,
}: {
  commId: string;
  draft: PersonaDraft;
  onAfterCopy: (draft: PersonaDraft) => void;
}) {
  const { mutate, isPending } = useDuplicatePersonaDraftMutation();

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    mutate({ commId, draftId: draft.id }, { onSuccess: onAfterCopy });
  }, [commId, draft, mutate, onAfterCopy]);

  return (
    <StyledButton
      onlyIcon
      icon={
        <Icon color="grey">
          <Copy01Icon />
        </Icon>
      }
      size="sm"
      color="grey"
      variant="outlined"
      disabled={isPending}
      onClick={handleClick}
    />
  );
}
