import { useTranslate } from "@xtreamsrl/react-i18n";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { TextInput } from "@xtreamsrl/react-ui-kit/TextInput";
import { TextInputBasic } from "@xtreamsrl/react-ui-kit/TextInputBasic";
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useState,
} from "react";
import type { TranslationKeys } from "src/_shared/assets/i18n";
import { ReaidyLogoNoTextSvg } from "@reaidy/icons";

const gradientColorStart = "rgba(251,252,255,1)";
const gradientColorEnd = "rgba(255,255,255,0)";

const Container = styled("div")(({ theme }) => ({
  // color: theme.palette.grey[9],
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  paddingTop: theme.spacing("sm-4"),
  paddingBottom: theme.spacing("md-5"),
  paddingInline: theme.spacing("md-5"),
  zIndex: 12,
  backgroundColor: theme.palette.grey[1],

  "::before": {
    pointerEvents: "none",
    content: '""',
    position: "absolute",
    bottom: "100%",
    left: 0,
    right: 0,
    height: "10px",
    background: `linear-gradient(to top, ${gradientColorStart}, ${gradientColorEnd})`,
  },
}));

const StyledTextInput = styled(TextInputBasic)(({ theme }) => ({
  "--_text-color": theme.palette.grey[1],
  "--_bg-color": "rgba(56, 68, 85, 1)",
  "--_border-color": "rgba(88, 101, 119, 1)",
  "--TextField-brandBorderColor": "var(--_border-color)",
  "--TextField-brandBorderHoverColor": "var(--_border-color)",
  "--TextField-brandBorderFocusedColor": "var(--_border-color)",

  ".MuiInputBase-root": {
    height: "3.5rem",
    boxSizing: "border-box",
    color: "var(--_text-color)",
    backgroundColor: "var(--_bg-color) !important",
    borderWidth: "4px",
    borderColor: "var(--_border-color)",
    "&:hover": {
      backgroundColor: "var(--_bg-color) !important",
      borderColor: "var(--_border-color)",
    },
  },
  ".MuiInputBase-input": {
    color: "var(--_text-color)",
    backgroundColor: "var(--_bg-color)",
    "&::placeholder": {
      color: "var(--_text-color)",
    },
    "&:focus,&:hover": {
      boxShadow: "none",
      color: "var(--_text-color)",
      backgroundColor: "var(--_bg-color)",
      borderColor: "var(--_border-color)",
    },
  },
  ".MuiInputAdornment-root:nth-of-type(2)": {
    marginRight: "unset",
    marginLeft: "0.5rem",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.brand[9],
  height: "1.5rem",
  width: "1.5rem",
  "&:hover": {
    backgroundColor: theme.palette.brand[9] + " !important",
  },
  svg: {
    color: theme.palette.grey[5] + " !important",
    width: "1rem",
    height: "1rem",
  },
}));

export function FloatingPrompt({
  onChat,
  isLoading,
}: {
  onChat?: (prompt: string) => void;
  isLoading?: boolean;
}) {
  const t = useTranslate();
  const [input, setInput] = useState("");
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => setInput(e.target.value),
    [],
  );

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      setInput("");
      onChat?.(input);
    },
    [input, onChat],
  );

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <StyledTextInput
          name="suggestion"
          value={input}
          onChange={handleChange}
          size="lg"
          placeholder={t(
            "editing.floatingPrompt.placeholder" satisfies TranslationKeys,
          )}
          startInputAddOn={
            <TextInput.AddOn>
              <ReaidyLogoNoTextSvg width="33px" height="24px" />
            </TextInput.AddOn>
          }
          endInputAddOn={
            <TextInput.AddOn>
              <StyledButton
                onlyIcon
                icon={<Icon name="ArrowUp" />}
                size="sm"
                color="brand"
                disabled={isLoading}
                type="submit"
              />
            </TextInput.AddOn>
          }
        />
      </Container>
    </form>
  );
}
