import { Theme } from "@reaidy/components";
import { FormProvider } from "@xtreamsrl/react-forms";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Divider } from "@xtreamsrl/react-ui-kit/Divider";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { Select } from "@xtreamsrl/react-ui-kit/Select";
import { SelectBasic } from "@xtreamsrl/react-ui-kit/SelectBasic";
import { TextInput } from "@xtreamsrl/react-ui-kit/TextInput";
import { TextInputBasic } from "@xtreamsrl/react-ui-kit/TextInputBasic";
import { createContext, useMemo, useState } from "react";
import { FeedbackModal } from "../../_shared/components/FeedbackModal";
import { CommunicationType } from "../../contentCreation/types";
import { TemplatesEvents, useTracker } from "../analytics";
import { TemplateFixedItem } from "../components/TemplateFixedItem.tsx";
import { TemplatePreview } from "../components/TemplatePreview.tsx";
import { TemplateSections } from "../components/TemplateSections.tsx";
import { useTemplateForm } from "../hooks/useTemplateForm.ts";
import {
  ComponentType,
  GenericTemplateComponent,
  TemplateData,
  isDemTemplate,
} from "../types";
import { moveSectionTitleInsideComponents } from "../utils/index.ts";

export const CommunicationTypeContext = createContext<CommunicationType>(
  CommunicationType.DEM,
);

const titleInputOverrides = {
  sx: (theme: Theme) => ({
    width: "100%",
    maxWidth: "100%",
    flex: 1,
    input: {
      ...theme.typography["body/xxl/bold"],
    },
  }),
};

export const TemplateCreator = ({
  closeCreator,
  onSuccess,
  initialValues,
  name: initialName,
  templateId,
  communicationType: externallySetCommunicationType,
}: {
  closeCreator: () => void;
  onSuccess?: (template: TemplateData) => void;
  initialValues?: GenericTemplateComponent;
  templateId?: string;
  communicationType?: CommunicationType;
  name?: string;
}) => {
  const t = useTranslate();
  // if the communicationType is received as prop, it means that the user is duplicating or updating a template or the user is in the contentCreation path.
  // in this two cases the communicationType is already set and the user can't change it -> see the disabled prop in the SelectBasic component below
  // if the communicationType is not received as prop, the default value is DEM (it means that the user is creating a new template from the archive and will be able to choose the communicationType freely)
  const [communicationType, setCommunicationType] = useState<CommunicationType>(
    externallySetCommunicationType || CommunicationType.DEM,
  );

  const handleSuccess = (template: TemplateData) => {
    closeCreator();
    onSuccess?.(template);
  };

  const transformedInitialValues = useMemo(() => {
    if (initialValues && isDemTemplate(initialValues)) {
      return moveSectionTitleInsideComponents(initialValues);
    }
  }, [initialValues]);

  const {
    isPending,
    modalState,
    failureCode,
    closeFailureModal,
    formProps,
    submit,
    name,
    setName,
    isTitle,
    addTitle,
    removeTitle,
    resetForm,
  } = useTemplateForm({
    onSuccess: handleSuccess,
    initialValues: transformedInitialValues,
    templateId,
    name: initialName,
    communicationType: communicationType,
  });

  const { track } = useTracker();
  const contentKey = `template.creation.failure.${failureCode}`;
  return (
    <CommunicationTypeContext.Provider value={communicationType}>
      <FeedbackModal
        visible={modalState === "FEEDBACK"}
        headerKey={"template.creation.failure.header"}
        contentKey={contentKey}
        primaryAction={closeFailureModal}
      />
      <FormProvider {...formProps}>
        <form
          style={{ height: "100%" }}
          onSubmit={(e) => {
            e.preventDefault();
            submit().catch((error) => {
              console.error("Failed to submit form:", error);
            });
          }}
        >
          <Flex flex={3} direction="column" gap="sm-8" height="100%">
            {/* View's header */}
            <Box>
              <TextInputBasic
                testId="template-name"
                name="template-name"
                size="sm"
                variant="filled"
                {...titleInputOverrides}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
            <Divider />
            <Flex gap="1rem" flex={1} flexBasis="12rem" overflow="auto">
              <Box px="1rem" flex="1 1 auto" overflow="auto">
                <TemplatePreview />
              </Box>
              <Divider direction="vertical" />
              <Flex
                flex="0 0 35rem"
                direction="column"
                gap="sm-6"
                height="100%"
                width="100%"
                overflow="auto"
              >
                <SelectBasic
                  size="md"
                  name="template-type"
                  startInputAddOn={
                    <TextInput.AddOn>
                      {t("template.creation.communicationType")}
                    </TextInput.AddOn>
                  }
                  // if the communicationType is received as prop, the user can't change it. It means that the user is duplicating or updating a template or the user is in the contentCreation path
                  disabled={!!externallySetCommunicationType}
                  value={communicationType}
                  onChange={(e) => {
                    !templateId && removeTitle(); // Remove title if it's a new template
                    setCommunicationType(e.target.value as CommunicationType);
                    resetForm(e.target.value as CommunicationType);
                  }}
                >
                  {Object.values(CommunicationType).map((type) => (
                    <Select.Option key={type} value={type}>
                      {t(`shared.communicationTypes.${type}.title`)}
                    </Select.Option>
                  ))}
                </SelectBasic>
                {/* TODO: abstract on some info line hasSubject or similar */}
                {(communicationType === CommunicationType.DEM ||
                  communicationType === CommunicationType.PAPER_MAIL) && (
                  <>
                    <TemplateFixedItem
                      communicationType={communicationType}
                      fieldName="subject"
                      value={ComponentType.SUBJECT}
                    />
                    {!isTitle ? (
                      <Box alignSelf="center">
                        <Button
                          size="sm"
                          variant="tinted"
                          leftIcon={<Icon name="Plus" />}
                          onClick={() => {
                            track(TemplatesEvents.AddTemplateTitle);
                            addTitle();
                          }}
                        >
                          {t("template.creation.addTitle")}
                        </Button>
                      </Box>
                    ) : (
                      <TemplateFixedItem
                        communicationType={communicationType}
                        fieldName="mainTitle"
                        value={ComponentType.MAIN_TITLE}
                        remove={() => {
                          track(TemplatesEvents.RemoveTemplateTitle);
                          removeTitle();
                        }}
                      />
                    )}
                  </>
                )}
                <TemplateSections />
              </Flex>
            </Flex>
            <Divider />
            <Flex justifyContent="flex-end" gap="sm-4">
              <Button
                type="button"
                variant="outlined"
                onClick={closeCreator}
                color="grey"
              >
                {t("template.creation.cancel")}
              </Button>
              <Button
                testId="create-template"
                loading={isPending}
                type="submit"
                variant="filled"
              >
                {t(
                  templateId
                    ? "template.update.submit"
                    : "template.creation.submit",
                )}
              </Button>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    </CommunicationTypeContext.Provider>
  );
};
