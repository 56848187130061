import { moveSectionTitleOutsideComponents } from "src/templates/utils";
import { default as fetch } from "../../_shared/fetch";
import { Communication } from "../types";
import { CreateCommunicationInput } from "./createCommunication";

export async function recreateCommunication({
  commId,
  input,
}: {
  commId: string;
  input: CreateCommunicationInput;
}): Promise<Communication> {
  // Hack to apply the correct structure to the communication
  const layoutStructure =
    input.details.source === "MANUAL"
      ? moveSectionTitleOutsideComponents(input.details.layoutStructure)
      : input.details.layoutStructure;

  const communication: Communication = await fetch.put(
    `/communications/${commId}`,
    {
      ...input,
      details: {
        ...input.details,
        layoutStructure,
      },
    },
    {
      extractJson: true,
    },
  );

  return communication;
}
