import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";

const Container = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.grey[3],
  color: theme.palette.grey[11],
  paddingBlock: theme.spacing("sm-2"),
  paddingInline: theme.spacing("sm-4"),
  borderRadius: theme.spacing("sm-1"),
  ...theme.typography["body/sm/medium"],
}));

export function HTMLTemplateBadge() {
  return <Container>HTML</Container>;
}
