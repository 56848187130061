import { ReaidyLogoInlineSvg } from "@reaidy/icons";
import { pages } from "@reaidy/pages";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { useLogout } from "../../login/hooks/useLogout.ts";
import { useSideMenu } from "../hooks/useSideMenu";
import { SideMenuButton } from "./SideMenuButton";
import {
  AddSquareIcon,
  FolderDetailsIcon,
  Home11Icon,
  Layout01Icon,
  Logout03Icon,
} from "hugeicons-react";
import { resetState } from "src/contentCreation/slice/contentCreationSlice.ts";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { useCallback, useEffect, useRef } from "react";

const menuItems = [
  {
    icon: <AddSquareIcon />,
    label: "sideMenu.write",
    page: pages.contentCreation,
  },
  {
    icon: <Home11Icon />,
    label: "sideMenu.home",
    page: pages.home,
  },
  {
    icon: <FolderDetailsIcon />,
    label: "sideMenu.archive",
    page: pages.archive,
  },
  {
    icon: <Layout01Icon />,
    label: "sideMenu.templates",
    page: pages.templates,
  },
];

export const COLLAPSED_MENU_WIDTH = "4rem";
export const EXPANDED_MENU_WIDTH = "16rem";

const Wrapper = styled("div")({
  width: COLLAPSED_MENU_WIDTH,
  height: "100%",
  overflowX: "visible",
});

const Container = styled("div")<{
  isExpanded?: boolean;
}>(({ theme, isExpanded }) => ({
  "--_bg-color": "#394455",

  position: "relative",
  width: "9999999px",
  maxWidth: isExpanded ? EXPANDED_MENU_WIDTH : "100%",
  overflowX: "clip",
  transitionProperty: "max-width",
  transitionDuration: "0.2s",
  height: "100%",
  zIndex: 100,
  backgroundColor: "var(--_bg-color)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingInline: theme.spacing("sm-5"),
  paddingBlock: theme.spacing("md-1"),
  gap: theme.spacing("md-8"),
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    width: "8px",
    height: "100%",
    backgroundColor: "#394455",
    background:
      "linear-gradient(to left, var(--_bg-color) 0%, transparent 100%)",
    zIndex: 1000,
  },
}));

const LogoContainer = styled("div")<{ isExpanded?: boolean }>(
  ({ isExpanded }) => ({
    left: 0,
    color: "white",
    width: "8.25rem",
    "& #reaiy-logo-text": {
      opacity: isExpanded ? 1 : 0,
      transitionProperty: "opacity",
      transitionDuration: "0.2s",
      transitionDelay: isExpanded ? "0" : "0.1s",
      transitionTimingFunction: isExpanded ? "ease-out" : "ease-in",
    },
  }),
);

const MENU_TIMEOUT = 1000;

export function SideMenu() {
  const t = useTranslate();
  const { goTo, pathname, isMenuExpanded, setIsMenuExpanded } = useSideMenu();
  const { logout } = useLogout();
  const menuTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseLeave = useCallback(() => {
    if (menuTimeoutRef.current) {
      clearTimeout(menuTimeoutRef.current);
      menuTimeoutRef.current = null;
    }
    setIsMenuExpanded(false);
  }, [setIsMenuExpanded]);

  const handleMouseEnter = useCallback(() => {
    if (menuTimeoutRef.current) {
      clearTimeout(menuTimeoutRef.current);
    }
    const expandMenu = () => setIsMenuExpanded(true);
    menuTimeoutRef.current = setTimeout(expandMenu, MENU_TIMEOUT);
  }, [setIsMenuExpanded]);

  useEffect(() => {
    return () => {
      if (menuTimeoutRef.current) {
        clearTimeout(menuTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Wrapper>
      <Container
        isExpanded={isMenuExpanded}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <LogoContainer isExpanded={isMenuExpanded}>
          <ReaidyLogoInlineSvg width="8.25rem" height="auto" />
        </LogoContainer>
        <Flex direction="column" gap="sm-4" flex={1}>
          {menuItems.map((item) => (
            <SideMenuButton
              key={item.label}
              testId={item.label}
              icon={item.icon}
              label={t(item.label)}
              active={pathname === item.page}
              isExpanded={isMenuExpanded}
              onClick={() => {
                if (pathname !== pages.contentCreation) {
                  resetState();
                }
                goTo(item.page);
              }}
            />
          ))}
        </Flex>
        <SideMenuButton
          testId="logout"
          icon={<Logout03Icon />}
          label={t("sideMenu.logout")}
          active={false}
          onClick={logout}
          isExpanded={isMenuExpanded}
        />
      </Container>
    </Wrapper>
  );
}
