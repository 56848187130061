export const pages = {
  home: "/",
  // path with parameter
  // communicationById: (id: string) => `/communications/${id}`,
  contentCreation: "/contentCreation",
  archive: "/archive",
  edit: "/communications/:communicationId/edit",
  editById: (communicationId: string) =>
    `/communications/${communicationId}/edit`,
  view: "/communications/:communicationId/view",
  viewById: (communicationId: string) =>
    `/communications/${communicationId}/view`,
  login: "/login",
  templates: "/templates",
};
