import { Template } from "../types/index";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Card } from "@xtreamsrl/react-ui-kit/Card";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { MoreVerticalCircle01Icon } from "hugeicons-react";
import {
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useState,
  useId,
  useMemo,
} from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@xtreamsrl/react-ui-kit/Divider";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { useTemplatesListQuery } from "../queries/useTemplateListQuery";
import { useDeleteTemplateMutation } from "../mutations/useDeleteTemplateMutation";
import { TemplateCreator } from "../views/TemplateCreator";
import { SidePanel } from "@xtreamsrl/react-ui-kit/SidePanel";
import { ImportedLayoutModal } from "./ImportedTemplateModal";
import { HTMLTemplateBadge } from "src/_shared/components/HTMLTemplateBadge";

const HTMLContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing("sm-6"),
}));

export function TemplateList() {
  const { data } = useTemplatesListQuery({});
  const templates = useMemo(
    () => data?.pages.flatMap((page) => page.content) ?? [],
    [data],
  );

  return (
    <Flex direction="column" gap="sm-5">
      <HTMLContainer>
        {templates.map((template) => (
          <TemplateItem key={template.id} template={template} />
        ))}
      </HTMLContainer>
    </Flex>
  );
}

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  transition: "background-color 0.2s",
  "&:hover": {
    backgroundColor: theme.palette.grey[2],
  },
}));

const CardContent = styled("div")((props) => ({
  display: "flex",
  gap: props.theme.spacing("sm-8"),
  padding: props.theme.spacing("sm-5"),
  alignItems: "center",
}));

const DangerMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.error[11],
  "&:hover": {
    backgroundColor: theme.palette.error[3],
  },
}));

function TemplateItem({ template }: { template: Template }) {
  const t = useTranslate();
  const itemId = useId();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const menuId = "template-menu-" + itemId;
  const menuButtonId = "template-menu-button-" + itemId;

  const { mutate: deleteTemplate } = useDeleteTemplateMutation();
  const [showCreator, setShowCreator] = useState(false);
  const closeCreator = useCallback(() => setShowCreator(false), []);
  const openCreator = useCallback(() => setShowCreator(true), []);

  const handleDelete = useCallback(() => {
    deleteTemplate({
      templateId: template.id,
    });
    setMenuOpen(false);
  }, [deleteTemplate, template]);

  const handleEdit = useCallback(() => {
    openCreator();
    setMenuOpen(false);
  }, [openCreator, setMenuOpen]);

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (e.target instanceof HTMLElement && e.target.id !== menuButtonId) {
        handleEdit();
      }
    },
    [handleEdit, menuButtonId],
  );

  return (
    <>
      <StyledCard onClick={handleClick}>
        <CardContent>
          <TemplateName template={template} />
          <TemplateMenuButton
            buttonId={menuButtonId}
            menuId={menuId}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            setMenuAnchor={setAnchorEl}
          />
        </CardContent>
      </StyledCard>
      <TemplateMenu
        menuId={menuId}
        menuOpen={menuOpen}
        menuAnchor={anchorEl}
        setMenuOpen={setMenuOpen}
      >
        {template.source === "MANUAL" && (
          <MenuItem onClick={handleEdit}>{t("templates.menu.edit")}</MenuItem>
        )}
        <Divider />
        <DangerMenuItem onClick={handleDelete}>
          {t("templates.menu.delete")}
        </DangerMenuItem>
      </TemplateMenu>
      {showCreator && template.source === "MANUAL" && (
        <SidePanel open={showCreator} onClose={closeCreator} anchor="right">
          <SidePanel.Content height="100%" width="80vw">
            <TemplateCreator
              templateId={template.id}
              name={template.name}
              initialValues={template.structure}
              communicationType={template.communicationType}
              closeCreator={closeCreator}
            />
          </SidePanel.Content>
        </SidePanel>
      )}
      {showCreator && template.source === "IMPORTED" && (
        <ImportedLayoutModal
          template={template}
          closeModal={closeCreator}
          modalIsOpen={showCreator}
        />
      )}
    </>
  );
}

function TemplateName({ template }: { template: Template }) {
  return (
    <Flex
      direction="row"
      overflow="hidden"
      flex="1"
      gap="sm-5"
      alignItems="baseline"
    >
      <Typography
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        {...{ overflow: "hidden" }}
      >
        {template.name}
      </Typography>

      {template.source === "IMPORTED" && <HTMLTemplateBadge />}
    </Flex>
  );
}

function TemplateMenuButton({
  menuOpen,
  setMenuOpen,
  setMenuAnchor,
  menuId,
  buttonId,
}: {
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  setMenuAnchor: (anchor: HTMLElement | null) => void;
  menuId: string;
  buttonId: string;
}) {
  const a11yProps = {
    id: buttonId,
    "aria-controls": menuOpen ? menuId : undefined,
    "aria-haspopup": true,
    "aria-expanded": menuOpen ? true : undefined,
  };

  const handleClick = useCallback<MouseEventHandler>(
    (e) => {
      setMenuAnchor(e.currentTarget as HTMLElement);
      setMenuOpen(true);
    },
    [setMenuAnchor, setMenuOpen],
  );

  return (
    <Button
      {...a11yProps}
      onClick={handleClick}
      size="md"
      onlyIcon
      variant="plain"
      color="grey"
      icon={
        <Icon>
          <MoreVerticalCircle01Icon fill="currentColor" />
        </Icon>
      }
    />
  );
}

function TemplateMenu({
  children,
  menuId,
  menuOpen,
  setMenuOpen,
  menuAnchor,
}: PropsWithChildren<{
  setMenuOpen: (open: boolean) => void;
  menuId: string;
  menuOpen: boolean;
  menuAnchor: HTMLElement | null;
}>) {
  const handleClose = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  const a11yProps = {
    id: menuId,
    "aria-expanded": menuOpen ? true : undefined,
  };

  return (
    <Menu
      {...a11yProps}
      anchorEl={menuAnchor}
      open={menuOpen}
      onClose={handleClose}
    >
      {children}
    </Menu>
  );
}
