import { useTranslate } from "@xtreamsrl/react-i18n";
import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Step } from "@xtreamsrl/react-ui-kit/Step";
import { Stepper } from "@xtreamsrl/react-ui-kit/Stepper";
import { TopBar } from "../../_shared/components/TopBar.tsx";
import { useStepper } from "../hooks/useStepper";
import { useSteps } from "../hooks/useSteps";
import { AvailableStep, StepKey } from "../types";
import { stepByKey } from "../utils/utils";
import { StepIcon } from "../components/StepIcon";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { useBrowserNavigation } from "@xtreamsrl/react-routing";
import { ContentCreationEvents, useTracker } from "../analytics/index.ts";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { pages } from "@reaidy/pages";

const componentToRender = (activeStep: StepKey) => {
  const stepEl = stepByKey[activeStep];
  if (stepEl?.element) return stepEl.element;
  else throw new Error("Step not found");
};

const StyledTopBar = styled(TopBar)({
  justifyContent: "space-between",
  paddingInline: "3rem",
  gap: "3rem",
});

const StepperWrapper = styled("div")({
  position: "relative",
  flex: 1,
  overflowX: "hidden",
  width: "100%",
  height: "100%",
});

const StyledStepper = styled(Stepper)({
  position: "absolute",
  inset: 0,
});

const StyledStep = styled(Step)<{ isActive?: boolean; isCompleted?: boolean }>(
  ({ theme, isActive, isCompleted }) => {
    const completeOrActive = isCompleted || isActive;
    return {
      "--_step-bg-color": completeOrActive
        ? theme.palette.brand[9]
        : theme.palette.grey[3],
      "--_step-text-color": completeOrActive
        ? theme.palette.grey[1]
        : theme.palette.grey[12],
      "--_step-border-color": completeOrActive
        ? theme.palette.brand[9]
        : theme.palette.grey[7],

      color: "var(--_step-text-color)",

      ".MuiSvgIcon-root": {
        overflow: "clip",
        borderRadius: "50%",
        boxShadow: isActive ? `0px 0px 0px 2px var(--_step-bg-color)` : "none",
      },
      ".MuiSvgIcon-root svg rect": {
        // the circle
        fill: "var(--_step-bg-color)",
        stroke: isActive
          ? "var(--_step-text-color)"
          : "var(--_step-border-color)",
        strokeWidth: 2,
      },
      ".MuiSvgIcon-root svg path": {
        // the checkmark
        stroke: "var(--_step-text-color)",
        strokeWidth: 2,
        width: "2rem",
        height: "2rem",
      },
      ".MuiStepLabel-label": {
        // the name of the step
        ...theme.typography["body/sm/regular"],
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    };
  },
);
StyledStep.displayName = "Step";

export function ContentCreator() {
  const { activeStepKey } = useStepper();

  return (
    <Flex direction="column" width="100%" height="100%">
      <Header />
      <Box overflow="auto" flex="1">
        {componentToRender(activeStepKey)}
      </Box>
    </Flex>
  );
}

export function Header() {
  const { goTo } = useBrowserNavigation();
  const { steps } = useSteps();
  const { track } = useTracker();
  const { activeStepNumber, activeStepKey } = useStepper();
  const t = useTranslate();

  return (
    <StyledTopBar>
      <StepperWrapper>
        <StyledStepper activeStep={activeStepNumber}>
          {steps.map((step: AvailableStep, index) => (
            <StyledStep
              StepIconComponent={StepIcon}
              key={step.id}
              label={t(step.label)}
              isActive={step.id === activeStepKey}
              isCompleted={index < activeStepNumber}
            />
          ))}
        </StyledStepper>
      </StepperWrapper>
      <Button
        color="brand"
        variant="tinted"
        onClick={() => {
          track(ContentCreationEvents.CloseCreationView);
          goTo(pages.home);
        }}
      >
        {t("contentCreation.exit")}
      </Button>
    </StyledTopBar>
  );
}
