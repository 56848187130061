import { useMutation, useQueryClient } from "@tanstack/react-query";
import { searchCommsListQueryKeys } from "src/archive/queries/useSearchCommsQuery";
import { updateCommunication } from "../apis/updateComm";
import { commDetailsQueryKeys } from "../queries/useCommDetailsQuery";
import { Communication } from "../types";

export function useUpdateCommMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCommunication,
    async onMutate({ commId, changes }) {
      // Cancel related queries
      const cancelPromise = Promise.allSettled([
        queryClient.cancelQueries({
          queryKey: searchCommsListQueryKeys.all,
        }),
        queryClient.cancelQueries({
          queryKey: commDetailsQueryKeys.byId(commId),
        }),
      ]);

      // Get the currenct communication data
      const previousComm = queryClient.getQueryData<Communication>(
        commDetailsQueryKeys.byId(commId),
      );
      if (!previousComm) {
        throw new Error("Communication not found");
      }

      // Optimistically update the cache
      queryClient.setQueryData<Communication>(
        commDetailsQueryKeys.byId(commId),
        {
          ...previousComm,
          ...changes,
          _optimistic: true,
        },
      );

      // It's important to cancel outgoing refetches asap,
      // but awaiting directly makes the optimistic update laggy
      await cancelPromise;

      // Return a context object with the snapshotted value
      return { previousComm };
    },
    onError(err, variables, context) {
      console.error(err);
      // Rollback on error
      if (context?.previousComm) {
        queryClient.setQueryData(
          commDetailsQueryKeys.byId(variables.commId),
          context.previousComm,
        );
      }
    },
    async onSettled(_, __, { commId }) {
      // Invalidate related queries
      await queryClient.invalidateQueries({
        queryKey: searchCommsListQueryKeys.all,
      });
      await queryClient.invalidateQueries({
        queryKey: commDetailsQueryKeys.byId(commId),
      });
    },
  });
}
