import { ContentCreationEvents, useTracker } from "../analytics";
import { stepByKey } from "../utils/utils.tsx";
import { useActiveStep } from "./useActiveStep.ts";
import { useSteps } from "./useSteps.ts";
import { useContentCreationSlice } from "../slice/contentCreationSlice.ts";

export function useStepper() {
  const [activeStep, setActiveStep] = useActiveStep();
  const { steps } = useSteps();
  const activeStepNumber = steps.findIndex((step) => step.id === activeStep);
  const { track } = useTracker();

  function previous() {
    track(ContentCreationEvents.GoBack, { activeStep });
    if (activeStepNumber === 0) {
      throw new Error("No step to go");
    }
    const currentStep = stepByKey[activeStep];
    let previousTep;
    if (currentStep.findPreviousStep) {
      previousTep = currentStep.findPreviousStep({
        communicationType: useContentCreationSlice.getState().communicationType,
      });
    } else {
      previousTep = steps[activeStepNumber - 1].id;
    }
    setActiveStep(previousTep);
  }

  function next() {
    if (activeStepNumber === steps.length - 1) {
      throw new Error("No step to go");
    }
    const currentStep = stepByKey[activeStep];
    let nextStep;
    if (currentStep.findNextStep) {
      nextStep = currentStep.findNextStep({
        communicationType: useContentCreationSlice.getState().communicationType,
      });
    } else {
      nextStep = steps[activeStepNumber + 1].id;
    }
    setActiveStep(nextStep);
  }

  return { previous, next, activeStepNumber, activeStepKey: activeStep };
}
