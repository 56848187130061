import { useCommDetailsQuery } from "../queries/useCommDetailsQuery";
import { useState, useCallback, ChangeEventHandler, useRef } from "react";
import { TextInput } from "@xtreamsrl/react-ui-kit/TextInput";
import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { useUpdateCommMutation } from "../mutations/useUpdateCommMutation";

const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing("md-2"),
  marginRight: theme.spacing("md-2"),
  ".MuiFormLabel-root": {
    ...theme.typography["body/base/medium"],
  },
}));

export function CommNameInput({
  commId,
  disabled,
}: {
  commId: string;
  disabled?: boolean;
}) {
  const { data: comm } = useCommDetailsQuery(commId);
  if (!comm) return null;
  return (
    <Container>
      <NameInput initialValue={comm.name} commId={commId} disabled={disabled} />
    </Container>
  );
}

const StyledTextInput = styled(TextInput)(({ theme }) => ({
  marginTop: theme.spacing("sm-4"),
  ".MuiFormHelperText-root": {
    display: "none",
  },
}));

function NameInput({
  initialValue,
  commId,
  disabled,
}: {
  initialValue: string;
  commId: string;
  disabled?: boolean;
}) {
  const [value, setValue] = useState(initialValue);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { mutate } = useUpdateCommMutation();

  const saveChange = useCallback(
    (value: string) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      const save = () => {
        mutate({
          commId,
          changes: {
            name: value,
          },
        });
      };
      timeoutRef.current = setTimeout(save, 1000);
    },
    [mutate, commId],
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const nextValue = e.target.value;
      setValue(nextValue);
      saveChange(nextValue);
    },
    [setValue, saveChange],
  );

  return (
    <StyledTextInput
      label="Nome della comunicazione"
      name="commId"
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}
