import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { useStepper } from "../hooks/useStepper";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { StepLayout } from "../components/StepLayout";
import { StepInfoBox } from "../components/StepInfoBox";
import { Card } from "@xtreamsrl/react-ui-kit/Card";
import { Select } from "@xtreamsrl/react-ui-kit/Select";
import { Template } from "src/templates/types";
import { useTemplatesListQuery } from "src/templates/queries/useTemplateListQuery";
import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import { Layout01Icon } from "hugeicons-react";
import { useBriefData } from "../hooks/useBriefData";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { ManualLayoutModal } from "../components/ManualLayoutModal";
import { ImportedLayoutModal } from "../components/ImportedLayoutModal";
import { HTMLTemplateBadge } from "src/_shared/components/HTMLTemplateBadge";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";

export function SelectLayout() {
  const { data } = useTemplatesListQuery();
  const templates = useMemo(
    () => data?.pages.flatMap((page) => page.content),
    [data],
  );

  if (templates) {
    return <SelectLayoutComponent templates={templates} />;
  }
  return null; // TODO: add loading state
}

export type Intent = "START_FROM_TEMPLATE" | "START_FROM_SCRATCH";

export function SelectLayoutComponent({
  templates,
}: {
  templates: Template[];
}) {
  const { previous } = useStepper();
  const t = useTranslate();

  const [intent, setIntent] = useState<Intent | null>(null);
  const [templateId, setTemplateId] = useState<string | null>(null);
  const [briefData] = useBriefData();

  const [modalIsOpen, setModalIsOpen] = useState(
    briefData?.layoutStructure != null,
  );

  const openModal = useCallback(() => setModalIsOpen(true), [setModalIsOpen]);
  const closeModal = useCallback(() => setModalIsOpen(false), [setModalIsOpen]);

  const template = useMemo(() => {
    return templates.find((template) => template.id === templateId);
  }, [templates, templateId]);

  const canOpenModal =
    intent === "START_FROM_TEMPLATE"
      ? templateId !== null
      : intent === "START_FROM_SCRATCH";

  const typeOfLayout: "IMPORTED" | "MANUAL" =
    intent === "START_FROM_TEMPLATE"
      ? (template?.source ?? "MANUAL")
      : "MANUAL";

  return (
    <>
      <StepLayout
        title={t("layout.header")}
        leftPane={<LeftPane />}
        rightPane={
          <RightPane
            templates={templates}
            templateId={templateId}
            setTemplateId={setTemplateId}
            intent={intent}
            setIntent={setIntent}
          />
        }
        buttons={
          <>
            <Button variant="filled" color="white" onClick={previous}>
              {t("brief.goBack")}
            </Button>
            <Button
              variant="filled"
              color="brand"
              disabled={!canOpenModal}
              onClick={openModal}
            >
              {t("brief.goNext")}
            </Button>
          </>
        }
      />
      {typeOfLayout === "MANUAL" && (
        <ManualLayoutModal
          templates={templates}
          templateId={templateId}
          closeModal={closeModal}
          modalIsOpen={modalIsOpen}
          intent={intent}
        />
      )}
      {typeOfLayout === "IMPORTED" && (
        <ImportedLayoutModal
          templates={templates}
          templateId={templateId}
          closeModal={closeModal}
          modalIsOpen={modalIsOpen}
          intent={intent}
        />
      )}
    </>
  );
}

function LeftPane() {
  const t = useTranslate();
  return (
    <StepInfoBox
      illustrationProps={{
        iconProps: {
          children: <Layout01Icon strokeWidth={2} />,
        },
      }}
    >
      <Typography variant="body/xl/regular" color="inherit">
        {t("layout.indications.0")}
      </Typography>
    </StepInfoBox>
  );
}

const FieldContainer = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing("md-4"),
  height: "100%",
  width: "100%",
  justifyContent: "center",
}));

function RightPane({
  templates,
  templateId,
  setTemplateId,
  intent,
  setIntent,
}: {
  templates: Template[];
  templateId: string | null;
  setTemplateId: (id: string | null) => void;
  intent: Intent | null;
  setIntent: (intent: Intent | null) => void;
}) {
  return (
    <FieldContainer>
      {templates != null && (
        <TemplateList
          templates={templates}
          templateId={templateId}
          setTemplateId={setTemplateId}
          intent={intent}
          setIntent={setIntent}
        />
      )}
      <CreateNewLayout intent={intent} setIntent={setIntent} />
    </FieldContainer>
  );
}

const SelectableCard = styled(Card)<{ isSelected: boolean }>(
  ({ isSelected, theme }) => ({
    border: isSelected ? "1px solid rgba(95, 77, 219, 1)" : "none",
    boxShadow: isSelected ? "0px 0px 0px 3px rgba(95, 77, 219, 0.2)" : "none",
    cursor: "pointer",
    ":hover": {
      backgroundColor: theme.palette.grey[1],
    },
  }),
);

const TemplateListCard = styled(SelectableCard)<{ isSelected: boolean }>(
  () => ({
    ".MuiFormHelperText-root": {
      display: "none",
    },
  }),
);

function TemplateList({
  templates,
  templateId,
  setTemplateId,
  setIntent,
  intent,
}: {
  templates: Template[];
  templateId: string | null;
  setTemplateId: (id: string | null) => void;
  setIntent: (intent: Intent | null) => void;
  intent: Intent | null;
}) {
  const t = useTranslate();
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setTemplateId(e.target.value);
    },
    [setTemplateId],
  );
  const handleClick = useCallback(() => {
    setIntent("START_FROM_TEMPLATE");
  }, [setIntent]);

  return (
    <TemplateListCard
      isSelected={intent === "START_FROM_TEMPLATE"}
      onClick={handleClick}
    >
      <Card.Content>
        <Select
          name="templateId"
          label={t("layout.createFromTemplate")}
          value={templateId}
          onChange={handleChange}
        >
          {templates.map((template) => (
            <Select.Option key={template.id} value={template.id}>
              <Flex alignItems="baseline" gap="sm-5">
                {template.name}
                {template.source === "IMPORTED" && <HTMLTemplateBadge />}
              </Flex>
            </Select.Option>
          ))}
        </Select>
      </Card.Content>
    </TemplateListCard>
  );
}

const CreateNewTemplateCard = styled(SelectableCard)(({ theme }) => ({
  ".MuiCardContent-root:last-child": {
    padding: theme.spacing("sm-8"),
  },
}));

function CreateNewLayout({
  intent,
  setIntent,
}: {
  intent: Intent | null;
  setIntent: (intent: Intent | null) => void;
}) {
  const t = useTranslate();
  const handleClick = useCallback(() => {
    setIntent("START_FROM_SCRATCH");
  }, [setIntent]);

  return (
    <CreateNewTemplateCard
      onClick={handleClick}
      isSelected={intent === "START_FROM_SCRATCH"}
    >
      <Card.Content>
        <Typography variant="body/base/medium">
          {t("layout.createNew.0")}
        </Typography>
        <Typography
          variant="body/base/regular"
          color="grey"
          style={{ marginTop: "0.5rem" }}
        >
          {t("layout.createNew.1")}
        </Typography>
      </Card.Content>
    </CreateNewTemplateCard>
  );
}
