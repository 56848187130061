import { useTranslate } from "@xtreamsrl/react-i18n";
import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { useEffect, useMemo, useRef } from "react";
import { TranslationKeys } from "src/_shared/assets/i18n";
import { TextModType } from "src/contentCreation/mutations/useGenerateTextModMutation";
import { Alert } from "@mui/material";
import DomPurify from "dompurify";

const Container = styled(Box)({});

const Header = styled(Box)(({ theme }) => ({
  height: "56px",
  display: "flex",
  paddingInline: theme.spacing("sm-8"),
  alignItems: "center",
  borderBottom: "1px solid",
  borderColor: theme.palette.grey[300],
}));

const Content = styled(Box)(({ theme }) => ({
  paddingInline: theme.spacing("sm-8"),
  paddingBlock: theme.spacing("sm-7"),
  width: "100%",
  maxHeight: "150px",
  overflow: "auto",
}));

const Footer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing("sm-8"),
}));

const StyledBackButton = styled(Button)(({ theme }) => ({
  ...theme.typography["body/base/medium"],
  color: theme.palette.grey[11],
  padding: 0,
  "&:hover": {
    backgroundColor: "transparent",
    color: theme.palette.grey[12],
  },
}));

const RenderedContent = styled("div")(({ theme }) => ({
  ...theme.typography["body/base/regular"],
}));

export function PreviewAction({
  generationType,
  isGenerating,
  onAccept,
  onRegenerate,
  onReset,
  content,
  error,
}: {
  generationType: TextModType;
  isGenerating: boolean;
  onAccept: () => void;
  onRegenerate: () => void;
  onReset: () => void;
  content: string;
  error: unknown;
}) {
  const t = useTranslate();
  const acceptButtonContainerRef = useRef<HTMLDivElement>(null);
  const isError = !!error;

  useEffect(() => {
    if (isGenerating) return;
    const container = acceptButtonContainerRef.current;
    const acceptButton = container?.querySelector("button");
    if (!acceptButton) return;
    if (document.activeElement === acceptButton) return;
    acceptButton.focus();
  }, [isGenerating]);

  const safeContent = useMemo(() => {
    return DomPurify.sanitize(content, {
      ALLOWED_TAGS: ["p", "span", "b", "i", "u", "em", "strong", "a"],
      ALLOW_DATA_ATTR: false, // prevent data-content-* from being injected
    });
  }, [content]);

  return (
    <Container>
      <Header>
        <StyledBackButton
          onClick={onReset}
          variant="plain"
          leftIcon={<Icon name="ChevronLeft" />}
          size="sm"
          color="grey"
        >
          {t("editing.goBack" satisfies TranslationKeys)}
        </StyledBackButton>
      </Header>
      <Content>
        <Typography variant="body/sm/regular" color="grey">
          {t(generationTypeLabel(generationType) as string)}
        </Typography>
        <Box mt="sm-6">
          {error ? (
            <ErrorMessage error={error} />
          ) : (
            <RenderedContent
              dangerouslySetInnerHTML={{
                __html: safeContent,
              }}
            />
          )}
        </Box>
      </Content>
      <Footer>
        <Flex gap="sm-4" alignItems="center">
          <Button
            onClick={onRegenerate}
            size="sm"
            variant="outlined"
            leftIcon={<Icon name="RefreshCcw" />}
            disabled={isGenerating || isError}
            color="grey"
          >
            {t("editing.regenerate" satisfies TranslationKeys)}
          </Button>
          <div ref={acceptButtonContainerRef}>
            <Button
              onClick={onAccept}
              size="sm"
              disabled={isGenerating || isError}
            >
              {t("editing.insert" satisfies TranslationKeys)}
            </Button>
          </div>
        </Flex>
      </Footer>
    </Container>
  );
}

const StyledAlert = styled(Alert)(({ theme }) => ({
  boxShadow: "none",
  borderRadius: theme.spacing("sm-3"),
}));

function ErrorMessage({ error }: { error: unknown }) {
  const t = useTranslate();
  const message = error instanceof Error ? error.message : t("editing.error");
  return <StyledAlert severity="error">{message}</StyledAlert>;
}

function generationTypeLabel(generationType: TextModType): TranslationKeys {
  switch (generationType) {
    case "EXPAND":
      return "editing.versionExpanded";
    case "SUMMARIZE":
      return "editing.versionReduced";
    case "REPHRASE":
      return "editing.versionRephrased";
    case "CUSTOM_PROMPT_MODIFICATION":
      return "editing.versionCustom";
  }
}
