import { default as fetch } from "../../_shared/fetch";
import { Communication } from "../types";

export function updateCommunication({
  commId,
  changes,
}: {
  commId: string;
  changes: Partial<Omit<Communication, "id">>;
}) {
  return fetch.patch(`/communications/${commId}`, changes, false);
}
