import { useIdentifyUser } from "@xtreamsrl/react-analytics";
import { signOut } from "firebase/auth";
import { SharedEvents, useTracker } from "../../_shared/analytics";
import { auth } from "../../_shared/config";
import { useUserInfoSlice } from "../../_shared/slice/userInfoSlice.ts";
import { resetState } from "../../contentCreation/slice/contentCreationSlice";
import { useQueryClient } from "@tanstack/react-query";

export function useLogout() {
  const { forgetUser } = useIdentifyUser();
  const { track } = useTracker();
  const queryClient = useQueryClient();

  function logout() {
    track(SharedEvents.Logout);
    signOut(auth)
      .then(() => {
        useUserInfoSlice.setState({
          userInfo: undefined,
        });
        forgetUser();
        resetState();
        queryClient.clear();
      })
      .catch((error) => {
        console.error("Failed to logout:", error);
      });
  }

  return { logout };
}
