import { create, StateCreator } from "zustand";
import { UserInfo } from "../types";

type UserInfoSlice = {
  isUserLoading: boolean;
  setIsUserLoading: (isUserLoading: boolean) => void;
  userInfo?: UserInfo;
  setUserInfo: (userInfo: UserInfo) => void;
};

const initialState = {
  isUserLoading: true,
  userInfo: undefined,
};

// TODO this will be removed later
const createUserInfoSlice: StateCreator<
  UserInfoSlice,
  [],
  [],
  UserInfoSlice
> = (set) => ({
  ...initialState,
  setUserInfo: (userInfo) => {
    set({ userInfo });
  },
  setIsUserLoading: (isUserLoading) => {
    set({ isUserLoading });
  },
});
export const useUserInfoSlice = create<UserInfoSlice>((...a) => ({
  ...createUserInfoSlice(...a),
}));
