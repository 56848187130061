import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { TopBar } from "src/_shared/components/TopBar";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { TemplateList } from "../components/TemplateList";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { useCallback, useState } from "react";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { AddSquareIcon } from "hugeicons-react";
import { SidePanel } from "@xtreamsrl/react-ui-kit/SidePanel";
import { TemplateCreator } from "./TemplateCreator";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing("md-5"),
  marginInline: theme.spacing("md-4"),
  marginBlock: theme.spacing("md-5"),
  [theme.breakpoints.up("md")]: {
    marginInline: theme.spacing("lg-1"),
  },
}));

export function Templates() {
  return (
    <Box width="100%" overflow="auto" height="100vh">
      <TopBar />
      <Container>
        <Title />
        <Controls />
        <TemplateList />
      </Container>
    </Box>
  );
}

function Title() {
  const t = useTranslate();
  return (
    <Typography variant="header/sm/semibold">{t("templates.title")}</Typography>
  );
}

function Controls() {
  return (
    <Flex>
      <CreateTemplateButton />
    </Flex>
  );
}

function CreateTemplateButton() {
  const t = useTranslate();
  const [showCreator, setShowCreator] = useState(false);
  const openCreator = useCallback(() => setShowCreator(true), []);
  const closeCreator = useCallback(() => setShowCreator(false), []);

  return (
    <>
      <Button
        size="md"
        onClick={openCreator}
        leftIcon={
          <Icon>
            <AddSquareIcon />
          </Icon>
        }
      >
        {t("templates.controls.create")}
      </Button>
      {showCreator && (
        <SidePanel open={showCreator} onClose={closeCreator} anchor="right">
          <SidePanel.Content height="100%" width="80vw">
            <TemplateCreator closeCreator={closeCreator} />
          </SidePanel.Content>
        </SidePanel>
      )}
    </>
  );
}
