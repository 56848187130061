import { PropsWithChildren, ReactNode, useMemo } from "react";
import { Modal } from "@xtreamsrl/react-ui-kit/Modal";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { Select } from "@xtreamsrl/react-ui-kit/Select";
import { Persona } from "../types";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { TextInput } from "@xtreamsrl/react-ui-kit/TextInput";
import {
  ChangeEventHandler,
  startTransition,
  useCallback,
  useState,
} from "react";
import { usePersonaListQuery } from "../queries/usePersonaListQuery";
import { styled } from "@xtreamsrl/react-ui-kit/styles";

export function CommEditingModal(
  props: PropsWithChildren<{
    title: string;
    paragraph?: string;
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode | ReactNode[];
    canClose?: boolean;
  }>,
) {
  const {
    title,
    paragraph,
    isOpen,
    onClose,
    children,
    canClose = true,
  } = props;

  const handleClose = useCallback(() => {
    canClose && onClose();
  }, [canClose, onClose]);

  return (
    <Modal open={isOpen} onClose={handleClose} showCloseButton={canClose}>
      <Modal.Content>
        <Flex direction="column" gap="2rem">
          <Flex direction="column" gap=".5rem">
            <Typography variant="body/xl/semibold">{title}</Typography>
            {paragraph && (
              <Typography variant="body/xs/regular" color="grey">
                {paragraph}
              </Typography>
            )}
          </Flex>
          {children}
        </Flex>
      </Modal.Content>
    </Modal>
  );
}

export const Emphasis = styled("em")(({ theme }) => ({
  color: theme.palette.brand["10"],
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  marginTop: theme.spacing("sm-4"),
}));

export function PersonaSelector({
  selectedPersona,
  setSelectedPersona,
  label,
  placeholder,
  disabled,
}: {
  selectedPersona: Persona | undefined;
  setSelectedPersona: (persona: Persona) => void;
  label: string;
  placeholder: string;
  disabled: boolean;
}) {
  const { data: personas = [] } = usePersonaListQuery();
  const personaOptions = useMemo(
    () =>
      personas.map((persona) => ({
        label: persona.name,
        value: persona.id,
      })),
    [personas],
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    (e) => {
      const persona = personas.find((p) => p.id === e.target.value);
      if (!persona) {
        console.error("Persona not found");
        return;
      }
      setSelectedPersona(persona);
    },
    [personas, setSelectedPersona],
  );

  return (
    <StyledSelect
      name="persona"
      label={label}
      value={selectedPersona?.id ?? ""}
      // @ts-expect-error FIXME: the type of onChange is wrong
      onChange={handleChange}
      placeholder={placeholder}
      disabled={disabled}
    >
      {personaOptions.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </StyledSelect>
  );
}

const StyledTextInput = styled(TextInput)(({ theme }) => ({
  marginTop: theme.spacing("sm-4"),
}));

export function PromptInput({
  prompt,
  setPrompt,
  inputRef,
  label,
  placeholder,
  disabled,
}: {
  prompt: string;
  setPrompt: (prompt: string) => void;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  label: string;
  placeholder: string;
  disabled: boolean;
}) {
  const [value, setValue] = useState(prompt);

  const onChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => {
      setValue(e.target.value);
      startTransition(() => setPrompt(e.target.value));
    },
    [setPrompt],
  );

  return (
    <StyledTextInput
      inputRef={inputRef}
      label={label}
      placeholder={placeholder}
      name="prompt"
      minRows={4}
      // @ts-expect-error FIXME: the type of value is wrong
      maxRows={4}
      multiline
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
}
