export enum ContentCreationEvents {
  GoBack = "GoBack",
  SelectCommunicationType = "SelectCommunicationType",
  SelectTemplate = "SelectTemplate",
  OpenTemplateCreatorPanelInFlow = "OpenTemplateCreatorPanelInFlow",
  OpenBriefEditorPanel = "OpenBriefEditorPanel",
  CreateDraft = "CreateDraft",
  OpenOverwriteDraftDialog = "OpenOverwriteDraftDialog",
  CancelOverwriteDraftDialog = "CancelOverwriteDraftDialog",
  OverwriteDraft = "OverwriteDraft",
  SelectCommunicationEditorContent = "SelectCommunicationEditorContent",
  ShareDraft = "ShareDraft",
  StartChangeCommunicationName = "StartChangeCommunicationName",
  CancelChangeCommunicationName = "CancelChangeCommunicationName",
  UndoEdit = "UndoEdit",
  RedoEdit = "RedoEdit",
  CopySelection = "CopySelection",
  CutSelection = "CutSelection",
  PasteSelection = "PasteSelection",
  DeleteSelection = "DeleteSelection",
  AcceptAIChanges = "AcceptAIChanges",
  RejectAIChanges = "RejectAIChanges",
  GoToPersonasSelection = "GoToPersonasSelection",
  SelectPersonalisedCommunicationEditorContent = "SelectPersonalisedCommunicationEditorContent",
  SharePersonalisedCommunications = "SharePersonalisedCommunications",
  CloseCreationView = "CloseCreationView",
}
