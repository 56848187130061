import { useForm } from "@xtreamsrl/react-forms";
import { useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import { useCreateCommunicationMutation } from "../mutations/useCreateCommMutation.ts";
import { useRecreateCommMutation } from "../mutations/useRecreateCommMutation.ts";
import { useContentCreationSlice } from "../slice/contentCreationSlice.ts";
import {
  CommunicationDetails,
  Communication,
  CommunicationType,
  BriefDemForm,
} from "../types";
import { useBriefData } from "./useBriefData.ts";
import { useCommunicationSetter } from "./useCommunicationSetter.ts";
import { useCommunicationType } from "./useCommunicationType.ts";
import { usePersonaListQuery } from "../queries/usePersonaListQuery.ts";
import { DemTemplateComponent } from "src/templates/types/index.ts";

const validationSchema = yup.object().shape({
  type: yup
    .string()
    .oneOf([
      CommunicationType.DEM,
      CommunicationType.RCS,
      CommunicationType.PAPER_MAIL,
    ])
    .required(),
  source: yup.string().oneOf(["MANUAL", "IMPORTED"]).required(),
  layoutStructure: yup.mixed().required(),
  brief: yup.array().of(
    yup.object().shape({
      question: yup.string().required(),
      answer: yup.string().required(),
    }),
  ),
});

// function isBriefDemForm(object: unknown): object is CommunicationDetails {
//   return validationSchema.isValidSync(object, { abortEarly: false });
// }

export function useCreateCommunication({
  brief,
  onSuccess,
  onError,
  onStreamChunk,
  onStreamError,
  onStreamEnd,
  onFirstStreamChunk,
}: {
  brief: CommunicationDetails["brief"];
  onSuccess?: (communication: Communication) => void;
  onError?: (error: unknown) => void;
  onStreamChunk?: (chunk: string) => void;
  onStreamError?: (error: unknown) => void;
  onStreamEnd?: () => void;
  onFirstStreamChunk?: (chunk: string) => void;
}) {
  const communicationType = useCommunicationType();
  if (!communicationType) throw new Error("Communication type is required");

  const [initialBriefData] = useBriefData();
  if (!initialBriefData) throw new Error("Initial brief data is required");

  const communication = useContentCreationSlice((state) => state.communication);
  const setCommunication = useCommunicationSetter();

  const [firstChunkReceived, setFirstChunkReceived] = useState(false);
  const handleStreamChunk = useCallback(
    (chunk: string) => {
      onStreamChunk?.(chunk);
      if (!firstChunkReceived) {
        setFirstChunkReceived(true);
        onFirstStreamChunk?.(chunk);
      }
    },
    [onStreamChunk, onFirstStreamChunk, firstChunkReceived],
  );

  const handleStreamEnd = useCallback(() => {
    onStreamEnd?.();
    if (firstChunkReceived) {
      setFirstChunkReceived(false);
    }
  }, [firstChunkReceived, setFirstChunkReceived, onStreamEnd]);

  const {
    mutate: create,
    isPending: isCreating,
    isSuccess: hasCreated,
  } = useCreateCommunicationMutation({
    onStreamChunk: handleStreamChunk,
    onStreamError: onStreamError,
    onStreamEnd: handleStreamEnd,
    onError,
  });

  const {
    mutate: regenerate,
    isPending: isRegenerating,
    isSuccess: hasRegenerated,
  } = useRecreateCommMutation({
    onStreamError,
    onStreamChunk: handleStreamChunk,
    onStreamEnd: handleStreamEnd,
    onError,
  });

  const isPending = communication ? isRegenerating : isCreating;
  const isSuccess = communication ? hasRegenerated : hasCreated;

  const initialValues = useMemo<BriefDemForm>(
    () => ({
      type: initialBriefData.type as CommunicationType.DEM,
      layoutStructure: initialBriefData.layoutStructure as DemTemplateComponent,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      layoutHtml: (initialBriefData as any).layoutHtml,
      source: initialBriefData.source as "MANUAL",
      brief,
    }),
    [initialBriefData, brief],
  );
  const form = useForm({
    initialValues,
    validationSchema,
  });

  const [showModal, setShowModal] = useState<
    "CONFIRMATION" | "FEEDBACK" | undefined
  >(undefined);

  function executeOnSuccess(data: Communication) {
    // setBriefData({
    //   type: data.details.type as CommunicationType.DEM,
    //   layoutStructure: data.details.layout.layoutStructure,
    //   brief: data.details.brief,
    //   source: data.details.layoutSource as "MANUAL",
    // });
    setCommunication(data);
    onSuccess?.(data);
  }

  const { data: personas } = usePersonaListQuery();
  const submitCreation = form.formProps.handleSubmit(
    (values: BriefDemForm, event) => {
      event?.preventDefault();
      console.log("values", values);
      useContentCreationSlice.setState({ briefData: values });

      const details =
        values.source === "MANUAL"
          ? {
              ...values,
              layoutStructure: values.layoutStructure,
              source: values.source,
            }
          : {
              ...values,
              layoutStructure: values.layoutStructure,
              layoutHtml: values.layoutHtml,
            };

      create(
        {
          personaId: personas?.[0].id,
          details,
        },
        {
          onError: (e) => {
            console.log("error", e);
            setShowModal("FEEDBACK");
          },
          onSuccess: (data) => {
            executeOnSuccess(data);
          },
        },
      );
    },
  );

  const submitRegeneration = form.formProps.handleSubmit(
    (values: BriefDemForm, event) => {
      event?.preventDefault();
      useContentCreationSlice.setState({ briefData: values });
      const commId = communication!.id;
      const details =
        values.source === "MANUAL"
          ? {
              ...values,
              layoutStructure: values.layoutStructure,
              source: values.source,
            }
          : {
              ...values,
              layoutStructure: values.layoutStructure,
              layoutHtml: values.layoutHtml,
            };
      regenerate(
        {
          commId,
          input: {
            details,
          },
        },
        {
          onError: (e) => {
            console.log("error", e);
            setShowModal("FEEDBACK");
          },
          onSuccess: (data) => {
            executeOnSuccess(data);
          },
        },
      );
    },
  );

  const submit = communication?.id ? submitRegeneration : submitCreation;

  return {
    initialValues,
    isNewCommunication: !communication,
    showModal,
    setShowModal,
    form,
    submit,
    isPending,
    isSuccess,
  };
}
