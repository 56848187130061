import { AnalyticsEvent } from "@xtreamsrl/react-analytics";
import { SharedEvents } from "./events.ts";
import { Page } from "./pageViews.ts";

export type PageProps = {
  page: Page;
  // eslint-disable-next-line
  props?: Record<string, any>;
};

export const sharedEventsMapping = {
  [SharedEvents.ExpandSideMenu]: () => ({
    type: "expandSideMenu",
  }),
  [SharedEvents.ReduceSideMenu]: () => ({
    type: "reduceSideMenu",
  }),
  [SharedEvents.HideShowSideMenu]: () => ({
    type: "hideShowSideMenu",
  }),
  [SharedEvents.CloseCreationView]: () => ({
    type: "closeCreationView",
  }),
  [SharedEvents.Logout]: () => ({
    type: "logout",
  }),
  [SharedEvents.PageView]: (props: PageProps) => {
    const { page, ...rest } = props;
    return {
      type: "pageView=" + page,
      ...rest,
    };
  },
  // eslint-disable-next-line
} satisfies Record<SharedEvents, (...props: any[]) => AnalyticsEvent>;
