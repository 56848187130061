import { startTransition, useCallback, useState } from "react";
import {
  ContentCreationEvents,
  useTracker,
} from "src/contentCreation/analytics";
import { useStepper } from "src/contentCreation/hooks/useStepper";
import {
  TextModType,
  useGenerateTextModMutation,
} from "src/contentCreation/mutations/useGenerateTextModMutation";

export function useMenuActions({
  draftId,
  commId,
  blockId,
  onAccept,
  onReject,
}: {
  draftId: string;
  commId: string;
  blockId: string;
  onAccept: (content: string) => void;
  onReject: (content: string) => void;
}) {
  const { activeStepKey } = useStepper();
  const { track } = useTracker();

  // Actual content of the text modification
  const [generationContent, setGenerationContent] = useState("");
  const [generationError, setGenerationError] = useState<unknown>(null);

  // Status flags for the text modification generation
  const [generationStarted, setGenerationStarted] = useState(false);
  const [generationInProgress, setGenerationInProgress] = useState(false);
  const [generationComplete, setGenerationComplete] = useState(false);
  const [generationEnded, setGenerationEnded] = useState(false);
  const [conversationId, setConversationId] = useState<string | null>(null);

  const trackEvent = useCallback(
    (event: ContentCreationEvents) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      track(event, {
        communicationId: commId,
        activeStep: activeStepKey,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    },
    [track, commId, activeStepKey],
  );

  // Asks the backend to generate a text modification,
  // and updates the content as the backend sends chunks
  const {
    mutate: generateTextMod,
    reset,
    variables,
  } = useGenerateTextModMutation({
    onStreamStart() {
      setGenerationStarted(true);
      setGenerationInProgress(true);
      setGenerationError(null);
      setGenerationComplete(false);
      setGenerationContent("");
    },
    onStreamChunk(chunk) {
      startTransition(() => {
        setGenerationContent((prev) => prev + chunk.delta);
      });
    },
    onStreamComplete() {
      setGenerationComplete(true);
    },
    onStreamError(error) {
      setGenerationError(error);
    },
    onStreamEnd() {
      setGenerationInProgress(false);
      setGenerationEnded(true);
    },
    onNewConversation(conversation) {
      setConversationId(conversation.conversationId);
    },
  });

  // Extract the generation type from the variables
  const generationType = variables?.operationType;

  // Call this to reset the state for the next generation
  const handleReset = useCallback(() => {
    reset();
    setGenerationStarted(false);
    setGenerationInProgress(false);
    setGenerationError(null);
    setGenerationComplete(false);
    setGenerationEnded(false);
    setConversationId(null);
  }, [
    reset,
    setGenerationStarted,
    setGenerationInProgress,
    setGenerationError,
    setGenerationComplete,
    setGenerationEnded,
    setConversationId,
  ]);

  // We need the full draft to generate the text modification
  // const { data: content } = useQuery({
  //   ...getCommPersonaDraftQuery({ draftId, commId }),
  //   select: (data) => data?.draftContent,
  // });

  // Generates the text modification
  const handleGeneration = useCallback(
    (operationType: TextModType, params: { directions?: string } = {}) => {
      generateTextMod({
        draftId,
        operationType,
        communicationId: commId,
        blockId,
        ...params,
      });
    },
    [draftId, commId, blockId, generateTextMod],
  );

  // Modifies the prompt with custom directions
  const handleModify = useCallback(
    (directions: string) => {
      handleGeneration("CUSTOM_PROMPT_MODIFICATION", {
        directions,
      });
    },
    [handleGeneration],
  );

  // Shortens the text
  const handleShorten = useCallback(
    () => handleGeneration("SUMMARIZE"),
    [handleGeneration],
  );

  // Expands the text
  const handleExpand = useCallback(
    () => handleGeneration("EXPAND"),
    [handleGeneration],
  );

  // Rephrases the text
  const handleRephrase = useCallback(
    () => handleGeneration("REPHRASE"),
    [handleGeneration],
  );

  const handleRegenerate = useCallback(() => {
    if (!variables) throw new Error("Variables not set");
    generateTextMod({ ...variables, conversationId });
  }, [generateTextMod, variables, conversationId]);

  // Inserts the generated content into the editor at current selection
  const handleAcceptMod = useCallback(() => {
    console.log("Accepting modification");
    trackEvent(ContentCreationEvents.AcceptAIChanges);
    onAccept(generationContent);
    handleReset();
  }, [trackEvent, generationContent, handleReset, onAccept]);

  // Rejects the modification and resets the state
  const handleRejectMod = useCallback(() => {
    trackEvent(ContentCreationEvents.RejectAIChanges);
    onReject(generationContent);
    handleReset();
  }, [handleReset, onReject, trackEvent, generationContent]);

  return {
    generationType,
    generationContent,
    generationStarted,
    generationInProgress,
    generationError,
    generationComplete,
    generationEnded,
    handleModify,
    handleShorten,
    handleExpand,
    handleRephrase,
    handleAcceptMod,
    handleRejectMod,
    handleReset,
    handleRegenerate,
  };
}
//
// function addMarkersToSelection(editor: Editor, selection: Range) {
//   const start: Point = Range.start(selection);
//   const end: Point = Range.end(selection);
//   const newEditor = createEditor();
//   newEditor.select(selection);
//
//   Transforms.insertNodes(newEditor, editor.children, { at: [0] });
//   // Optionally, inspect and remove the last node if it's an unwanted newline
//   const lastNodePath = [newEditor.children.length - 1];
//   const lastNode = Editor.node(newEditor, lastNodePath);
//   if (lastNode && Node.string(lastNode[0]).trim() === "") {
//     Transforms.removeNodes(newEditor, { at: lastNodePath });
//   }
//
//   // the end should be done first to avoid changing the start point
//   newEditor.insertText("~~~", { at: end });
//   newEditor.insertText("~~~", { at: start });
//
//   return slateToMarkdownProcessor.stringify(slateToRemark(newEditor.children));
// }
