import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { CommEditingModal } from "./CommEditingModal";
import { TextInput } from "@xtreamsrl/react-ui-kit/TextInput";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useState,
} from "react";
import { useRecreateCommMutation } from "../mutations/useRecreateCommMutation";
import { useCommDetailsQuery } from "../queries/useCommDetailsQuery";
import { BriefDemForm, Communication, PersonaDraft } from "../types";
import type { TranslationKeys } from "../../_shared/assets/i18n";
import { useTranslate } from "@xtreamsrl/react-i18n";

export function EditBriefModal(props: {
  commId: string;
  isOpen: boolean;
  handleClose: () => void;
  setSelectedDraft: (draft: PersonaDraft) => void;
}) {
  const t = useTranslate();
  const { isOpen, handleClose, commId, setSelectedDraft } = props;
  const { data: communication } = useCommDetailsQuery(commId);

  const [newCommunication, setNewCommunication] = useState<Communication>();
  const [isGenerating, setIsGenerating] = useState(false);

  const handleStreamEnd = useCallback(() => {
    const newDraft = newCommunication?.personaDrafts[0];
    if (newDraft) setSelectedDraft(newDraft);
    handleClose();
  }, [newCommunication, handleClose, setSelectedDraft]);

  const handleNewCommunication = useCallback(
    (comm: Communication) => {
      setNewCommunication(comm);
      setIsGenerating(true);
    },
    [setNewCommunication, setIsGenerating],
  );

  const { mutate, isPending } = useRecreateCommMutation({
    onNewCommunication: handleNewCommunication,
    onStreamEnd: handleStreamEnd,
    onStreamError: handleClose,
  });

  const handleSubmit = useCallback(
    (brief: Communication["details"]["brief"]) => {
      if (!communication) return;

      mutate({
        commId: communication.id,
        input: {
          name: communication.name,
          details: {
            brief,
            type: communication.details.type,
            layoutStructure: communication.details.layout.layoutStructure,
            source: communication.details.layoutSource,
            layoutHtml: communication.details.layout.layoutHtml,
          } as BriefDemForm,
        },
      });
    },
    [communication, mutate],
  );

  const isLoading = isPending || isGenerating;

  if (!communication) return null;
  return (
    <CommEditingModal
      title={t("commEditing.editBrief.title" satisfies TranslationKeys)}
      paragraph={t("commEditing.editBrief.paragraph" satisfies TranslationKeys)}
      isOpen={isOpen}
      onClose={handleClose}
      canClose={!isLoading}
    >
      <EditBriefForm
        handleClose={handleClose}
        communication={communication}
        setSelectedDraft={setSelectedDraft}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </CommEditingModal>
  );
}

const StyledTextInput = styled(TextInput)(({ theme }) => ({
  minWidth: "30rem",
  marginTop: theme.spacing("sm-4"),
}));

function EditBriefForm({
  handleClose,
  communication,
  onSubmit,
  isLoading,
}: {
  handleClose: () => void;
  communication: Communication;
  setSelectedDraft: (draft: PersonaDraft) => void;
  onSubmit: (brief: Communication["details"]["brief"]) => void;
  isLoading: boolean;
}) {
  const [brief, setBrief] = useState(communication.details.brief);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const index = parseInt(e.target.name.split(".")[1]);
      // note: spreading the field is necessary to avoid mutating state
      const newBrief = brief.map((field) => ({ ...field }));
      newBrief[index].answer = e.target.value;
      setBrief(newBrief);
    },
    [setBrief, brief],
  );

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      onSubmit(brief);
    },
    [onSubmit, brief],
  );

  const inputsMissing = false;

  return (
    <form onSubmit={handleSubmit}>
      {brief.map((field, index) => (
        <StyledTextInput
          key={field.question}
          label={field.question}
          value={field.answer}
          onChange={handleChange}
          name={"brief." + index}
          multiline
          minRows={3}
          {...{ maxRows: 6 }}
          disabled={isLoading}
        />
      ))}
      <Flex gap={"sm-4"} justifyContent="space-between">
        <Button
          variant="outlined"
          color="grey"
          onClick={handleClose}
          type="button"
          disabled={isLoading}
        >
          Annulla
        </Button>
        <Button
          type="submit"
          disabled={isLoading}
          loading={isLoading || inputsMissing}
        >
          Modifica
        </Button>
      </Flex>
    </form>
  );
}
