import { theme } from "@reaidy/components";
import { FormTextInputBasic } from "@xtreamsrl/react-form-input-kit/FormTextInputBasic";
import { FormSelectBasic } from "@xtreamsrl/react-form-input-kit/FormSelectBasic";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { TextInput } from "@xtreamsrl/react-ui-kit/TextInput";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { CommunicationType } from "../../contentCreation/types";
import { TemplatesEvents, useTracker } from "../analytics";
import { Button } from "@xtreamsrl/react-ui-kit/Button";

export function TemplateFixedItem({
  showDescription,
  communicationType,
  fieldName,
  value,
  remove,
}: {
  showDescription?: boolean;
  communicationType: CommunicationType;
  fieldName: string;
  value: string;
  remove?: () => void;
}) {
  const t = useTranslate();
  const { track } = useTracker();

  return (
    <Box
      padding="sm-6"
      pt="sm-6"
      border={`1px solid ${theme.palette.grey[5]}`}
      style={{ borderRadius: "10px" }}
    >
      <Flex
        justifyContent="space-between"
        marginBottom="sm-6"
        alignItems="center"
      >
        <Flex gap="sm-4" alignItems="center">
          <Icon fontSize="large" name="TextSelect" />
          <Typography>
            {t(`template.creation.${communicationType}.${fieldName}`)}
          </Typography>
        </Flex>
        {remove && (
          <Button
            size="sm"
            color="grey"
            variant="outlined"
            onlyIcon={true}
            onClick={() => {
              track(TemplatesEvents.RemoveTemplateSection);
              remove();
            }}
            icon={<Icon name="Trash2" />}
          />
        )}
      </Flex>
      <Flex direction="column" gap="sm-6">
        {showDescription && (
          <FormTextInputBasic
            size="md"
            testId={`${fieldName}.description`}
            name={`${fieldName}.description`}
            multiline={true}
            placeholder={t("template.creation.descriptionOptional")}
          />
        )}
        <Flex gap="sm-4">
          <Box flex={10}>
            <FormSelectBasic
              size="md"
              name={`${fieldName}.type`}
              disabled={true}
            >
              <FormSelectBasic.Option value={value} selected={true}>
                {t(`template.creation.${communicationType}.${fieldName}Field`)}
              </FormSelectBasic.Option>
            </FormSelectBasic>
          </Box>

          <Box flex={1} flexBasis="min-content">
            <FormTextInputBasic
              size="md"
              type="number"
              name={`${fieldName}.maxLength`}
              endInputAddOn={
                <TextInput.AddOn
                  {...{
                    sx: {
                      textTransform: "none",
                    },
                  }}
                >
                  {t("template.creation.charsAbbreviation")}
                </TextInput.AddOn>
              }
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
