import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { useFieldArray } from "react-hook-form";
import { GenericTemplateComponent } from "../types";
import { TemplateSection } from "./TemplateSection";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { emptyTemplateSection } from "../utils";
import { TemplatesEvents, useTracker } from "../analytics";
import { CommunicationTypeContext } from "../views/TemplateCreator";
import { useContext } from "react";
import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { useTranslate } from "@xtreamsrl/react-i18n";

export function TemplateSections({
  showDescription,
}: {
  showDescription?: boolean;
}) {
  const {
    fields: sections,
    remove: removeSection,
    insert: insertSection,
  } = useFieldArray<GenericTemplateComponent, "sections">({
    name: "sections",
  });
  const t = useTranslate();
  const { track } = useTracker();
  const communicationType = useContext(CommunicationTypeContext);
  return (
    <>
      {sections.map((field, indexSection) => (
        <TemplateSection
          key={field.id}
          index={indexSection}
          remove={removeSection}
          showDescription={showDescription}
        />
      ))}
      <Box alignSelf="center">
        <Button
          size="sm"
          variant="filled"
          leftIcon={<Icon name="Plus" />}
          onClick={() => {
            track(TemplatesEvents.AddTemplateSection);
            insertSection(
              sections.length + 1,
              emptyTemplateSection[communicationType],
            );
          }}
        >
          {t("template.creation.newSection")}
        </Button>
      </Box>
    </>
  );
}
