import ButtonGroup from "@mui/material/ButtonGroup";
import { FormSelectBasic } from "@xtreamsrl/react-form-input-kit/FormSelectBasic";
import { FormTextInputBasic } from "@xtreamsrl/react-form-input-kit/FormTextInputBasic";
import { useFormContext } from "@xtreamsrl/react-forms";
import { useTranslate } from "@xtreamsrl/react-i18n";
import { Button } from "@xtreamsrl/react-ui-kit/Button";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Icon } from "@xtreamsrl/react-ui-kit/Icon";
import { TextInput } from "@xtreamsrl/react-ui-kit/TextInput";
import { useContext } from "react";
import { useFormContext as useReactHookFormContext } from "react-hook-form";
import { CommunicationType } from "../../contentCreation/types";
import { ComponentType, SectionFieldComponent } from "../types";
import { CommunicationTypeContext } from "../views/TemplateCreator.tsx";

const emptyField: SectionFieldComponent = {
  type: ComponentType.TEXT,
  maxLength: 200,
  editable: true,
};

const removeTextTransform = {
  sx: {
    textTransform: "none",
  },
};

const removeNativeSpinButtons = {
  sx: {
    ["input::-webkit-outer-spin-button,input::-webkit-inner-spin-button"]: {
      ["-webkit-appearance"]: "none",
      ["-moz-appearance"]: "none",
    },
  },
};

const options = {
  //  [CommunicationType.SMS]: () => [ComponentType.TEXT],
  [CommunicationType.DEM]: (firstField: boolean) =>
    firstField
      ? [
          ComponentType.SECTION_TITLE,
          ComponentType.TEXT,
          ComponentType.CTA,
          ComponentType.BULLET_POINT,
          ComponentType.TITLED_BULLET_POINT,
        ]
      : [
          ComponentType.TEXT,
          ComponentType.CTA,
          ComponentType.BULLET_POINT,
          ComponentType.TITLED_BULLET_POINT,
        ],
  [CommunicationType.PAPER_MAIL]: (firstField: boolean) =>
    firstField
      ? [
          ComponentType.SECTION_TITLE,
          ComponentType.TEXT,
          ComponentType.BULLET_POINT,
          ComponentType.TITLED_BULLET_POINT,
        ]
      : [
          ComponentType.TEXT,
          ComponentType.BULLET_POINT,
          ComponentType.TITLED_BULLET_POINT,
        ],
  [CommunicationType.RCS]: (firstField: boolean) =>
    firstField
      ? [
          ComponentType.SECTION_TITLE,
          ComponentType.TEXT,
          ComponentType.TITLED_BULLET_POINT,
        ]
      : [
          ComponentType.TEXT,
          ComponentType.TITLED_BULLET_POINT,
          ComponentType.CTA,
        ],
  // eslint-disable-next-line
} satisfies Record<CommunicationType, (...args: any[]) => ComponentType[]>;

export const TemplateSectionField = ({
  sectionIndex,
  index,
  insert,
  remove,
}: {
  sectionIndex: number;
  index: number;
  isFirstAndOnly: boolean;
  insert: (index: number, value: SectionFieldComponent) => void;
  remove: (index: number) => void;
}) => {
  const t = useTranslate();
  const firstField = index === 0;
  const communicationType = useContext(CommunicationTypeContext);
  const { watch } = useFormContext();
  const { setValue } = useReactHookFormContext();

  const fieldData = watch(`sections.${sectionIndex}.components.${index}`) as
    | SectionFieldComponent
    | undefined;

  const isBulletPointWithTitle =
    fieldData?.type === ComponentType.TITLED_BULLET_POINT;

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const value = e.target.value as ComponentType;
    // if we are selecting titled bullet point coming from a field that has only one length to specify
    if (value === ComponentType.TITLED_BULLET_POINT) {
      setValue(
        `sections.${sectionIndex}.components.${index}.maxTitleLength`,
        30,
      );
      setValue(
        `sections.${sectionIndex}.components.${index}.maxContentLength`,
        200,
      );
      setValue(
        `sections.${sectionIndex}.components.${index}.maxLength`,
        undefined,
      );
    }
    // if we are selecting a field that has only one length to specify and the currently selected is a titled bullet point
    else if (isBulletPointWithTitle) {
      setValue(`sections.${sectionIndex}.components.${index}.maxLength`, 200);
      setValue(
        `sections.${sectionIndex}.components.${index}.maxTitleLength`,
        undefined,
      );
      setValue(
        `sections.${sectionIndex}.components.${index}.maxContentLength`,
        undefined,
      );
    }
  };

  return (
    <Flex direction="row" gap="sm-4" alignItems="start">
      <Flex direction="row" gap="sm-4" flexWrap="wrap" flex={1}>
        <FormSelectBasic
          size="md"
          name={`sections.${sectionIndex}.components.${index}.type`}
          onChange={handleChange}
          {...{ sx: { flex: 1 } }}
        >
          {options[communicationType](firstField).map((option) => (
            <FormSelectBasic.Option key={option} value={option}>
              {t(`template.creation.${option}`)}
            </FormSelectBasic.Option>
          ))}
        </FormSelectBasic>
        <Flex direction="row" gap="sm-4">
          {isBulletPointWithTitle && (
            <FormTextInputBasic
              size="md"
              type="number"
              name={`sections.${sectionIndex}.components.${index}.maxTitleLength`}
              {...removeNativeSpinButtons}
              startInputAddOn={
                <TextInput.AddOn {...removeTextTransform}>
                  {t("template.creation.bulletMaxTitleLength")}
                </TextInput.AddOn>
              }
              endInputAddOn={
                <TextInput.AddOn {...removeTextTransform}>
                  {t("template.creation.charsAbbreviation")}
                </TextInput.AddOn>
              }
            />
          )}
          <FormTextInputBasic
            size="md"
            type="number"
            name={
              isBulletPointWithTitle
                ? `sections.${sectionIndex}.components.${index}.maxContentLength`
                : `sections.${sectionIndex}.components.${index}.maxLength`
            }
            {...{
              sx: {
                ...removeNativeSpinButtons.sx,
                width: isBulletPointWithTitle ? "auto" : "6rem",
              },
            }}
            startInputAddOn={
              isBulletPointWithTitle ? (
                <TextInput.AddOn {...removeTextTransform}>
                  {t("template.creation.bulletMaxContentLength")}
                </TextInput.AddOn>
              ) : undefined
            }
            endInputAddOn={
              <TextInput.AddOn {...removeTextTransform}>
                {t("template.creation.charsAbbreviation")}
              </TextInput.AddOn>
            }
          />
        </Flex>
      </Flex>
      <ButtonGroup sx={{ paddingTop: ".5rem" }}>
        <Button
          color="grey"
          size="sm"
          variant="outlined"
          onlyIcon
          icon={<Icon name="Copy" />}
          onClick={() => insert(index + 1, fieldData ?? emptyField)}
        />
        <Button
          color="grey"
          size="sm"
          variant="outlined"
          onlyIcon
          icon={<Icon name="Trash2" />}
          onClick={() => remove(index)}
        />
      </ButtonGroup>
    </Flex>
  );
};
