import { useBrowserNavigation } from "@xtreamsrl/react-routing";
import { useLocation } from "react-router-dom";
import { useMenuVisibility } from "./useMenuVisibility.ts";

export function useSideMenu() {
  const { goTo } = useBrowserNavigation();
  const { pathname } = useLocation();
  const { isMenuExpanded, setIsMenuExpanded } = useMenuVisibility();

  return {
    goTo,
    pathname,
    isMenuExpanded,
    setIsMenuExpanded,
  };
}
