import { useTranslate } from "@xtreamsrl/react-i18n";
import { Modal } from "@xtreamsrl/react-ui-kit/Modal";
import React, { PropsWithChildren } from "react";

export type ConfirmationProps = {
  headerKey: string;
  visible: boolean;
  primaryButtonKey?: string;
  primaryAction: () => void;
  secondaryButtonKey?: string;
  secondaryAction: () => void;
};
const ConfirmationModal: React.FC<PropsWithChildren<ConfirmationProps>> = ({
  headerKey,
  visible,
  primaryButtonKey = "shared.proceed",
  primaryAction,
  secondaryButtonKey = "shared.cancel",
  secondaryAction,
  children,
}) => {
  const t = useTranslate();
  return (
    <Modal open={visible} onClose={secondaryAction} showCloseButton={true}>
      <Modal.Header>{t(headerKey)}</Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Footer type="right">
        <Modal.PrimaryAction onClick={primaryAction}>
          {t(primaryButtonKey)}
        </Modal.PrimaryAction>
        <Modal.SecondaryAction onClick={secondaryAction}>
          {t(secondaryButtonKey)}
        </Modal.SecondaryAction>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationModal.displayName = "ConfirmationModal";
export default ConfirmationModal;
