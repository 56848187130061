import { queryOptions, useQuery } from "@tanstack/react-query";
import { getPersonasAPI } from "../apis/getPersonas";

export const personaListQueryKeys = {
  all: ["persona", "list"] as const,
};

export function getPersonaListQuery() {
  return queryOptions({
    queryKey: personaListQueryKeys.all,
    queryFn: getPersonasAPI,
  });
}

export function usePersonaListQuery() {
  return useQuery(getPersonaListQuery());
}
