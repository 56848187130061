import { infiniteQueryOptions, useInfiniteQuery } from "@tanstack/react-query";
import { CommunicationType } from "../../contentCreation/types/index.ts";
import { getTemplatesAPI } from "../apis/getTemplates.ts";
import { ColumnName, SortDirection } from "../types/index.ts";

export type TemplatesListQueryFilters = {
  commType?: CommunicationType;
  sortBy?: Record<ColumnName, SortDirection>;
};

export const templatesListQueryKeys = {
  all: ["templates", "list"] as const,
  filtered: (filters: TemplatesListQueryFilters) =>
    [...templatesListQueryKeys.all, filters] as const,
};

export function getTemplatesListQuery(filters: TemplatesListQueryFilters = {}) {
  return infiniteQueryOptions({
    queryKey: templatesListQueryKeys.filtered(filters),
    queryFn: async ({ pageParam, queryKey }) => {
      const { commType, sortBy } = queryKey[2];
      return getTemplatesAPI(pageParam, sortBy, commType);
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.pageInfo.nextPage,
  });
}

export function useTemplatesListQuery(filters: TemplatesListQueryFilters = {}) {
  return useInfiniteQuery(getTemplatesListQuery(filters));
}
