import { pages } from "@reaidy/pages";
import { useBrowserNavigation } from "@xtreamsrl/react-routing";
import { useState } from "react";
import { ModalState } from "../../_shared/components/ConfirmationWithFeedbackModal/ConfirmationWithFeedbackModal.tsx";
import { getCommunicationAPI } from "src/contentCreation/apis/getCommunication.ts";
import { useDuplicateCommMutation } from "../../contentCreation/mutations/useDuplicateCommMutation.ts";
import {
  ContentCreationSlice,
  ContentCreationState,
  resetState,
  useContentCreationSlice,
} from "../../contentCreation/slice/contentCreationSlice.ts";
import {
  BriefDemForm,
  Communication,
  CommunicationType,
  StepKey,
} from "../../contentCreation/types";
import { ArchiveEvents, useTracker } from "../analytics";
import { CommunicationElement } from "../types";
import { useDeleteCommMutation } from "../mutations/useDeleteCommMutation.ts";
import { DemTemplateComponent } from "src/templates/types/index.ts";

type Operation = "DELETE" | "DUP_FROM_DRAFT" | "DUP_FROM_PERSONAS";
type OperationHandlers = {
  [K in Operation]: () => void;
};

export function useCommunicationItemActions(
  communication: CommunicationElement,
) {
  const { goTo } = useBrowserNavigation();
  const { mutate: duplicateFromDraft } = useDuplicateCommMutation();
  const { mutate: deleteMutation } = useDeleteCommMutation();

  const [nameAfterDuplication, setNameAfterDuplication] = useState(
    `${communication.name} Copy`,
  );

  const [op, setOp] = useState<Operation>("DELETE");
  const [modalState, setModalState] = useState<ModalState>(undefined);
  const [failureCode, setFailureCode] = useState<string | undefined>(undefined);
  const { track } = useTracker();

  const view = () => {
    track(ArchiveEvents.ViewCommunication, {
      communicationId: communication.id,
    });
    goTo(pages.viewById(communication.id));
  };

  const edit = () => {
    track(ArchiveEvents.EditCommunication, {
      communicationId: communication.id,
    });
    goTo(pages.editById(communication.id));
  };

  // TODO: reimplement this
  function dupFromBrief() {
    track(ArchiveEvents.DuplicateFromBrief, {
      communicationId: communication.id,
    });
    getCommunicationAPI(communication.id)
      .then((res) => {
        const { type, layout, ...rest } = res.details;
        const dataToSetInStore: ContentCreationState = {
          communicationType: type,
          activeStep: "briefForm" as StepKey,
          briefData: {
            type: type as CommunicationType.DEM,
            source: res.details.layoutSource,
            layoutStructure: layout.layoutStructure as DemTemplateComponent,
            layoutHtml: layout.layoutHtml,
            ...rest,
          } as BriefDemForm,
          selectedPersonas: [],
          draftToBeSaved: [],
          communicationsBeingSaved: [],
          savingError: false,
        };
        useContentCreationSlice.setState(dataToSetInStore);
        goTo(pages.contentCreation);
      })
      .catch((error) => {
        console.error("Error getting communication", error);
      });
  }

  function duplicate(
    name: string,
    onSuccess?: (communication: Communication) => void,
  ) {
    duplicateFromDraft(
      {
        communicationId: communication.id,
        name: name,
      },
      {
        onSuccess: (res: Communication) => {
          if (onSuccess) onSuccess(res);
        },
        onError: (error) => {
          console.error(error);
          // FIXME: fix this type definition
          setFailureCode((error as Error & { code: string }).code);
          setModalState("FEEDBACK");
        },
      },
    );
  }

  function dupFromDraft(name: string) {
    duplicate(name);
  }

  function dupFromPersonas(name: string) {
    const onSuccess = (c: Communication) => {
      const dataToSetInStore: Partial<ContentCreationSlice> = {
        communicationType: c.details.type,
        activeStep: "personasSelection" as StepKey,
        communication: c,
        briefData: {
          ...c.details,
          type: c.details.type as CommunicationType.DEM,
          layoutStructure: c.details.layout
            .layoutStructure as DemTemplateComponent,
          source: "MANUAL",
        },
        selectedPersonas: [],
      };
      useContentCreationSlice.setState(dataToSetInStore);
      goTo(pages.contentCreation);
    };
    duplicate(name, onSuccess);
  }

  function deleteCommunication() {
    if (
      useContentCreationSlice.getState().communication?.id === communication.id
    ) {
      resetState();
    }
    deleteMutation(
      { communicationId: communication.id },
      {
        onError: (error) => {
          setFailureCode((error as Error & { code: string }).code);
          setModalState("FEEDBACK");
        },
      },
    );
  }

  function openConfirmation(operation: Operation) {
    setOp(operation);
    switch (operation) {
      case "DELETE":
        track(ArchiveEvents.OpenDeleteDialog, {
          communicationId: communication.id,
        });
        break;
      case "DUP_FROM_DRAFT":
        track(ArchiveEvents.OpenDuplicateFromDraftDialog, {
          communicationId: communication.id,
        });
        break;
      case "DUP_FROM_PERSONAS":
        track(ArchiveEvents.OpenDuplicateFromPersonasDialog, {
          communicationId: communication.id,
        });
        break;
    }
    setModalState("CONFIRMATION");
  }

  const onConfirm: OperationHandlers = {
    DELETE: () => {
      setModalState(undefined);
      deleteCommunication();
    },
    DUP_FROM_DRAFT: () => {
      track(ArchiveEvents.DuplicateFromDraft, {
        communicationId: communication.id,
      });
      setModalState(undefined);
      dupFromDraft(nameAfterDuplication.trim());
      setNameAfterDuplication(communication.name);
    },
    DUP_FROM_PERSONAS: () => {
      track(ArchiveEvents.DuplicateFromPersonas, {
        communicationId: communication.id,
      });
      setModalState(undefined);
      dupFromPersonas(nameAfterDuplication.trim());
      setNameAfterDuplication(communication.name);
    },
  };

  const onCancel: OperationHandlers = {
    DELETE: () => {
      setModalState(undefined);
    },
    DUP_FROM_DRAFT: () => {
      setModalState(undefined);
      setNameAfterDuplication(communication.name);
    },
    DUP_FROM_PERSONAS: () => {
      setModalState(undefined);
      setNameAfterDuplication(communication.name);
    },
  };

  return {
    op,
    nameAfterDuplication,
    setNameAfterDuplication,
    modalState,
    failureCode,
    view,
    edit,
    dupFromBrief,
    delete: deleteCommunication,
    openConfirmation,
    onConfirm,
    onCancel,
  };
}
