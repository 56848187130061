import { styled } from "@xtreamsrl/react-ui-kit/styles";

export const EditorContainer = styled("div")(({ theme }) => ({
  paddingInline: theme.spacing("md-8"),
  paddingTop: theme.spacing("lg-1"),
  paddingBottom: "20rem", // to make room for the toolbar
  position: "absolute",
  inset: 0,
  overflow: "auto",
}));
