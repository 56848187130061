import fetch from "../../_shared/fetch";
import { PersonaDraft } from "../types";

export type UpdatePersonaDraftPayload = {
  block: {
    id: string;
    content: string;
  };
};

export async function updatePersonaDraft({
  commId,
  draftId,
  payload,
}: {
  commId: string;
  draftId: string;
  payload: UpdatePersonaDraftPayload;
}) {
  const result: PersonaDraft = await fetch.patch(
    `/communications/${commId}/persona-drafts/${draftId}`,
    payload,
    false, // extractJson
  );
  return result;
}
