import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { Modal } from "@xtreamsrl/react-ui-kit/Modal";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { useTranslate } from "@xtreamsrl/react-i18n";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: ".5rem",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});

const SpinnerWrapper = styled("div")(({ theme }) => ({
  borderRadius: "50%",
  backgroundColor: theme.palette.brand[3],
  display: "grid",
  height: "3.5rem",
  width: "3.5rem",
  placeContent: "center",
  marginBottom: ".75rem",
}));

const SpinnerContainer = styled("div")({
  position: "relative",
  display: "flex",
});

function Spinner() {
  return (
    <SpinnerWrapper>
      <SpinnerContainer>
        <CircularProgress
          variant="determinate"
          sx={(theme) => ({
            color: theme.palette.brand[5],
          })}
          size={27}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={(theme) => ({
            color: theme.palette.brand[9],
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          })}
          size={27}
          thickness={4}
        />
      </SpinnerContainer>
    </SpinnerWrapper>
  );
}

export function GenerationLoadingModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const t = useTranslate();
  const title = t("brief.generation.loading.title");
  const description = t("brief.generation.loading.description");
  return (
    <Modal open={open} onClose={handleClose} showCloseButton={false}>
      <Modal.Content>
        <Container>
          <Spinner />
          <Typography variant="body/xxl/semibold">{title}</Typography>
          <Typography variant="body/base/regular" color="grey">
            {description}
          </Typography>
        </Container>
      </Modal.Content>
    </Modal>
  );
}
