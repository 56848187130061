import { AvailableStep } from "../types";
import { allSteps } from "../utils/utils.tsx";

export function useSteps(): {
  steps: AvailableStep[];
} {
  // const communicationType = useContentCreationSlice((s) => s.communicationType);

  const stepsInFlow = allSteps.filter((s) => {
    if (typeof s.isInFlow === "function") {
      return s.isInFlow();
    } else {
      return s.isInFlow;
    }
  });

  return { steps: stepsInFlow };
}
