import Skeleton from "@mui/material/Skeleton";
import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { Card } from "@xtreamsrl/react-ui-kit/Card";
import { Flex } from "@xtreamsrl/react-ui-kit/Flex";
import { Typography } from "@xtreamsrl/react-ui-kit/Typography";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import DOMPurify from "dompurify";
import { MouseEventHandler, useCallback, useMemo } from "react";
import { useCommPersonaDraftQuery } from "../queries/useCommPersonaDraftQuery";
import { PersonaDraft } from "../types";
import { CommDraftCardActions } from "./CommDraftCardActions";

const selectedStyles = {
  border: `1px solid rgba(95, 77, 219, 1)`,
  boxShadow: "0px 0px 0px 3px rgba(95, 77, 219, 0.2)",
};

const StyledCard = styled(Card)<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    height: "8.5rem",
    borderRadius: ".5rem",
    padding: theme.spacing("sm-8", "md-1"),
    boxShadow: "none",
    position: "relative",
    ...(selected ? selectedStyles : {}),
    "&:hover": selectedStyles,
    cursor: "pointer",
  }),
);

const LoadingOverlay = styled(Skeleton)({
  position: "absolute",
  inset: 0,
  zIndex: 10,
  height: "100%",
  backgroundColor: "rgba(227, 233, 242, 1)",
});

const StyledCardHeader = styled(Card.Header)({
  [".MuiCardHeader-content"]: {
    overflow: "hidden",
  },
});

const StyledTypography = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  lineHeight: "1.5",
});

export function CommDraftCard({
  draft,
  commId,
  selectedDraft,
  setSelectedDraft,
  personaDrafts,
}: {
  draft: PersonaDraft;
  commId: string;
  selectedDraft?: PersonaDraft;
  setSelectedDraft: (draft: PersonaDraft) => void;
  personaDrafts: PersonaDraft[];
}) {
  const isSelected = draft.id === selectedDraft?.id;
  const { data: personaDraft, isLoading: queryLoading } =
    useCommPersonaDraftQuery({
      commId,
      draftId: draft.id,
    });

  const { draftContent: content, status, personaName } = personaDraft ?? {};
  const isLoading =
    queryLoading ||
    status === "DRAFT_LOADING" ||
    content?.length === 0 ||
    !content;

  const safeContent = useMemo(() => {
    return content?.map(({ content }) =>
      DOMPurify.sanitize(content, {
        USE_PROFILES: { html: false },
      }),
    );
  }, [content]);

  const title =
    safeContent?.filter((v) => v.length > 15)[0] ?? "Titolo mancante";
  const paragraph =
    safeContent?.filter((v) => v.length > 15)[1] ?? "Paragrafo mancante";

  const onClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      const target = event.target as HTMLElement;
      // Prevent selection when clicking on buttons
      if (target.tagName === "BUTTON") return;
      if (target.closest("button")) return;
      setSelectedDraft(draft);
    },
    [draft, setSelectedDraft],
  );

  const onBeforeDelete = useCallback(() => {
    const index = personaDrafts.findIndex((d) => d.id === draft.id);
    const nextDraft = personaDrafts[index + 1] ?? personaDrafts[index - 1];
    if (nextDraft) setSelectedDraft(nextDraft);
  }, [draft, personaDrafts, setSelectedDraft]);

  const onAfterCopy = useCallback(
    (draft: PersonaDraft) => {
      setSelectedDraft(draft);
    },
    [setSelectedDraft],
  );

  return (
    <StyledCard selected={isSelected} onClick={onClick}>
      {isLoading ? (
        <LoadingOverlay variant="rectangular" animation="wave" />
      ) : (
        <Flex
          height="100%"
          direction="column"
          gap="1rem"
          justifyContent="space-between"
        >
          <StyledCardHeader
            padding={0}
            title={
              <StyledTypography variant="body/xl/semibold">
                {title}
              </StyledTypography>
            }
            subheader={
              <StyledTypography variant="body/base/regular" color="grey">
                {paragraph}
              </StyledTypography>
            }
          />
          <Card.Footer padding={0}>
            <Flex
              gap="sm-2"
              direction="row"
              justifyContent="space-between"
              width="100%"
            >
              <CommDraftCardActions
                commId={commId}
                draft={draft}
                onBeforeDelete={onBeforeDelete}
                onAfterCopy={onAfterCopy}
                canDelete={personaDrafts.length < 2}
              />
              {personaName && <PersonaBadge personaName={personaName} />}
            </Flex>
          </Card.Footer>
        </Flex>
      )}
    </StyledCard>
  );
}

const BadgeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing("sm-2"),
  color: "#5f4ddb",
  alignItems: "center",
  backgroundColor: "rgba(240, 241, 255, 1)",
  borderRadius: theme.spacing("sm-2"),
  padding: theme.spacing("sm-2", "sm-4"),
}));

const userIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.83692 9.0303C3.01164 9.5217 0.847795 10.5251 2.16572 11.7807C2.80952 12.394 3.52654 12.8327 4.42801 12.8327H9.57198C10.4735 12.8327 11.1905 12.394 11.8343 11.7807C13.1522 10.5251 10.9884 9.5217 10.1631 9.0303C8.2278 7.87792 5.7722 7.87792 3.83692 9.0303Z"
      stroke="#5342C2"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.625 3.79102C9.625 5.24076 8.44976 6.41602 7 6.41602C5.55025 6.41602 4.375 5.24076 4.375 3.79102C4.375 2.34127 5.55025 1.16602 7 1.16602C8.44976 1.16602 9.625 2.34127 9.625 3.79102Z"
      stroke="#5342C2"
      strokeWidth="1"
    />
  </svg>
);

function PersonaBadge({ personaName }: { personaName: string }) {
  return (
    <BadgeContainer>
      {userIcon}
      <Typography variant="body/xs/medium" color="inherit">
        {personaName}
      </Typography>
    </BadgeContainer>
  );
}
