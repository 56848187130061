import { Box } from "@xtreamsrl/react-ui-kit/Box";
import { Icon, IconProps } from "@xtreamsrl/react-ui-kit/Icon";
import { styled } from "@xtreamsrl/react-ui-kit/styles";
import { ComponentProps } from "react";

const Container = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  width: "50ch",
  flexDirection: "column",
  justifyContent: "flex-end",
  backgroundColor: theme.palette.info[9],
  color: theme.palette.common.white,
  padding: theme.spacing("md-8"),
  borderRadius: theme.spacing("sm-4"),
  "& p:not(:last-of-type)": {
    marginBottom: theme.spacing("md-2"),
  },
}));

const IconContainer = styled("div")(({ theme }) => ({
  width: "8rem",
  height: "8rem",
  position: "relative",
  borderRadius: theme.spacing("sm-2"),
  backgroundColor: theme.palette.info[3],
  color: theme.palette.info[9],
  marginBlock: theme.spacing("md-4"),
}));

const StyledIcon = styled(Icon)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  fontSize: "5rem",
}));

function Illustration({ iconProps }: { iconProps: IconProps }) {
  return (
    <IconContainer>
      <StyledIcon color="inherit" {...iconProps} />
    </IconContainer>
  );
}

export function StepInfoBox({
  children,
  illustrationProps,
}: {
  children: React.ReactNode;
  illustrationProps: ComponentProps<typeof Illustration>;
}) {
  return (
    <Container>
      <Illustration {...illustrationProps} />
      {children}
    </Container>
  );
}
