import fetch from "../../_shared/fetch";
import { SearchCommunicationsResponse } from "../types";

export type SearchCommFilters = {
  userId?: string;
};

export function searchCommunicationsAPI(
  pageParam: number,
  filters: SearchCommFilters = {},
): Promise<SearchCommunicationsResponse> {
  return fetch.get(`/communications/`, {
    pageNumber: pageParam,
    pageSize: 10,
    ...filters,
  });
}
